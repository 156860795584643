import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import {environment} from '@app/env';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class SlotsService {
  private _jsonURL = 'assets/HabaneroGames.json';
  private _virtulaGames = 'assets/virtual_games.json';
  // private _TablejsonURL = 'assets/habanerotablegames.json';
  private _TablejsonURL = 'assets/Habanero_TableGames.json';
  constructor(private http: HttpClient) {

   }
   private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Access-Control-Allow-Origin':'*',
      'siteid': environment.skinId,
      'wsession':sessionStorage.getItem('wsession')
    })
  };
   private httpOption = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Access-Control-Allow-Origin':'*',
      'siteid': environment.skinId
    })
  };
  public getJSON(): Observable<any> {
    return this.http.get(this._jsonURL);
  }
  public getVirtula(): Observable<any> {
    return this.http.get(this._virtulaGames);
  }
  public getTableJSON(): Observable<any> {
    return this.http.get(this._TablejsonURL);
  }
  httpClient(data):Observable<any>{
    return this.http.get(`${environment.appApi.baseUrl}${environment.appApi.heblounch}/${data}`, this.httpOptions)
    //return this.http.get("http://africa-test.czargaming.com/rest/habanero/handler/"+data, this.httpOptions)
    //return this.http.get("http://trophy777.czargaming.com/rest/habanero/handler/"+data, this.httpOptions)
  }
  httpClientfun():Observable<any>{
    return this.http.get(`${environment.appApi.baseUrl}${environment.appApi.hebfunlounch}`, this.httpOption)
    //return this.http.get("http://africa-test.czargaming.com/rest/habanero/handler/"+data, this.httpOptions)
    //return this.http.get("http://trophy777.czargaming.com/rest/habanero/handler/"+data, this.httpOptions)
  }
}

