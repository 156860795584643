// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  skinId: 'trophy777',
  //slotsiamgepath: 'http://africa-test.czargaming.com/i/skin/habanero',
   slotsiamgepath: 'https://trophy777.czargaming.com ',
  //slotsiamgepath: 'http://trophyp4m.trophy777.com/i/skin/habanero',
  appApi: {
  baseUrl: '',
  login: '/api/player/login',
  heblounch: '/rest/habanero/handler',
  hebfunlounch: 'rest/habanero/habFunMode',
  virtuals: 'rest/xpress/handler/',
  generateCaptcha: '/api/player/generateCaptcha',
  logout: '/api/player/logout',
  register: '/api/player/register',
  getCountries: '/api/player/getCountries',
  fotgotPassword: '/api/player/forgotPassword',
  generateOTP: '/api/player/generateOTP',
  uploadFiles: '/api/player/uploadFile',
  getDocuments: '/api/player/getDocuments',
  leaderboardList:"/api/leaderboard/list",
  getParticipants:"/api/leaderboard/getParticipants",
  remotelogin:"/sbapi/auth/remote",
  contactus:"api/player/contactUs",
  allGamesUrl:'/api/player/mrGameURL',
  allGamesfunUrl:'rest/mrslotty/mrGameFunURL',
  player: {
  getProfile: '/api/player/getProfile',
  updateProfile: '/api/player/updateProfile',
  updatePassword: '/api/player/updatePassword',
  verifyAccount: '/api/player/verifyAccount',
  resendToken: '/api/player/resendToken',
  getLaunchURLS: '/api/player/getLaunchURLS',
  resetPassword: '/api/player/resetPassword',
  tournamentsList: '/api/player/getPokerTournamentsList',
  playerStats: '/api/player/getStats',
  playerCurrencies: '/api/player/getCurrencies',
  games: '/api/player/getGames',
  playerLevels:"/api/cp/playerLevels",
  exchangeRates:"/api/cp/exchangeRates",
  exchange:"/api/cp/exchange",
  contactus:"api/player/contactUs",
  getExchangeRates: "/api/cp/exchangeRates",
  exchangeVipPointsUrl:"/api/cp/exchange",

  },
  cashier: {
  balance: '/api/cashier/balance',
  resetPlayMoneyBalance: '/api/cashier/resetPlayMoneyBalance',
  getPaymentSystems: '/api/cashier/getPaymentSystems?pgid=',
  depositSystems: '/api/cashier/depositSystems',
  withdrawSystems: '/api/cashier/getCashOutInfo',
  withDrawCashout: '/api/cashier/cashout',
  deposit: '/api/cashier/buy',
  getOpenWithdrawRequests: '/api/cashier/getOpenWithdrawRequests',
  cancelWithdrawRequest: '/api/cashier/cancelWithdrawRequest',
  withdraw: '/api/cashier/cashout',
  addBankAccount: '/api/cashier/addBankAccount',
  getBankAccounts: '/api/cashier/getBankAccounts',
  deleteBankAccounts: '/api/cashier/deleteBankAccounts',
  },
  history: {
  transaction: '/api/history/transaction',
  // game: '/api/history/game',
  game: 'api/history/gameRoundData',
  
  remotegame:"/api/history/remoteGames"
  },
  sports:{
  sportsList:"/sbapi/sports/matches/no",
  leaguesList:"/sbapi/sports/sportleagues",
  leaguesandmatchList:"/sbapi/sports/prematch",
  getleagueById:'/sbapi/sports/leagues',
  betslip:'/sbapi/bets/betslip',
  anonymousbet:'/sbapi/bets/anonymousbet',
  bonus:'/sbapi/bets/bonus',
  betsByCoupon:'/sbapi/bets/coupon',
  emailTicket:'/rest/bet/email/ticket',
  betHistory:'/sbapi/bets/getbets'
  
  }
  },
  mainMenu: {
  menu: [
  { name: 'home', url: '/', children: [] },
  { name: " poker", url: "/poker", children: [] },
  //{ name: " casino", url: "/slots", children: [] },
  // { name: " casino", url: "/slotsandtablegames", children: [] },
  { name: "Home", url: "/slots", children: [
    // { name: 'slots', url: '/slots'},
    // { name: 'Table Games', url: '/tablegames'},
    // { name: 'Lottery', url: '/lottery' },
  ] },
  { name: 'tournaments', url: '/tournaments', children: [] },
  { name: 'promotions', url: '/promotions', children: [] },
  // { name: 'how to play', url: '/howtoplay', children: [] },
  // { name: 'contact us', url: '/contactus', children: [] },
  /* { name: " about us", url: "/aboutus", children: [] },
  { name: " eula", url: "/eula", children: [] }, */
  ],
  loggedInMenu: [
    { name: "Home", url: "/slots", disabled:false, children: [
      // { name: 'slots', url: '/slots'},
      // { name: 'Table Games', url: '/tablegames'},
      // { name: 'Lottery', url: '/lottery'},
    ] },
  // { name: 'home', url: '/', children: []},
  { name: "poker", url: "/poker", disabled:true, children: [], },
  // { name: "poker", url: "/poker", children: []},
  //{ name: " casino", url: "/slotsandtablegames", children: [] },
  // { name: " casino", url: "/slotsandtablegames", children: [] },
 
  { name: 'tournaments', url: '/tournaments', disabled:true, children: [] },
  { name: 'promotions', url: '/promotions', disabled:true, children: [] },
  // { name: 'how to play', url: '/howtoplay', children: [] },
  // { name: 'contact us', url: '/contactus', children: [] },
  // { name: "eula", url: "/eula", children: [] },
  {
  name: 'my account', url: '/profile', disabled:false, children: [
  // { name: 'profile', url: '/profile' },
  //{ name: 'deposit', url: '/deposit' },
  //{ name: 'depositresponse', url: '/depositresponse' },
  //{ name: 'withdraw', url: '/withdraw' },
  // { name: 'banking', url: '/bankaccount' },
  // { name: 'transaction history', url: '/transactionhistory' },
  // { name: 'game history', url: '/gamehistory' },
  // { name: 'casino game history', url: '/casinogamehistory' }
  // { name: 'currency-exchange', url: '/currency-exchange' },
  ]
  
  },
  {
    name: 'History', url: '/transactionhistory', disabled:false, children: [
   
    { name: 'transaction history', url: '/transactionhistory' },
    { name: 'game history', url: '/gamehistory' },
    { name: 'casino game history', url: '/casinogamehistory' }
    ]
    
    },
    // { name: 'Deposit', url:'/deposit', children:[],}
  
//   {
//   name: 'History', url:'/', childern: [
//     { name: 'transaction history', url: '/transactionhistory' },
//     { name: 'game history', url: '/gamehistory' },
//     { name: 'casino game history', url: '/casinogamehistory' }
//   ]
// },
  ]
  },
  socketConfig: {
  url: 'http://10.10.20.201:9000',
  opts: {
  transports: ['websocket'],
  path: '/ws/poker-ssl',
  query: {
  EIO: '3'
  }
  }
  }
  };
  
  /*
  * For easier debugging in development mode, you can import the following file
  * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
  *
  * This import should be commented out in production mode because it will have a negative impact
  * on performance if an error is thrown.
  */
  // import 'zone.js/dist/zone-error'; // Included with Angular CLI.