import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import * as appState from 'src/app/source/appstates/appState';
import * as loginActions from 'src/app/source/appstates/loginstates/loginActions';
import { Subscription } from 'rxjs';
import { LoginState } from 'src/app/source/appstates/loginstates/loginState';
import { LoginService } from 'src/app/source/services/login/login.service';
import { toUnicode } from 'punycode';
import { data } from 'jquery';
import { CommonService } from 'src/app/source/services/commonService.service';

declare var $: any;
function showLoginModal() {
  $("#login").modal("show");
}
function hideLoginModal() {
  $("#login").modal("hide");
}
@Component({
  selector: 'app-quicklogin',
  templateUrl: './quicklogin.component.html',
  styleUrls: ['./quicklogin.component.scss'],
})
export class QuickloginComponent implements OnInit, OnDestroy {
  showPassword: boolean = false;
  profileName: string;
  showLoginResPop: boolean = false;

  visible: boolean = true;
  changetype: boolean = true;
  viwepass() {
    this.visible = !this.visible;
    this.changetype = !this.changetype;
  }

  //   const togglePassword = document.querySelector('#togglePassword');
  //   const password = document.querySelector('#id_password');

  //   this.togglePassword.addEventListener('click', function (e) {
  //     // toggle the type attribute
  //     const type = password.getAttribute('type') === 'password' ? 'text' : 'password';
  //     password.setAttribute('type', type);
  //     // toggle the eye slash icon
  //     this.classList.toggle('fa-eye-slash');
  // });



  @Input() formState: string;
  @Output() close = new EventEmitter<void>();
  // @Output() currentItem = new EventEmitter<string>();
  errorMessage: string;
  storeSub: Subscription;

  form: FormGroup;

  loginState: string = "LOGIN";
  forgotState: string = "FORGOTPASSWORD";
  registerState: string = "REGISTER";
  quickLoginFormState: string = "LOGIN";
  imageBlobUrl: string | ArrayBuffer;

  constructor(private store: Store<appState.AppState>, private commonService: CommonService,
    private loginService: LoginService) { }

  // toggleShow() {
  //   this.showPassword = !this.showPassword;
  //   this.input.type = this.showPassword ? 'text' : 'password';
  // }

  ngOnInit() {
    // console.log(this.currentItem)
    this.quickLoginFormState = 'LOGIN'; 
    this.commonService.currentMethod.subscribe(data => {
      this.quickLoginFormState = data; 

      console.log(data)
      // switch (data) {
      //   case 'LOGIN': 
      //     this.quickLoginFormState = data;
      //     break;
      //     case 'REGISTER':
      //     this.quickLoginFormState = data; 
      //     break;
      //   default: 
      //     this.quickLoginFormState = data;
      //     break;

      // }

    })
    let uName = localStorage.getItem('trophy777User')
    let pword = localStorage.getItem('trophy777Password')


    // let uName = localStorage.getItem('trophyUserName')
    // let pword = localStorage.getItem('trophyPassword')
    this.form = new FormGroup({
      "login": new FormControl(uName !== '' ? uName : null, [Validators.required,Validators.minLength(4),Validators.maxLength(15),Validators.pattern('[a-zA-Z0-9]*')]),
      "password": new FormControl(pword !== '' ? pword : null,[ Validators.required,Validators.minLength(6),Validators.maxLength(15),Validators.pattern(/^\S*$/)]),
      // "captcha": new FormControl(null, Validators.required)
      "rememberMe": new FormControl(true)
    })


    this.changeFormState(this.formState);
    showLoginModal();

    $("#login").on('hidden.bs.modal', function () {
      this.onClose();
    }.bind(this));
    this.storeSub = this.store.select("loginState").subscribe(
      (loginState: LoginState) => {
        if (loginState.forgotPasswordResponse) {
          if (loginState.forgotPasswordResponse.success === true) {
            this.errorMessage = "Success! Email Sent to the registered email ID";
            hideLoginModal();
          }
          if (loginState.forgotPasswordResponse.description) {
            this.errorMessage = loginState.forgotPasswordResponse.description;
          }
        }
        if (loginState.forgotPasswordErrorResponse) {
          this.errorMessage = loginState.forgotPasswordErrorResponse.message;
        }
      }
    )
    this.storeSub = this.store.select("loginState").subscribe(
      (loginState: LoginState) => {
        if (loginState.loginResponse) {
          if (loginState.loginResponse.success === false) {
            this.errorMessage = loginState.loginResponse.description;
          }
          if (loginState.loginResponse.success === true) {
            hideLoginModal();
          }
        }
        if (loginState.loginErrorResponse) {
          this.errorMessage = loginState.loginErrorResponse.message;
        }
        // if (loginState.generatedCaptcha) {
        //   //console.log(loginState.generatedCaptcha);
        //   this.createImageFromBlob(loginState.generatedCaptcha);
        // }
        // if (loginState.generatedCaptchaError) {
        //   this.errorMessage = loginState.generatedCaptchaError.message;
        // }
      }
    )

    // this.store.dispatch(new loginActions.GenerateCaptchaStart());

    // this.refreshCaptcha();

  }
  // refreshCaptcha() {
  //   this.loginService.onGenerateCaptchaStart().subscribe(
  //     (value) => {

  //       this.createImageFromBlob(value);
  //     },
  //     response => {
  //       console.log(response);
  //     },
  //     () => {
  //       console.log("POST observable is now completed.");
  //     }
  //   )
  // }
  createImageFromBlob(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
      console.log(reader.result);
      this.imageBlobUrl = reader.result;
    }, false); if (image) {
      reader.readAsDataURL(image);
    }
  }
  onInputFocus() {
    this.errorMessage = "";
  }
  onClose() {
    console.log("i am in close");
    this.close.emit();
  }
  onFormSubmit() {

    if (this.form.value.rememberMe) {
      localStorage.setItem('trophy777User', this.form.value.login)
      localStorage.setItem('trophy777Password', this.form.value.password)
    } else {
      null
    }

    const body = {
      login: this.form.value.login,
      password: this.form.value.password
    }
    // if(this.form.value.rememberMe){
    //   localStorage.setItem('trophyUserName', this.form.value.login )
    //   localStorage.setItem('trophyPassword', this.form.value.password )
    // } else {
    //   null
    // }

    console.log('check')
    this.store.dispatch(new loginActions.LoginStart(body));
    // this.loginService.onLogin(this.form.value).subscribe( data => this.loginRes(data))
  }
  // loginRes(data){
  //   console.log(data)
  //   if(data.success == true){
  //     console.log("true")
  // this.showLoginResPop = true;
  //   }else{
  //     console.log("false")
  //   }

  // }
  closeLogPop() {
    this.showLoginResPop = false;
  }
  changeFormState(state) {
    this.store.dispatch(new loginActions.ResetState());
    this.quickLoginFormState = state;
    this.errorMessage = '';
  }
  ngOnDestroy() {
    if (this.storeSub) {
      this.storeSub.unsubscribe();
    }
  }
  sessionId
  username(event){
    var R
    R = event.charCode;
    console.log(R)
    
    return((R>64 && R<91) || (R>96 && R<123) || (R>48 && R<57) )   
  }
  userchar(event){
    var R
    R = event.charCode;
    console.log(R)
    
    return((R > 64 && R < 91) || (R > 96 && R< 123) || R== 8 || R >= 33 && R<= 47 || R >= 60 && R <= 64 || (R >= 48 && R <= 58) || R>= 91 && R<= 96 || R >= 123 && R<= 126);   
  }
}


