import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { Subscription, interval, timer } from 'rxjs';
import { LoginService } from 'src/app/source/services/login/login.service';
import { ContactUsResponse } from 'src/app/source/modules/contactusresponse';
import { Location } from '@angular/common';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss'],
})
export class ContactUsComponent implements OnInit {
  form: FormGroup;
  errorMessage: string;
  successvalue: boolean;
  contactusresponse: ContactUsResponse;
  contactUsErrorMessage: string;
  // location: any;
  constructor(private loginservice: LoginService, private location: Location) { window.scroll(0, 0); }

  ngOnInit() {
    this.form = new FormGroup({
      face: new FormControl(null),
      ip: new FormControl(null),
      lang: new FormControl('en'),
      loginName: new FormControl(null),
      firstName: new FormControl(null,[Validators.required,Validators.minLength(4),Validators.maxLength(15),Validators.pattern('[a-zA-Z0-9]*')]),
      lastName: new FormControl(null),
      email: new FormControl(null, [ Validators.required, Validators.email,Validators.pattern(/^\S*$/),Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]),    
      subject: new FormControl(null, [Validators.required]),
      userQuestion: new FormControl(null, [Validators.required]),
    })
  }

  onFormSubmit() {
    console.log(this.form.value);
    let profile = sessionStorage.getItem('profile')

    let body = {
      "face": "Trophy777",
      "ip": null,
      "lang": "en",
      "loginName": profile,
      "lastName": this.form.value.lastName,
      "firstName": this.form.value.firstName,
      "email": this.form.value.email,
      "subject": this.form.value.subject,
      "userQuestion": this.form.value.userQuestion
    }
    this.loginservice.onContactUsSendStats(body).subscribe(data => this.contactusresponsedata(data));
  }
  contactusresponsedata(data) {
    console.log(data);
    this.contactusresponse = data;
    if (this.contactusresponse) {
      if (this.contactusresponse.success == true) {
        this.successvalue = this.contactusresponse.success;
        //this.contactUsErrorMessage = this.contactusresponse.description;
        this.form.reset();
        this.contactUsErrorMessage = 'Successfully Sent. Our support team will revert back soon.';
      } else if (this.contactusresponse.success == false) {
        this.successvalue = this.contactusresponse.success;
        this.contactUsErrorMessage = this.contactusresponse.description;
      }
    }
  }

  backClicked() {
    this.location.back();
  }
  username(event){
    var R
    R = event.charCode;
    console.log(R)
    
    return((R>64 && R<91) || (R>96 && R<123) || (R>48 && R<57) )   
  }
  emailValids(event) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k >= 33 && k <= 47 || k >= 60 && k <= 64 || (k >= 48 && k <= 57) || k >= 91 && k <= 96 || k >= 123 && k <= 126);
  }
 

}
