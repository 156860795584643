import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';
import * as cashierActions from './cashierActions';
import { exhaustMap, map, catchError, tap } from 'rxjs/internal/operators';
import { CashierService } from '../../services/cashier/cashier.service';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import { Balance } from '../../modules/cashier/balance';
import { PaymentSystems } from '../../modules/cashier/paymentSystems';
import { DepositResponse } from '../../modules/cashier/depositResponse';
import { BankAccountInfo } from '../../modules/cashier/bankAccountInfo';
import { WithdrawSystems } from '../../modules/cashier/withDraw';
import { TrasactionHistory } from '../../modules/cashier/trasactionhistory';
import { addBankAccountResponse } from '../../modules/cashier/addbankaccount';

const handleError = (error: any, actions: any) => {
    if (error.error instanceof Error) {
        return of(new actions({ "message": error.error.message }));
    } else {
        try {
            if (error.error.message) {
                return of(new actions({ "message": error.error.message }));
            } else if (error.message) {
                return of(new actions({ "message": error.message }));
            }
            return of(new actions({ "message": "some thing went wrong please contact admin" }));
        } catch (error) {

        }
    }
    return of(new actions({ "message": "some thing went wrong please contact admin" }));
}

@Injectable()
export class CashierEffects {
    constructor(private actions$: Actions,
        private router: Router,
        private cashierservice: CashierService) { }

    @Effect()
    cashierGetBalance = this.actions$.pipe(
        ofType(cashierActions.CASHIER_GET_BALANCE),
        exhaustMap((onCashierGetBalanceStart: cashierActions.CashierGetBalanceStart) =>
            this.cashierservice.onCashierGetBalance()
                .pipe(
                    map((response: Balance) => {
                        return new cashierActions.CashierGetBalanceSuccess(response);
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return handleError(error, cashierActions.CashierGetBalanceFail);
                    })
                )
        )
    )

    @Effect()
    cashierResetPlaymoney = this.actions$.pipe(
        ofType(cashierActions.CASHIER_RESET_PLAYMONEY),
        exhaustMap((onCashierRetsetPlaymoneyStart: cashierActions.CashierResetPlayMoneyStart) =>
            this.cashierservice.onCashierResetPlayMoney()
                .pipe(
                    map(response => {
                        return new cashierActions.CashierResetPlayMoneySuccess(response);
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return handleError(error, cashierActions.CashierResetPlayMoneyFail);
                    })
                )
        )
    )

    @Effect({ dispatch: false })
    cashierResetPlaymoneySuccess = this.actions$.pipe(
        ofType(cashierActions.CASHIER_RESET_PLAYMONEY_SUCCESS),
        tap((onCashierResetPlayerMoneySuccess: cashierActions.CashierResetPlayMoneySuccess) => {
            if (onCashierResetPlayerMoneySuccess.payload["success"] === true) {
                this.router.navigate(['/profile']);
            }
        })
    )

    @Effect()
    cashierGetPaymentSystem = this.actions$.pipe(
        ofType(cashierActions.CASHIER_GET_PAYMENTS_SYSTEMS),
        exhaustMap((onCashierGetPaymentSystemsStart: cashierActions.CashierGetPaymentSystemsStart) =>
            this.cashierservice.onCashierGetPaymentSystems()
                .pipe(
                    map((response: PaymentSystems) => {
                        return new cashierActions.CashierGetPaymentSystemsSuccess(response);
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return handleError(error, cashierActions.CashierGetPaymentSystemsFail);
                    })
                )
        )
    )

    @Effect()
    cashierDeposit = this.actions$.pipe(
        ofType(cashierActions.CASHIER_DEPOSIT),
        exhaustMap((onCashierDepositStart: cashierActions.CashierDepositStart) =>
            this.cashierservice.onCashierDeposit(onCashierDepositStart.payload)
                .pipe(
                    map((response: DepositResponse) => {
                        return new cashierActions.CashierDepositSuccess(response);
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return handleError(error, cashierActions.CashierDepositFail);
                    })
                )
        )
    )

    @Effect()
    cashierGetOpenWithdrawRequest = this.actions$.pipe(
        ofType(cashierActions.CASHIER_OPEN_WITHDRAW_REQUEST),
        exhaustMap((onCashierGetOpenWithDrawRequest: cashierActions.CashierGetOpenWithDrawRequest) =>
            this.cashierservice.onCashierGetOpenWithdrawRequest()
                .pipe(
                    map(response => {
                        return new cashierActions.CashierGetOpenWithDrawRequestSuccess(response);
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return handleError(error, cashierActions.CashierGetOpenWithDrawRequestFail);
                    })
                )
        )
    )

    @Effect()
    cashierWithdrawRequest = this.actions$.pipe(
        ofType(cashierActions.CASHIER_WITHDRAW_REQUEST),
        exhaustMap((onCashierWithDrawRequest: cashierActions.CashierWithDrawRequest) =>
            this.cashierservice.onCashierWithdrawRequest(onCashierWithDrawRequest.payload)
                .pipe(
                    map(response => {
                        return new cashierActions.CashierWithDrawRequestSuccess(response);
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return handleError(error, cashierActions.CashierWithDrawRequestFail);
                    })
                )
        )
    )

    @Effect()
    cashierCancelWithdrawRequest = this.actions$.pipe(
        ofType(cashierActions.CASHIER_CANCEL_WITHDRAW_REQUEST),
        exhaustMap((onCashierCancelWithDrawRequest: cashierActions.CashierCancelWithDrawRequest) =>
            this.cashierservice.onCashierCancelWithdrawRequest(onCashierCancelWithDrawRequest.payload)
                .pipe(
                    map(response => {
                        return new cashierActions.CashierCancelWithDrawRequestSuccess(response);
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return handleError(error, cashierActions.CashierCancelWithDrawRequestFail);
                    })
                )
        )
    )

    @Effect({ dispatch: false })
    cashierCancelWithdrawRequestSuccess = this.actions$.pipe(
        ofType(cashierActions.CASHIER_CANCEL_WITHDRAW_REQUEST_SUCCESS),
        tap((onCashierCancelWithDrawRequestSuccess: cashierActions.CashierCancelWithDrawRequestSuccess) => {
            if (onCashierCancelWithDrawRequestSuccess.payload["success"] === true) {
                this.cashierservice.onCashierCancelWitdrawSuccess();
            }
        })
    )

    @Effect()
    cashierTransactionHistory = this.actions$.pipe(
        ofType(cashierActions.CASHIER_TRANSACTION_HISTORY),
        exhaustMap((onCashiercashierTransactionHistory: cashierActions.CashierTransactionHistory) =>
            this.cashierservice.onCashierTransactionHistory(onCashiercashierTransactionHistory.payload)
                .pipe(
                    map((response:TrasactionHistory) => {
                        return new cashierActions.CashierTransactionHistorySuccess(response);
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return handleError(error, cashierActions.CashierTransactionHistoryFail);
                    })
                )
        )
    )


    @Effect()
    cashierAddBankAccount = this.actions$.pipe(
        ofType(cashierActions.CASHIER_ADD_BANK_ACCOUNT),
        exhaustMap((onCashierAddBankAccount: cashierActions.CashierAddBankAccount) =>
            this.cashierservice.onCashierAddBankAccount(onCashierAddBankAccount.payload)
                .pipe(
                    map((response:addBankAccountResponse) => {
                        return new cashierActions.CashierAddBankAccountSuccess(response);
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return handleError(error, cashierActions.CashierAddBankAccountFail);
                    })
                )
        )
    )

    @Effect({ dispatch: false })
    cashierAddBankAccountSuccess = this.actions$.pipe(
        ofType(cashierActions.CASHIER_ADD_BANK_ACCOUNT_SUCCESS),
        tap((onCashierAddBankAccountSuccess: cashierActions.CashierAddBankAccountSuccess) => {
            if (onCashierAddBankAccountSuccess.payload.success === true) {
                this.cashierservice.getCashierBankAccounts();
            }
        })
    )

    @Effect()
    cashierGetBankAccount = this.actions$.pipe(
        ofType(cashierActions.CASHIER_GET_BANK_ACCOUNT),
        exhaustMap((onCashierGetBankAccount: cashierActions.CashierGetBankAccount) =>
            this.cashierservice.onCashierGetBankAccount()
                .pipe(
                    map((response: BankAccountInfo) => {
                        return new cashierActions.CashierGetBankAccountSuccess(response);
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return handleError(error, cashierActions.CashierGetBankAccountFail);
                    })
                )
        )
    )
    @Effect()
    cashierDeleteBankAccount = this.actions$.pipe(
        ofType(cashierActions.CASHIER_DELETE_BANK_ACCOUNT),
        exhaustMap((onCashierDeleteBankAccount: cashierActions.CashierDeleteBankAccount) =>
            this.cashierservice.onCashierDeleteBankAccount(onCashierDeleteBankAccount.payload)
                .pipe(
                    map((response: BankAccountInfo) => {
                        return new cashierActions.CashierDeleteBankAccountSuccess(response);
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return handleError(error, cashierActions.CashierDeleteBankAccountFail);
                    })
                )
        )
    )

    @Effect({ dispatch: false })
    cashierDeleteBankAccountSuccess = this.actions$.pipe(
        ofType(cashierActions.CASHIER_DELETE_BANK_ACCOUNT_SUCCESS),
        tap((onCashierDeleteBankAccountSuccess: cashierActions.CashierDeleteBankAccountSuccess) => {
            if (onCashierDeleteBankAccountSuccess.payload["success"] === true) {
                this.cashierservice.getCashierBankAccounts();
            }
        })
    )

    @Effect()
    cashierGetDepositSystems = this.actions$.pipe(
        ofType(cashierActions.CASHIER_GET_DEPOSIT_SYSTEMS),
        exhaustMap((onCashierGetDepositSystems: cashierActions.CashierGetDepositSystems) =>
            this.cashierservice.onCashierGetDepositSystems(onCashierGetDepositSystems.payload)
                .pipe(
                    map(response => {
                        return new cashierActions.CashierGetDepositSystemsSuccess(response);
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return handleError(error, cashierActions.CashierGetDepositSystemsFail);
                    })
                )
        )
    )

    @Effect()
    cashierGetWithdrawSystems = this.actions$.pipe(
        ofType(cashierActions.CASHIER_GET_WITHDRAW_SYSTEMS),
        exhaustMap((onCashierGetWithdrawSystems: cashierActions.CashierGetDepositSystems) =>
            this.cashierservice.onCashierGetWithdrawSystems(onCashierGetWithdrawSystems.payload)
                .pipe(
                    map((response:WithdrawSystems) => {
                        return new cashierActions.CashierGetWithdrawSystemsSuccess(response);
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return handleError(error, cashierActions.CashierGetWithdrawSystemsFail);
                    })
                )
        )
    )

    @Effect()
    cashierWithdrawCashout = this.actions$.pipe(
        ofType(cashierActions.CASHIER_WITHDRAW_CASHOUT),
        exhaustMap((onCashierWithdrawCashout: cashierActions.CashierWithdrawCashout) =>
            this.cashierservice.onCashierWithdrawCashout(onCashierWithdrawCashout.payload)
                .pipe(
                    map(response => {
                        return new cashierActions.CashierWithdrawCashoutSuccess(response);
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return handleError(error, cashierActions.CashierWithdrawCashoutFail);
                    })
                )
        )
    )

}