import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as appState from 'src/app/source/appstates/appState';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import * as cashierActions from 'src/app/source/appstates/cashierstates/cashierActions';
import { PlayerGetGameHistory, PlayerGetRemoteGameHistory } from 'src/app/source/appstates/playerstates/playerActions';
import * as moment from 'moment';
import { ProfileState } from 'src/app/source/appstates/playerstates/playerState';
import { SlotsService } from "../../../../source/services/slotsservice/slots.service";
import { RootObject } from 'src/app/source/modules/slotsmodel/slotsvalue';
@Component({
  selector: 'app-casino-history',
  templateUrl: './casino-history.component.html',
  styleUrls: ['./casino-history.component.scss'], 
})
export class CasinoHistoryComponent implements OnInit {
  private storeSub: Subscription;
  gamehistory: any;
  slotsobject: RootObject;
  tableobject: RootObject;
  remoteGameHistoryErrorMessage: string;
  descriptiondata: string;
  remotehistory: any;
  totalrecords: number; 
  startDate:Date;
  endDate:Date;
  todaydate: any;
  gameTypes = ["POKER_HOLDEMNOLIMIT_USD,HOLDEM,NOLIMIT","POKER_HOLDEMLIMIT_USD,HOLDEM,LIMIT","POKER_OMAHANOLIMIT_USD,OMAHA,NOLIMIT","POKER_OMAHALIMIT_USD,OMAHA,LIMIT"]
  form: FormGroup;
  forms: FormGroup;
  steddate: boolean;
  constructor(private SlotsService: SlotsService, private store: Store<appState.AppState>) {
    this.SlotsService.getJSON().subscribe(slotdata => {
      this.slotsvaluelunch(slotdata);
    });
    this.SlotsService.getTableJSON().subscribe(tabledata => {
      this.tablevaluelunch(tabledata);
    });
  }

  ngOnInit() { 
    this.todaydate = moment(new Date()).format('YYYY-MM-DD'); 
    this.endDate = new Date();
    let today = new Date(this.endDate.getFullYear(), this.endDate.getMonth(), this.endDate.getDate() - 7);
    this.startDate = today;
    this.form = new FormGroup({
      "currency": new FormControl("USD", Validators.required),
      "startDate": new FormControl(null, Validators.required),
      "endDate": new FormControl(null, Validators.required),
      "name": new FormControl(null),
      "limit": new FormControl("100", Validators.required),
      "index": new FormControl("0", Validators.required),
    })
    this.forms = new FormGroup({
      "currency": new FormControl("USD", Validators.required),
      "startDate": new FormControl(null, Validators.required),
      "endDate": new FormControl(null, Validators.required),
      "name": new FormControl(null),
      "limit": new FormControl("100", Validators.required),
      "index": new FormControl("0", Validators.required),
    })
    this.form.valueChanges.subscribe(x => {
      let yearsDiff = 0;
      var ToDate = new Date();
      var pastYear = ToDate.getFullYear() - yearsDiff;
      ToDate.setFullYear(pastYear);
      if (new Date(x.startDate).getTime() > ToDate.getTime() || new Date(x.startDate).getTime() > new Date(x.endDate).getTime() || new Date(x.endDate).getTime() > ToDate.getTime()) {
        this.steddate = true; 
      } else {
        this.steddate = false;
      }
    });

    this.storeSub = this.store.select("playerState").subscribe(
      (playerState: ProfileState) => {
        console.log(this.storeSub)
        if (playerState.remotegameshistory) {
          console.log(playerState.remotegameshistory)
          if (playerState.remotegameshistory.success == true) {
            this.totalrecords = playerState.remotegameshistory.total;
            this.remotehistory = playerState.remotegameshistory.values;
            console.log( this.remotehistory )
            console.log( this.totalrecords )
            this.descriptiondata = playerState.remotegameshistory.description;
            console.log( this.descriptiondata )
            this.remoteGameHistoryErrorMessage = null;
          } else if ( playerState.remotegameshistory.success == false ) {
            this.remotehistory = null;
            this.remoteGameHistoryErrorMessage = playerState.remotegameshistory.description;
            console.log( this.remoteGameHistoryErrorMessage )
          }
        }
        if (playerState.remotegameshistoryError) {
          this.remotehistory = null;
          this.remoteGameHistoryErrorMessage = playerState.remotegameshistoryError.message;
          console.log( this.remoteGameHistoryErrorMessage )
        }
      }
    )
  }
  onFormSubmit() {
    this.forms.value.startDate = moment(this.forms.value.startDate).format('DD-MM-YYYY');
    this.forms.value.endDate = moment(this.forms.value.endDate).format('DD-MM-YYYY');
    this.store.dispatch(new cashierActions.CashierGetBalanceStart());
    this.store.dispatch(new PlayerGetRemoteGameHistory(this.form.value));
  }
  slotsvaluelunch(slotdata) {
    this.slotsobject = slotdata;
  }
  tablevaluelunch(tabledata) {
    this.tableobject = tabledata;
  }

}
