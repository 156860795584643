
import { environment } from '@app/env';
export const appConstants = {
    "appDownloadLinks": {
        "android": environment.appApi.baseUrl+"/download/mobile/pokermobile.apk",
        "ios": "itms-services://www.777elite.com?action=download-manifest&url=https://www.777elite.com/download/mobile/ios/manifest.plist",
        "desktop": "https://www.777elite.com/client/download.html",
        "agent": environment.appApi.baseUrl+"/agent/#/",
        "affiliate": environment.appApi.baseUrl+"/affiliate/#/"
    },
    "appCertificatesLinks": {
        "mainPageCertificate": environment.appApi.baseUrl+"/"
    },
    "CASINO_GAMEPLAY_REAL_PLAY_KEY":"real_play",
    "CASINO_GAMEPLAY_DEMO_PLAY_KEY":"demo_play",
    "CASINO_DEMO_GAME_LAUNCH_CONFIRM_MSG":"This is a demo game , click ok to launch",
    "CONTACT_MAIL":"admin@777elite.info",
    "SOCIAL_LINKS": {
        "TELEGRAM": { "link": "http://telegram.me/777elite" },
        "INSTAGRAM": { "link": "https://www.instagram.com/777elite/?igshid=1frajsrybifan" }
    },
    
    "slotsiamgepath": "/assets/habimg/habanero",
    "LOGIN_COMPONENT":"loginComp",
    "REGISTER_COMPONENT":"register",
    "defaultModalHeight":"500px",
    "defaultModalWidth":"500px",
    'language':localStorage.getItem('locale'),
    "CASHOUT_SUCCESS_MSG_EN":"your cashout request is under processing, it will reflect in your account in 2 to 3 working days",
    "CASHOUT_SUCCESS_MSG_FA":"درخواست برداشت شما با موفقیت ثبت شد"
}
