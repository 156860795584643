import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from '@app/env';
import { UploadFileResponse } from '../../modules/player/uploadfileresponse';
import { GetDocumetsResponse } from '../../modules/player/getDocument';


@Injectable({
  providedIn: 'root'
})
export class UploadServiceService {
  percentDone: number;
  uploadSuccess: boolean;
  constructor(private httpClient:HttpClient) { }

  headers = new HttpHeaders({
    'Content-Type': 'multipart/mixed',
    'Access-Control-Allow-Origin':'*',
    'siteid': environment.skinId,
    'wsession':sessionStorage.getItem('wsession')
  });

  postFile(fileToUpload: File, doctype:any){
    const docType = doctype;
    const formData: FormData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    formData.append('docType', docType);
    return this.httpClient.post<UploadFileResponse>(`${environment.appApi.baseUrl}${environment.appApi.uploadFiles}`, formData, {headers: this.headers})
    
}

getDocumetsfile(){
  return this.httpClient.post<GetDocumetsResponse>(`${environment.appApi.baseUrl}${environment.appApi.getDocuments}`, {headers: this.headers})
}


}
