import { Component, OnInit, OnDestroy, ComponentFactoryResolver, ViewChild, Input } from '@angular/core';
import * as appState from 'src/app/source/appstates/appState';
import { Store } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import { PlayerGetLaunchURLs, PlayerGetStats } from 'src/app/source/appstates/playerstates/playerActions';
import { Subscription } from 'rxjs';
import { LoginState } from 'src/app/source/appstates/loginstates/loginState';
import * as loginActions from 'src/app/source/appstates/loginstates/loginActions';
import { ProfileState } from 'src/app/source/appstates/playerstates/playerState';
import { Stats } from 'src/app/source/modules/player/stats';
import { QuickloginComponent } from '../quicklogin/quicklogin.component';
import { PlaceholderDirective } from '../PlaceholderDirective';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/source/services/commonService.service';


@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit, OnDestroy { 
  private storeSub: Subscription;
  private closeSub: Subscription;
  playerStats: Stats;
  loginstate: string;
  promotions = ['1', '2'];
  // imageSrc:string = "../../../../../assets/image(r)/imageTemol.png";
  mainMenu;
  playerLoggedIn: boolean = false;
  promotionsSlideOptions = {
    loop: true,
    navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
    nav: true,
    dots: false,
    autoplay: false,
    margin: 0,
    autoplayTimeout: 6000,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1
      },
      500: {
        items: 1
      },
      768: {
        items: 2
      },
      992: {
        items: 3
      },
      1200: {
        items: 3
      },
      1920: {
        items: 3
      }
    }
  };
  @ViewChild(PlaceholderDirective, { static: false }) alertHost: PlaceholderDirective;
  openLogPop: boolean = false;
  constructor(private store: Store<appState.AppState>, public router: Router, private commonService:CommonService,
    private componentFactoryResolver: ComponentFactoryResolver) { }
  
  ngOnInit() {
      this.autoLogin();
    if (!this.playerLoggedIn) {
      this.mainMenu = environment.mainMenu.menu;
    }

    this.storeSub = this.store.select("loginState").subscribe(
      (loginState: LoginState) => {
        if (loginState.playerLoggedIn) {
          console.log(loginState.playerLoggedIn.loggedIn);
          if (loginState.playerLoggedIn.loggedIn) {
            this.mainMenu = environment.mainMenu.loggedInMenu;
            this.playerLoggedIn = loginState.playerLoggedIn.loggedIn;
          } else {
            this.mainMenu = environment.mainMenu.menu;
            this.playerLoggedIn = false;
          }
        }
      })
    this.storeSub = this.store.select("playerState").subscribe(
      (profileState: ProfileState) => {
        if (profileState.stats) {
          this.playerStats = profileState.stats;
        }
      })
    this.store.dispatch(new PlayerGetStats());
  }

  autoLogin(){ 
    if(this.router.url.indexOf('?username') !== -1){
        let routUrl = window.location.search;
        let splitUname = routUrl.split('?username=')
        let uName = splitUname[1].split('&')[0]; 
        
        let splitPword = splitUname[1].split('&')[1]
        let pWord = splitPword.split('password=')[1] 
 
      const body = {
        login: uName,
        password: pWord
      }

      sessionStorage.clear();    

      this.store.dispatch(new loginActions.LoginStart(body));
    }
  }


  launchFlash() {
    this.loginstate = sessionStorage.getItem('wsession');
    if (this.loginstate == null) {
       window.scroll(0,0);
    }else if (this.loginstate != null) {
      this.store.dispatch(new PlayerGetLaunchURLs());
      try {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      } catch (e) { }
    }
  }
  closePops(){
    this.openLogPop = false;
  }
  showPopUp(value:string) {
    this.openLogPop = true;
    console.log( value )
    this.commonService.updateHitMethod(value)
    setTimeout(() => {
      this.commonService.updateHitMethod(value)
    }, 100);
    // this.store.dispatch(new loginActions.ResetState());
    
    // const alertCmpFactory = this.componentFactoryResolver.resolveComponentFactory( QuickloginComponent );
    // const hostViewContainerRef = this.alertHost.viewContainerRef;
    // hostViewContainerRef.clear();
    // const componentRef = hostViewContainerRef.createComponent(alertCmpFactory);
    // componentRef.instance.formState = value;
    // this.closeSub = componentRef.instance.close.subscribe(() => {
    //   this.closeSub.unsubscribe();
    //   hostViewContainerRef.clear(); 
    // });

  }


  


  ngOnDestroy() {
    if (this.storeSub) {
      this.storeSub.unsubscribe();
    }
    if (this.closeSub) {
      this.closeSub.unsubscribe();
    }
  }
}
