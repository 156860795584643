import { Component, OnInit } from '@angular/core';
import { SlotsService } from '../../../../source/services/slotsservice/slots.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import * as appState from 'src/app/source/appstates/appState';
//import { slotsData, casinogmaelunch, KeyVsValue } from 'src/app/source/modules/slotsmodel/slotsdata';
import { RootObject, Game } from 'src/app/source/modules/slotsmodel/slotsvalue';
import { buildRootObject, buildGame } from 'src/app/source/modules/slotsmodel/builsslots';
import { environment } from '@app/env';
import { CoreEnvironment } from '@angular/compiler/src/compiler_facade_interface';
import * as cashierActions from 'src/app/source/appstates/cashierstates/cashierActions';
//import { tokenState } from '../../source/appstates/sblogin/sbloginState';
import { LoginState } from 'src/app/source/appstates/loginstates/loginState';
//import { CasinoGames, Casino } from 'src/app/source/modules/goldenraceinterface/goldenrace';
import { Router, ActivatedRoute } from '@angular/router';
import { AllGames, Response} from "src/app/source/modules/slotsmodel/allgames";
//import {Tablegame} from 'src/app/source/modules/slotsmodel/allgames';

import * as loginActions from 'src/app/source/appstates/loginstates/loginActions';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-lottery',
  templateUrl: './lottery.component.html',
  styleUrls: ['./lottery.component.scss']
})
export class LotteryComponent implements OnInit {

  private profilestoreSubslots: Subscription;
  private bettokenstoreSub: Subscription;
  private logoutstore: Subscription;
  private allGamesLunchStore: Subscription;
  tablegame:Response[];
  private storeSub: Subscription;
  casinogamessuccessPop:boolean=false;
  casinomessage:string;
  rootObject: RootObject;
  buildRootObject: buildRootObject;
  buildGame: buildGame;
  game: Array<Game>;
  //Casinresponse: Array<Casino>;
  balackjackplayerContextId: string;
  baccaratplayerContextId: string;
  europeanrouletteplayerContextId: string;
  OasispokerplayerContextId: string;
  sessionid: any;
  imagespath = environment.slotsiamgepath;
  keyname:string;
  tokendata:any=null;
  gameLunchUrl:any;
  urlSafe:SafeResourceUrl;
  playerLoggedIn: boolean = false;
  loader_div: boolean = false;
  constructor(public sanitizer: DomSanitizer, private route: ActivatedRoute, private router: Router, private slotsservice: SlotsService, private store: Store<appState.AppState>) {
    this.storeSub = this.store.select("loginState").subscribe(
      (loginState: LoginState) => {
        if (loginState.playerLoggedIn) {
            this.playerLoggedIn = loginState.playerLoggedIn.loggedIn;
        }
      })
    this.store.dispatch(new loginActions.allGamesStart());
    this.store.dispatch(new cashierActions.CashierGetBalanceStart());
    // this.slotsservice.getTablegamesJSON().subscribe(slotdata => {
    //   this.slotsvaluelunch(slotdata);
    // });
  }

  slotsvaluelunch(slotdata) {
    this.rootObject = slotdata;
    if (this.rootObject) {
      this.game = this.rootObject.Games;
    }
  }
  ngOnInit() {
  //  this.store.dispatch(new loginActions.ResetState());
    this.storeSub = this.store.select("loginState").subscribe(
      (loginState: LoginState) => {
        if (loginState.playerLoggedIn) {
            this.playerLoggedIn = loginState.playerLoggedIn.loggedIn;
        }
      })


 
    this.logoutstore = this.store.select("loginState").subscribe(
      (loginState: LoginState) => {
        if (loginState.allgames) {
          if (loginState.allgames) {
            this.tablegame = loginState.allgames.response;
          }
          console.log(this.tablegame)
        }
      }
    )
    this.allGamesLunchStore = this.store.select("loginState").subscribe(
      (loginState: LoginState) => {
        if (loginState.allGamesLunch) {
          if(loginState.allGamesLunch.status == 200){
            this.casinomessage = "";
            this.gameLunchUrl = loginState.allGamesLunch.gameUrl;
            if(this.gameLunchUrl){
             this.slotsGameLunch();
            }
          }else if(loginState.allGamesLunch.status != 200){
            this.casinogamessuccessPop = true;
            if (loginState.allGamesLunch.description) {
              this.casinomessage = loginState.allGamesLunch.description;
            } else {
              this.casinomessage = "Unknown Error"
            }
          }
      }
      if(loginState.allGamesLunchError){
        this.casinogamessuccessPop = true;
        if (loginState.allGamesLunchError.message) {
          this.casinomessage = loginState.allGamesLunchError.message;
        } else {
          this.casinomessage = "Unknown Error"
        }
      }
    }
    )
   
  
  }
  slotsinlogout(data) {
    this.store.dispatch(new loginActions.ResetState());
  }


  lotteryFun(id:any, providername:any){
    this.loader_div = true;
    const body = {
      gameId: id,
      gameMode:'demo_play',
      provider:providername
    }
    this.store.dispatch(new loginActions.allGamesLunchStart(body));
    setTimeout(() => {
      this.loader_div = false;
      }, 4000);
  }
  lotteryReal(id:any, providername:any){
    if(this.playerLoggedIn == true){
      this.loader_div = true;
      const body = {
        gameId: id,
        gameMode:'real_play',
        provider:providername
      }
      this.store.dispatch(new loginActions.allGamesLunchStart(body));
    }else{
      this.casinomessage = 'Please Login';
     this.casinogamessuccessPop= true;
    }
    setTimeout(() => {
      this.loader_div = false;
      }, 10000);
  }
  casinogamessuccessPopClose(){
    this.casinogamessuccessPop= false;
    this.casinomessage = '';
  }
  slotsGameLunch(){
    // this.store.dispatch(new cashierActions.CashierGetBalanceStart());
     //this.store.dispatch(new loginActions.ResetState());
     //return window.open(this.gameLunchUrl);
    
     this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.gameLunchUrl);
     window.scroll({
      top:350,
      left:0,
      behavior:'smooth'
     });
   }
   urlSafeBtn(){
    this.urlSafe = false;
    window.scroll(0,0)
   }
}
