import {ActionReducerMap } from '@ngrx/store';
import * as loginState from './loginstates/loginState';
import * as playerState from './playerstates/playerState';
import * as cashierState from './cashierstates/cashierState';
import * as sportsbookState from './sportsbookstates/sportsbookState';

export interface AppState{
    loginState:loginState.LoginState;
    playerState:playerState.ProfileState;
    cashierState:cashierState.CashierState;
    sportsbookState:sportsbookState.SportsBookState;
}

export const AppReducer:ActionReducerMap<AppState> = {
    loginState:loginState.loginReducer,
    playerState:playerState.playerReducer,
    cashierState:cashierState.cashierReducer,
    sportsbookState:sportsbookState.cashierReducer
}
