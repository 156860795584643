import { Component, OnInit } from '@angular/core';
import * as appState from 'src/app/source/appstates/appState';
import { LoginState } from 'src/app/source/appstates/loginstates/loginState';
import { PlayerGetLaunchURLs } from 'src/app/source/appstates/playerstates/playerActions';
import { Store } from '@ngrx/store';
@Component({
  selector: 'app-poker',
  templateUrl: './poker.component.html',
  styleUrls: ['./poker.component.scss'],
})
export class PokerComponent implements OnInit {

  loginstate: string;
  constructor(private store: Store<appState.AppState>) { window.scroll(0,0); }

  ngOnInit() {

    
  }

  launchFlash() {
    this.loginstate = sessionStorage.getItem('wsession');
    if (this.loginstate == null) {
       window.scroll(0,0);
    }else if (this.loginstate != null) {
      this.store.dispatch(new PlayerGetLaunchURLs());
      try {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      } catch (e) { }
    }
  }

}
