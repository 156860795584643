import * as cashierActions from './cashierActions';
import { Balance } from '../../modules/cashier/balance';
import { PaymentSystems } from '../../modules/cashier/paymentSystems';
import { DepositResponse } from '../../modules/cashier/depositResponse';
import { HttpClientError } from '../../modules/login/httpClientError';
import { WithDrawResponse } from '../../modules/cashier/withdrawResponse';
import { BankAccountInfo } from '../../modules/cashier/bankAccountInfo';
import { DepositSystems } from '../../modules/cashier/depositSystems';
import { WithdrawSystems } from '../../modules/cashier/withDraw';
import { TrasactionHistory } from '../../modules/cashier/trasactionhistory';
import { addBankAccountResponse } from '../../modules/cashier/addbankaccount';

export interface CashierState {
    balance: Balance;
    paymentSystems: PaymentSystems;
    depositResponse: DepositResponse;
    depositFail: HttpClientError;
    withDrawResponse: WithDrawResponse;
    bankAccountInfo: BankAccountInfo;
    bankAccountInfoError:HttpClientError;
    withDrawRequestResponse: object;
    withDrawRequestResponseFail: HttpClientError;
    depositeSystem: DepositSystems;
    depositeSystemResponseFail: HttpClientError;
    withDrawSystem: WithdrawSystems;
    withDrawSystemResponseFail: HttpClientError;
    TrasactionHistory:TrasactionHistory;
    TrasactionHistoryerror:HttpClientError;
    addBankAccountResponse:addBankAccountResponse;
    addBankAccountError:HttpClientError
}

const initialState: CashierState = {
    balance: null,
    paymentSystems: null,
    depositResponse: null,
    depositFail: null,
    withDrawResponse: null,
    bankAccountInfo: null,
    bankAccountInfoError:null,
    withDrawRequestResponse: null,
    withDrawRequestResponseFail: null,
    depositeSystem: null,
    depositeSystemResponseFail: null,
    withDrawSystem: null,
    withDrawSystemResponseFail: null,
    TrasactionHistory:null,
    TrasactionHistoryerror:null,
    addBankAccountResponse:null,
    addBankAccountError:null
}

export function cashierReducer(state: CashierState = initialState, action: cashierActions.CashierActions) {
    switch (action.type) {
        case cashierActions.RESET_STATE:
            return {
                    ...state,
                    balance: null,
                    paymentSystems: null,
                    depositResponse: null,
                    depositFail: null,
                    withDrawResponse: null,
                    bankAccountInfo: null,
                    bankAccountInfoError:null,
                    withDrawRequestResponse: null,
                    withDrawRequestResponseFail: null,
                    depositeSystem: null,
                    depositeSystemResponseFail: null,
                    withDrawSystem: null,
                    withDrawSystemResponseFail: null,
                    TrasactionHistory:null,
                    TrasactionHistoryerror:null,
                    addBankAccountResponse:null,
                    addBankAccountError:null
            }
            break;
        case cashierActions.CASHIER_GET_BALANCE:
            return {
                ...state,
                balance: null
            }
            break;
        case cashierActions.CASHIER_GET_BALANCE_SUCCESS:
            return {
                ...state,
                balance: { ...state, ...action.payload }
            }
            break;
        case cashierActions.CASHIER_GET_BALANCE_FAIL:
            return {
                ...state,
                balance: null
            }
            break;
        case cashierActions.CASHIER_GET_PAYMENTS_SYSTEMS:
            return {
                ...state,
                paymentSystems: null
            }
            break;
        case cashierActions.CASHIER_GET_PAYMENTS_SYSTEMS_SUCCESS:
            return {
                ...state,
                paymentSystems: { ...state, ...action.payload }
            }
            break;
        case cashierActions.CASHIER_GET_PAYMENTS_SYSTEMS_FAIL:
            return {
                ...state,
                paymentSystems: null
            }
            break;
        case cashierActions.CASHIER_OPEN_WITHDRAW_REQUEST:
            return {
                ...state,
                withDrawResponse: null
            }
            break;
        case cashierActions.CASHIER_OPEN_WITHDRAW_REQUEST_SUCCESS:
            return {
                ...state,
                withDrawResponse: { ...state.withDrawResponse, ...action.payload }
            }
            break;
        case cashierActions.CASHIER_OPEN_WITHDRAW_REQUEST_FAIL:
            return {
                ...state,
                withDrawResponse: null
            }
            break;
        case cashierActions.CASHIER_WITHDRAW_REQUEST:
            return {
                ...state,
                withDrawRequestResponse: null,
                withDrawRequestResponseFail: null
            }
            break;
        case cashierActions.CASHIER_WITHDRAW_REQUEST_SUCCESS:
            return {
                ...state,
                withDrawRequestResponse: { ...state.withDrawRequestResponse, ...action.payload },
                withDrawRequestResponseFail: null
            }
            break;
        case cashierActions.CASHIER_WITHDRAW_REQUEST_FAIL:
            return {
                ...state,
                withDrawRequestResponse: null,
                withDrawRequestResponseFail: { ...state.withDrawRequestResponseFail, ...action.payload }
            }
            break;
        case cashierActions.CASHIER_DEPOSIT:
            return {
                ...state,
                depositResponse: null,
                depositFail: null
            }
            break;
        case cashierActions.CASHIER_DEPOSIT_SUCCESS:
            return {
                ...state,
                depositResponse: { ...state.depositResponse, ...action.payload },
                depositFail: null
            }
            break;
        case cashierActions.CASHIER_DEPOSIT_FAIL:
            return {
                ...state,
                depositResponse: null,
                depositFail: { ...state.depositFail, ...action.payload }
            }
            break;
        case cashierActions.CASHIER_GET_BANK_ACCOUNT:
            return {
                ...state,
                bankAccountInfo: null,
                bankAccountInfoError:null

            }
            break;
        case cashierActions.CASHIER_GET_BANK_ACCOUNT_SUCCESS:
            return {
                ...state,
                bankAccountInfo: { ...state.bankAccountInfo, ...action.payload },
                bankAccountInfoError:null
            }
            break;
        case cashierActions.CASHIER_GET_BANK_ACCOUNT_FAIL:
            return {
                ...state,
                bankAccountInfo: null,
                bankAccountInfoError: { ...state.bankAccountInfoError, ...action.payload }
            }
            break;
        case cashierActions.CASHIER_GET_DEPOSIT_SYSTEMS:
            return {
                ...state,
                depositeSystem: null,
                depositeSystemResponseFail: null,
                depositResponse: null
            }
            break;
        case cashierActions.CASHIER_GET_DEPOSIT_SYSTEMS_SUCCESS:
            return {
                ...state,
                depositeSystem: { ...state.depositeSystem, ...action.payload },
                depositeSystemResponseFail: null
            }
            break;
        case cashierActions.CASHIER_GET_DEPOSIT_SYSTEMS_FAIL:
            return {
                ...state,
                depositeSystem: null,
                depositeSystemResponseFail: { ...state.depositeSystemResponseFail, ...action.payload }
            }
            break;
        case cashierActions.CASHIER_GET_WITHDRAW_SYSTEMS:
            return {
                ...state,
                withDrawSystem: null,
                withDrawSystemResponseFail: null
            }
            break;
        case cashierActions.CASHIER_GET_WITHDRAW_SYSTEMS_SUCCESS:
            return {
                ...state,
                withDrawSystem: { ...state.withDrawSystem, ...action.payload },
                withDrawSystemResponseFail: null
            }
            break;
        case cashierActions.CASHIER_GET_WITHDRAW_SYSTEMS_FAIL:
            return {
                ...state,
                withDrawSystem: null,
                withDrawSystemResponseFail:  { ...state.withDrawSystemResponseFail, ...action.payload }
            }
            break;
            case cashierActions.CASHIER_TRANSACTION_HISTORY:
                return {
                    ...state,
                    TrasactionHistory: null,
                    TrasactionHistoryerror: null
                }
                break;
            case cashierActions.CASHIER_TRANSACTION_HISTORY_SUCCESS:
                return {
                    ...state,
                    TrasactionHistory: { ...state.TrasactionHistory, ...action.payload },
                    TrasactionHistoryerror: null
                }
                break;
            case cashierActions.CASHIER_TRANSACTION_HISTORY_FAIL:
                return {
                    ...state,
                    TrasactionHistory: null,
                    TrasactionHistoryerror:  { ...state.TrasactionHistoryerror, ...action.payload }
                }
                break;
                case cashierActions.CASHIER_ADD_BANK_ACCOUNT:
                    return {
                        ...state,
                        addBankAccountResponse: null,
                        addBankAccountError: null
                    }
                    break;
                case cashierActions.CASHIER_ADD_BANK_ACCOUNT_SUCCESS:
                    return {
                        ...state,
                        addBankAccountResponse: { ...state.addBankAccountResponse, ...action.payload },
                        addBankAccountError: null
                    }
                    break;
                case cashierActions.CASHIER_ADD_BANK_ACCOUNT_FAIL:
                    return {
                        ...state,
                        addBankAccountResponse: null,
                        addBankAccountError:  { ...state.addBankAccountError, ...action.payload }
                    }
                    break;
        default:
            return state;
            break;
    }
}