import { Actions, Effect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import * as loginActions from './loginActions';
import { catchError, map, tap, exhaustMap, switchMap } from "rxjs/internal/operators";
import { LoginService } from '../../services/login/login.service';
import { Router, ActivatedRoute } from '@angular/router';
import { of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { Login } from '../../modules/login/login';
import { Countries } from '../../modules/login/countries';
import { environment } from '../../../../environments/environment';
import { AllGames } from '../../modules/slotsmodel/allgames';
import { AllGameLunch } from '../../modules/slotsmodel/allgamelunch';

const handleError = (error: any, actions: any) => {
    if (error.error instanceof Error) {
        return of(new actions({ "message": error.error.message }));
    } else {
        try {
            if (error.error.message) {
                return of(new actions({ "message": error.error.message }));
            } else if (error.message) {
                return of(new actions({ "message": error.message }));
            }
            return of(new actions({ "message": "some thing went wrong please contact admin" }));
        } catch (error) {

        }
    }
    return of(new actions({ "message": "some thing went wrong please contact admin" }));
}
@Injectable()
export class LoginEffects {

    constructor(private actions$: Actions,
        private loginservice: LoginService,
        private router: Router,
        private route: ActivatedRoute) { }


    @Effect()
    generateCaptchaStart = this.actions$.pipe(
        ofType(loginActions.GENERATE_CAPTCHA_START),
        switchMap((onGenerateCaptchaStart: loginActions.GenerateCaptchaStart) =>
            this.loginservice.onGenerateCaptchaStart()
                .pipe(
                    map(
                        (response: Blob) => {
                        return new loginActions.GenerateCaptchaSuccess(response);
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return handleError(error, loginActions.GenerateCaptchaFail);
                    })
                )
        )
    )


    @Effect()
    loginStart = this.actions$.pipe(
        ofType(loginActions.LOGIN_START),
        exhaustMap((onLoginStart: loginActions.LoginStart) =>
            this.loginservice.onLogin(onLoginStart.payload)
                .pipe(
                    map((response: Login) => {
                        return new loginActions.LoginSuccess(response);
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return handleError(error, loginActions.LoginFail);
                    })
                )
        )
    )

    @Effect({ dispatch: false })
    loginSuccess = this.actions$.pipe(
        ofType(loginActions.LOGIN_SUCCESS),
        tap((onLoginSuccess: loginActions.LoginSuccess) => {
            if (onLoginSuccess.payload.success === true) {
                sessionStorage.setItem('wsession', onLoginSuccess.payload.sessionId);
                this.loginservice.onPlayerLoggedIn(true);
                this.router.navigate(['/slots']);
                return;
            }
            this.loginservice.onPlayerLoggedIn(false);
        })
    )

    @Effect({dispatch:false})
    loginFail = this.actions$.pipe(
        ofType(loginActions.LOGIN_FAIL),
        tap((onLoginFailed:loginActions.LoginFail)=>{
            console.log("on login fail");
            console.log(onLoginFailed.payload);
        })
    )

    @Effect()
    sportsRemoteLoginStart = this.actions$.pipe(
        ofType(loginActions.SPORTS_REMOTE_LOGIN),
        exhaustMap((onSportsRemoteLoginStart:loginActions.SportsRemoteLogin) => 
        this.loginservice.onSportRemoteLogin(onSportsRemoteLoginStart.payload)
        .pipe(
            map((response:Login) => {
                return new loginActions.SportsRemoteLoginSuccess(response);
            }),
            catchError((error:HttpErrorResponse)=>{
                return handleError(error,loginActions.SportsRemoteLoginFail);
                })
        )
        )
    )

    @Effect({dispatch:false})
    sportsRemoteLoginSuccess = this.actions$.pipe(
        ofType(loginActions.SPORTS_REMOTE_LOGIN_SUCCESS),
        tap((onSportsRemoteLoginSuccess:loginActions.SportsRemoteLoginSuccess)=>{
            if(onSportsRemoteLoginSuccess.payload["accessToken"]){
                sessionStorage.setItem('accessToken_'+environment.skinId,onSportsRemoteLoginSuccess.payload["accessToken"]);
                //this.router.navigate(['/prematch']);
                //this.loginservice.onPlayerLoggedIn(true);
                return;
            }
            this.loginservice.onPlayerLoggedIn(false);
        })
    )


    @Effect()
    registerStart = this.actions$.pipe(
        ofType(loginActions.REGISTER_START),
        exhaustMap((onRegisterStart: loginActions.RegisterStart) =>
            this.loginservice.onRegister(onRegisterStart.payload)
                .pipe(
                    map((response:Login) => {
                        return new loginActions.RegisterSuccess(response);
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return handleError(error, loginActions.RegisterFail);
                    })
                )
        )
    )


    @Effect({ dispatch: false })
    registerSuccess = this.actions$.pipe(
        ofType(loginActions.REGISTER_SUCCESS),
        tap((onRegisterSuccess: loginActions.RegisterSuccess) => {
            if (onRegisterSuccess.payload.success === true) {
                sessionStorage.setItem('wsession', onRegisterSuccess.payload.loginResponse.sessionId);
                this.router.navigate(['/slots']);
                this.loginservice.onPlayerLoggedIn(true);
                return;
            }
            this.loginservice.onPlayerLoggedIn(false);
        })
    )



    @Effect()
    registerGetCountry = this.actions$.pipe(
        ofType(loginActions.REGISTER_GET_COUNTRY),
        exhaustMap((onRegisterGetCountry: loginActions.RegisterGetCountry) =>
            this.loginservice.onGetRegisterCountry()
                .pipe(
                    map((response: Countries) => {
                        return new loginActions.RegisterGetCountrySuccess(response);
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return handleError(error, loginActions.RegisterGetCountryFail);
                    })
                )
        )
    )


    @Effect()
    logoutStart = this.actions$.pipe(
        ofType(loginActions.LOGOUT_START),
        exhaustMap((onLogOutStart: loginActions.LoginStart) =>
            this.loginservice.onLogOut()
                .pipe(
                    map((response: Login) => {
                        return new loginActions.LogoutSuccess(response);
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return handleError(error, loginActions.LogoutFail);
                    })
                )
        )
    )

    @Effect({ dispatch: false })
    logoutSuccess = this.actions$.pipe(
        ofType(loginActions.LOGOUT_SUCCESS),
        tap((onLogOutSuccess: loginActions.LogoutSuccess) => {
            if (onLogOutSuccess.payload["success"] === true) {
                sessionStorage.removeItem('wsession');
                sessionStorage.removeItem('profile');
                this.router.navigate(["/home"]);
                this.loginservice.onPlayerLoggedIn(false);
            }
        })
    )


    @Effect()
    forgotPasswordStart = this.actions$.pipe(
        ofType(loginActions.FORGOTPASSWORD_START),
        exhaustMap((onForgoutPasswordStart: loginActions.ForgotPasswordStart) =>
            this.loginservice.onForgotPassword(onForgoutPasswordStart.payload)
                .pipe(
                    map(response => {
                        return new loginActions.ForgotPasswordSuccess(response);
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return handleError(error, loginActions.ForgotPasswordFail);
                    })
                )
        )
    )

    @Effect({ dispatch: false })
    forGotPasswordSuccess = this.actions$.pipe(
        ofType(loginActions.FORGOTPASSWORD_SUCCESS),
        tap((onForgotPasswordSuccess: loginActions.ForgotPasswordSuccess) => {
            if (onForgotPasswordSuccess.payload["success"] === true) {
                //this.router.navigate(["/login"]);
            }
        })
    )

    @Effect()
    registerGenarateOTP = this.actions$.pipe(
        ofType(loginActions.REGISTER_GENERATE_OTP),
        exhaustMap((onRegisterGenarateOTPStart: loginActions.RegisterGenarateOTP) =>
            this.loginservice.onRegisterGenarateOTPStart(onRegisterGenarateOTPStart.payload)
                .pipe(
                    map(response => {
                        return new loginActions.RegisterGenarateOTPSuccess(response);
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return handleError(error, loginActions.RegisterGenarateOTPFail);
                    })
                )
        )
    )
    @Effect()
    allGamesStart = this.actions$.pipe(
        ofType(loginActions.ALL_GAMES_START),
        exhaustMap((onallGamesStart: loginActions.allGamesStart) => 
        this.loginservice.AllGamesData()
                .pipe(
                    map((response:AllGames)=> {
                        return new loginActions.allGamesSuccess(response);
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return handleError(error, loginActions.allGamesFail);
                    })
                )
        )
    )
    @Effect()
    allGamesLunchStart = this.actions$.pipe(
        ofType(loginActions.ALL_GAMES_LUNCH_START),
        exhaustMap((onallGamesLunchStart: loginActions.allGamesLunchStart) => 
        this.loginservice.AllGamesLunch(onallGamesLunchStart.payload)
                .pipe(
                    map((response:AllGameLunch)=> {
                        return new loginActions.allGamesLunchSuccess(response);
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return handleError(error, loginActions.allGamesLunchFail);
                    })
                )
        )
    )
}