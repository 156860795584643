import { Component, OnInit} from '@angular/core';
import { Store } from '@ngrx/store';
import * as appState from 'src/app/source/appstates/appState';
import { Subscription } from 'rxjs';
import * as cashierActions from 'src/app/source/appstates/cashierstates/cashierActions';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray  } from '@angular/forms';
import * as moment from 'moment';
import { TrasactionHistory } from 'src/app/source/modules/cashier/trasactionhistory';
import { CashierState } from 'src/app/source/appstates/cashierstates/cashierState';
@Component({
  selector: 'app-transaction-history',
  templateUrl: './transaction-history.component.html',
  styleUrls: ['./transaction-history.component.scss'],
  providers: []
})
export class TransactionHistoryComponent implements OnInit{

  private storeSub: Subscription;
  TrasactionHistory:TrasactionHistory;
  transactiondata:any;
  form: FormGroup;
  forms: FormGroup;
  typeForm: FormGroup;

  errormessage:string;
  // types = ["Deposit", "Cash Adjustment", "Chargeback",
  //   "Cashout", "Bonus Adjustment", "Transfer Cancellation",
  //   "Transfer to Agent", "Transfer from Agent", "Referring Revenue", "Cashout Reverse",
  //   "Manual Deposit"]

  types = [
    {
      language: 'Deposits',
      senttype: "Deposits"
    },
    {
      language: 'Cashout',
      senttype: "Cashout"
    },
    {
      language: 'Bonus',
      senttype: "Bonus"
    },
    {
      language: 'Adjustment',
      senttype: "Adjustment"
    }, 
  ];
    // typesValue="Deposit,Cash Adjustment,Chargeback,Cashout,Bonus Adjustment,Transfer Cancellation,Transfer to Agent,Transfer from Agent,Referring Revenue,Cashout Reverse,Manual Deposit"
    typesValue="Deposit,Cashout,Bonus Adjustment"

    startDate:Date;
    endDate:Date;
  steddate: any;
  todaydate: any;
  selectedType: any;

  constructor(private store: Store<appState.AppState>, private fb: FormBuilder,) {
  
  }
  // ngOnDestroy(): void {
  //   throw new Error("Method not implemented.");
  // }

  ngOnInit() {
    this.typeForm = this.fb.group({
      typesValue: this.fb.array([])
    })

    this.todaydate = moment(new Date()).format('YYYY-MM-DD');
    this.endDate = new Date();
    let today = new Date(this.endDate.getFullYear(), this.endDate.getMonth(), this.endDate.getDate() - 7);
    this.startDate =  today;
    this.form = new FormGroup({
      "currency": new FormControl("USD"),
      "startDate": new FormControl(null, Validators.required),
      "endDate": new FormControl(null, Validators.required),
      "limit": new FormControl("100"),
      "index": new FormControl("0"),
      "type": new FormControl(this.typesValue, Validators.required),
      // "checkBox": new FormControl ('')
    })
    this.forms = new FormGroup({
      "currency": new FormControl("USD"),
      "startDate": new FormControl(null, Validators.required),
      "endDate": new FormControl(null, Validators.required),
      "limit": new FormControl("100"),
      "index": new FormControl("0"),
      "type": new FormControl(this.typesValue, Validators.required),
      // "checkBox": new FormControl ('')
    })
    this.form.valueChanges.subscribe(x => {
      let yearsDiff = 0;
      var ToDate = new Date();
      var pastYear = ToDate.getFullYear() - yearsDiff;
      ToDate.setFullYear(pastYear);
      if (new Date(x.startDate).getTime() > ToDate.getTime() || new Date(x.startDate).getTime() > new Date(x.endDate).getTime() || new Date(x.endDate).getTime() > ToDate.getTime()) {
        this.steddate = true; 
      } else {
        this.steddate = false;
      }
    });
    this.storeSub = this.store.select("cashierState").subscribe(
      (cashierState: CashierState) => {
        if(cashierState.TrasactionHistory){
          if(cashierState.TrasactionHistory.success==true){
            this.errormessage ="";
            this.transactiondata = cashierState.TrasactionHistory.values;
        }else if(cashierState.TrasactionHistory.success==false){
          this.transactiondata = "";
          this.errormessage = cashierState.TrasactionHistory.description;
        }
      }
      if(cashierState.TrasactionHistoryerror){
        this.transactiondata = "";
        this.errormessage = cashierState.TrasactionHistoryerror.message;
      }
      })
      this.types.forEach(value => this.onChangetype(value.senttype, true));

  }
  onChangetype(typesValue: string, isChecked: boolean) {
    const typesFormArray = <FormArray>this.typeForm.controls.typesValue;
    if (isChecked) {
      typesFormArray.push(new FormControl(typesValue));
    } else {
      let index = typesFormArray.controls.findIndex(x => x.value == typesValue)
      typesFormArray.removeAt(index);
    }
    this.selectedType = this.typeForm.value.typesValue;
  }
  // onStartDateChange($event){
  //   console.log($event);
  //   // this.startDate = $event["month"]+"-"+$event["day"]+"-"+$event["year"];
  //   this.startDate = $event["day"]+"-"+$event["month"]+"-"+$event["year"];
  // }
  // onEndDateChange($event){
  //   console.log($event);
  //  // this.endDate = $event["month"]+"-"+$event["day"]+"-"+$event["year"];
  //  this.endDate = $event["day"]+"-"+$event["month"]+"-"+$event["year"];
  // }
  
  // get today() {
  //   return new Date();
  // }
  onFormSubmit(){
    this.forms.value.startDate = moment(this.forms.value.startDate).format('DD-MM-YYYY');
    this.forms.value.endDate= moment(this.forms.value.endDate).format('DD-MM-YYYY');
    this.store.dispatch(new cashierActions.CashierGetBalanceStart());
    this.store.dispatch(new cashierActions.CashierTransactionHistory(this.form.value));
  }

}
