import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home.component';
import { ProfileComponent } from './player/profile/profile.component';
import { DepositComponent } from './cashier/deposit/deposit.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { MainComponent } from './main/main.component';
import { WithdrawComponent } from './cashier/withdraw/withdraw.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { TransactionHistoryComponent } from './transaction-history/transaction-history.component';
import { GameHistoryComponent } from './game-history/game-history.component';
import { TournamentsListComponent } from './tournaments-list/tournaments-list.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { BankAccountComponent } from './cashier/bank-account/bank-account.component';
import { PromotionsComponent } from './promotions/promotions.component';
import { HowtoplayComponent } from './howtoplay/howtoplay.component';
import {DepositResponseComponent} from './cashier/depositresponse/depositresponse.component';
import {RakestructureComponent} from './aboutus/rakestructure/rakestructure.component';
import {PrivacypolicyComponent} from './aboutus/privacypolicy/privacypolicy.component';
import {TermsandconditionsComponent} from './aboutus/termsandconditions/termsandconditions.component';
import {TaxationComponent} from './aboutus/taxation/taxation.component';
import {EulaComponent} from './aboutus/eula/eula.component';
import {SoftwarecertificationComponent} from './aboutus/softwarecertification/softwarecertification.component';
import {CurrencyExchangeComponent} from './cashier/currency-exchange/currency-exchange.component';
import { CasinoGamesComponent } from './casino-games/casino-games.component';
import { PokerComponent } from './poker/poker.component';
import {SlotsComponent} from './slots/slots.component';
import {SlotsandtablegamesComponent} from './slotsandtablegames/slotsandtablegames.component';
import {TablgamesComponent} from '../../tablgames/tablgames.component';
import { CasinoHistoryComponent } from './casino-history/casino-history.component';
import { LotteryComponent } from './lottery/lottery.component';
import { PrivecyPolicy1Component } from 'src/app/privecy-policy1/privecy-policy1.component';
import { MybalanceComponent } from 'src/app/skins/mybalance/mybalance.component';
import { ExchangeComponent } from 'src/app/skins/exchange/exchange.component';
import { VirtualGamesComponent } from 'src/app/skins/virtual-games/virtual-games.component';


const routes: Routes = [
  
    {path:"",component:HomeComponent,
    children:[
        {path:"",redirectTo:'home',pathMatch: 'full'},
        // {path:"", redirectTo:'slots'},
        {path:"home",component:MainComponent},
        {path:"profile",component:ProfileComponent},
        {path:"mybalance", component:MybalanceComponent},
          {path:"exchange", component:ExchangeComponent},
        {path:"deposit", component:DepositComponent},
        {path:"aboutus", component:AboutusComponent},
        {path:"withdraw", component:WithdrawComponent},
        {path:"transactionhistory", component:TransactionHistoryComponent},
        {path:"gamehistory", component:GameHistoryComponent},
        {path:"casinogamehistory", component:CasinoHistoryComponent},
        {path:"tournaments", component:TournamentsListComponent},
        {path:"contactus", component:ContactUsComponent},
        {path:"bankaccount", component:BankAccountComponent},
        {path:"promotions", component:PromotionsComponent},
        {path:"howtoplay", component:HowtoplayComponent},
        {path:"rakestructure", component:RakestructureComponent},
        {path:"privacypolicy", component:PrivacypolicyComponent},
        // {path: 'priveyPolicy1', component:PrivecyPolicy1Component},
        {path:"termsandconditions", component:TermsandconditionsComponent},
        {path:"taxation", component:TaxationComponent},
        {path:"eula", component:EulaComponent},
        {path:"softwarecertification", component:SoftwarecertificationComponent},
        {path:"currency-exchange", component:CurrencyExchangeComponent},
        {path: 'casino-games', component: CasinoGamesComponent},
        {path: 'poker', component: PokerComponent},
        {path: 'slots', component: SlotsComponent},
        {path: 'virtual', component:VirtualGamesComponent},
        {path: 'tablegames', component: TablgamesComponent},
        {path: 'lottery', component: LotteryComponent},
        {path: 'slotsandtablegames', component: SlotsandtablegamesComponent},
        
    ]},
    
    { path: 'lobby/lobbyflash', redirectTo: 'lobby/lobbyflash.html'},
    { path: 'lobby/tableflash', redirectTo: 'lobby/tableflash.html'},
    {path:"depositresponse", component:DepositResponseComponent},
    { path: '**', component: PagenotfoundComponent }

  ];

@NgModule({
    imports: [
        RouterModule.forRoot(routes)
      ],
      exports: [RouterModule]
})
export class HomeRoutingModule { }
