import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-casino-games',
  templateUrl: './casino-games.component.html',
  styleUrls: ['./casino-games.component.scss'],
})
export class CasinoGamesComponent implements OnInit {

  constructor() {window.scroll(0,0); }

  ngOnInit() {}

}
