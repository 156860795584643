import { viewClassName } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import * as appState from '../../../source/appstates/appState';
import * as loginActions from '../../../source/appstates/loginstates/loginActions';
import { Subscription } from 'rxjs';
import { LoginState } from 'src/app/source/appstates/loginstates/loginState';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  // mainMenu;
  playerLoggedIn: boolean = false;
  private storeSub: Subscription;
  showPleaseLogin: boolean = false;
  // showNav: boolean = false;
  sessionid: any;
  constructor(private store: Store<appState.AppState>, private router: Router, private route: ActivatedRoute) {


    this.route.queryParams.subscribe(params => {
      this.sessionid = params.sid;
      if (this.sessionid != null) {
        sessionStorage.setItem('wsession', this.sessionid);
        let value = true;
        this.store.dispatch(new loginActions.PlayerLoggedIn({ loggedIn: value }));
        return;
      }
    });
  }

  ngOnInit() {
    this.storeSub = this.store.select("loginState").subscribe(
      (loginState: LoginState) => {
        if (loginState.playerLoggedIn) {
          if (loginState.playerLoggedIn.loggedIn) {
            this.playerLoggedIn = loginState.playerLoggedIn.loggedIn;
          } else {
            this.playerLoggedIn = false;
          }
        }
      })
  }
  showLogin() {
    if (!this.playerLoggedIn) {
      this.showPleaseLogin = true;
    }
  }
  closeLogPop() {
    this.showPleaseLogin = false;
  }

}
