import { Component, OnInit, ViewChild, OnDestroy, ComponentFactoryResolver } from '@angular/core';
import { Store } from '@ngrx/store';
import * as appState from 'src/app/source/appstates/appState';
import * as loginActions from 'src/app/source/appstates/loginstates/loginActions';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LoginState } from 'src/app/source/appstates/loginstates/loginState';
import { QuickloginComponent } from '../quicklogin/quicklogin.component';
import { PlaceholderDirective } from '../PlaceholderDirective';
import { PlayerGetProfile } from 'src/app/source/appstates/playerstates/playerActions';
import { ProfileState } from 'src/app/source/appstates/playerstates/playerState';
import { Router, ActivatedRoute } from '@angular/router';
import { CashierState } from 'src/app/source/appstates/cashierstates/cashierState';

import { LoginService } from '../../../../source/services/login/login.service';

import * as cashierActions from 'src/app/source/appstates/cashierstates/cashierActions';
import { Balance, WalletInfo } from 'src/app/source/modules/cashier/balance';
declare var $ : any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {

  mainMenu;
  private storeSub: Subscription;
  private closeSub: Subscription;
  private storeProfileSub: Subscription;
  playerName: String;
  dropdown:boolean = false;

  cashier:Balance;
  walleteInfo:WalletInfo[];
  balance:any;
  playertype:any;
  playerLoggedIn: boolean = false;

  @ViewChild(PlaceholderDirective, { static: false }) alertHost: PlaceholderDirective;

  constructor(private store: Store<appState.AppState>,
    private loginService:LoginService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private router: Router,
    private route: ActivatedRoute) {
      
    this.store.dispatch(new cashierActions.CashierGetBalanceStart());
     }

 
  ngOnInit() {
    
    $(document).ready(function(){
      $('.btn_line').click(function(){ 
        $('.btn_line_menu').slideToggle();
      })

      // if ($(window).width() < 601){
      //   $('.btn_line_menu').click(function(){ 
      //   $('.btn_line_menu').slideToggle();
      //   })
      // }
      if ($(window).width() < 991) {
        $('.btn_line_menu').click(function(){
        $('.btn_line_menu').slideToggle();
      })
    }
    })
    this.route.url.subscribe(response => {
      console.log(response);
    })

    if (!this.playerLoggedIn) {
      this.mainMenu = environment.mainMenu.menu;
    }

    this.storeSub = this.store.select("loginState").subscribe(
      (loginState: LoginState) => {
        if (loginState.playerLoggedIn) {
          if (loginState.playerLoggedIn.loggedIn) {
            this.mainMenu = environment.mainMenu.loggedInMenu;
            this.playerLoggedIn = loginState.playerLoggedIn.loggedIn;
            this.store.dispatch(new PlayerGetProfile());
            this.store.dispatch(new cashierActions.CashierGetBalanceStart());
            this.getBalance()
          } else {
            this.mainMenu = environment.mainMenu.menu;
            this.playerLoggedIn = false;
          }
        }
      })
    this.storeProfileSub = this.store.select("playerState").subscribe(
      (profileState: ProfileState) => {
        if (profileState.profile) {
          if (profileState.profile.login) {
            this.playerName = profileState.profile.login;
          }

        }

      })
  
    this.store.dispatch(new PlayerGetProfile());
  }
getBalance(){
  this.storeSub = this.store.select("cashierState").subscribe(
    (cashierState: CashierState) => {
      if (cashierState.balance) {
        this.cashier = cashierState.balance;
        this.walleteInfo = cashierState.balance.values;
        for (let wallet of this.walleteInfo) {
          if (wallet.wallet.name == 'USD'){
            this.balance =  wallet.cash.value + wallet.bonus.value;
            console.log(this.balance)
            this.playertype = wallet.wallet.name;
          }
      }
      }
    }
  )
}
  openMenu(){
    $('.btn_line_menu').slideToggle();
  }

  ngOnDestroy() {
    if (this.closeSub) {
      this.closeSub.unsubscribe();
    }
    if (this.storeSub) {
      this.storeSub.unsubscribe();
    }
  }
  showPopUp(value) {
    this.store.dispatch(new loginActions.ResetState());
    const alertCmpFactory = this.componentFactoryResolver.resolveComponentFactory(
      QuickloginComponent
    );

    const hostViewContainerRef = this.alertHost.viewContainerRef;
    hostViewContainerRef.clear();
    const componentRef = hostViewContainerRef.createComponent(alertCmpFactory);

    componentRef.instance.formState = value;
    this.closeSub = componentRef.instance.close.subscribe(() => {
      this.closeSub.unsubscribe();
      hostViewContainerRef.clear();
    });

  }
  onClickLogout() {
    // this.loginService.onLogOut().subscribe(data => console.log(data))
    this.store.dispatch(new loginActions.LogoutStart());
    this.playerLoggedIn = !this.playerLoggedIn; 
  }
  show_hide(){
    alert("check")
    this.dropdown = !this.dropdown;
  }
}
