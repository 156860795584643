import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-eula',
  templateUrl: './eula.component.html',
  styleUrls: ['./eula.component.scss'],
})
export class EulaComponent implements OnInit {

  constructor() {window.scroll(0,0); }

  ngOnInit() {}

}
