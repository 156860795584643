import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import * as appState from 'src/app/source/appstates/appState';
import { Profile } from 'src/app/source/modules/player/profile';
import * as playerActions from 'src/app/source/appstates/playerstates/playerActions';
import { ProfileState } from 'src/app/source/appstates/playerstates/playerState';
import * as loginActions from 'src/app/source/appstates/loginstates/loginActions';
import { Countries, Country } from 'src/app/source/modules/login/countries';
import { LoginState } from 'src/app/source/appstates/loginstates/loginState';
import { data } from 'jquery';

declare var $: any;
function showLoginModal() {
  // this.errorMessage = false;
  $("#login").modal("show");
}
function hideLoginModal() {
  $("#login").modal("hide");
}

@Component({
  selector: 'app-update-porfile',
  templateUrl: './update-porfile.component.html',
  styleUrls: ['./update-porfile.component.scss'],
})
export class UpdatePorfileComponent implements OnInit, OnDestroy {
  @Input() formState: string;
  @Output() close = new EventEmitter<void>();

  errorMessage: string;
  private storeSub: Subscription;
  updateForm: FormGroup;
  updatePassword: FormGroup;

  countries: Countries;
  country: Country[];

  updateProfileState: string = "UPDATEFORM";
  updatePasswordState: string = "UPDATEPASSWORD";
  profileFormState: string = this.updateProfileState;

  profile: Profile;
  responsePop: boolean = false;

  constructor(private store: Store<appState.AppState>) { }

  ngOnInit() {
    
    this.store.dispatch(new playerActions.ResetState());
    if (this.formState) {
      this.profileFormState = this.formState;
    }
    this.changeFormState(this.profileFormState);

    this.updateForm = new FormGroup({
      "nickname": new FormControl('', Validators.required),
      "email": new FormControl(null, [Validators.required, Validators.email]),
      "firstName": new FormControl('', Validators.required),
      "lastName": new FormControl('', Validators.required),
      "address": new FormGroup({
        "city": new FormControl('', Validators.required),
        "phone": new FormControl('', Validators.required),
        "country": new FormControl('', Validators.required),
      }),
    })

    this.updatePassword = new FormGroup({
      "oldPassword": new FormControl('', Validators.required),
      "newPassword": new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(15),])
    })

    this.storeSub = this.store.select("loginState").subscribe(
      (loginState: LoginState) => {
        if (loginState.countries) {
          this.countries = loginState.countries;
          this.country = this.countries.values;
        }
      }
    )

    this.storeSub = this.store.select("playerState").subscribe(
      (playerState: ProfileState) => {
        console.log( playerState.profileUpdateResponse )
        if (playerState.profileUpdateResponse) {
          if (playerState.profileUpdateResponse["success"] === false) {
            this.errorMessage = playerState.profileUpdateResponse["description"];
            // debugger
            if(this.errorMessage == "Invalid argument"){
              this.errorMessage = "Invalid old password"
            }
            hideLoginModal();
          }
          if (playerState.profileUpdateResponse["success"] === true) {
            // debugger
            this.errorMessage = "Updated successfully";
            console.log(this.errorMessage)
            hideLoginModal();
          }
          setTimeout(() => {
            this.errorMessage = null;
          }, 5000);
        }
        
        if (playerState.profileUpdateFail) {
          console.log(playerState.profileUpdateFail)
          this.errorMessage = playerState.profileUpdateFail.message;
          console.log(this.errorMessage)
          hideLoginModal();
        }
        if (playerState.profile) {
          this.profile = playerState.profile;
          if (this.profile.nickname) {
            (<FormControl>this.updateForm.get('nickname')).setValue(this.profile.nickname);
          }
          if (this.profile.email) {
            (<FormControl>this.updateForm.get('email')).setValue(this.profile.email);
          }
          if (this.profile.firstName) {
            (<FormControl>this.updateForm.get('firstName')).setValue(this.profile.firstName);
          }
          if (this.profile.lastName) {
            (<FormControl>this.updateForm.get('lastName')).setValue(this.profile.lastName);
          }
          if (this.profile.address) {
            if (this.profile.address.city) {
              (<FormControl>this.updateForm.get('address')).get("city").setValue(this.profile.address.city);
            }
            if (this.profile.address.phone) {
              (<FormControl>this.updateForm.get('address')).get("phone").setValue(this.profile.address.phone);
            }
            if (this.profile.address.country) {
              (<FormControl>this.updateForm.get('address')).get("country").setValue(this.profile.address.country);
            }
          }
        }
      }
    )
    
    this.store.dispatch(new loginActions.RegisterGetCountry());
    this.store.dispatch(new playerActions.PlayerGetProfile());

    showLoginModal();
    $("#login").on('hidden.bs.modal', function () {
      // this.onClose();
    }.bind(this));
  }
  changeFormState(state) {
    // this.store.dispatch(new loginActions.ResetState());
    this.profileFormState = state;
  }
  onInputFocus() {

  }
  onFormSubmit() {
    let body = {
      nickname: this.updateForm.value.nickname,
      email: this.updateForm.value.email,
      firstName: this.updateForm.value.firstName,
      lastName: this.updateForm.value.lastName,
      city: this.updateForm.value.city,
      phone: this.updateForm.value.phone,
      country:this.updateForm.value.country

    }
    this.store.dispatch(new playerActions.PlayerUpdateProfile(body));
    
  }
  onUpdatePasswordSubmit() {
    this.store.dispatch(new playerActions.PlayerUpdatePassword(this.updatePassword.value));
  }
  // onClose() {
  //   console.log("update profile close popup");
  //   this.responsePop = true;
  //   this.close.emit();
  // }
  ngOnDestroy() {
    if (this.storeSub) {
      this.storeSub.unsubscribe();
    }
  }
  closePop(){
    this.errorMessage = null;
  }

}
