import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PlayerService } from '../player/player.service';
import {environment} from 'src/environments/environment';
import { Balance } from '../../modules/cashier/balance';
import { PaymentSystems } from '../../modules/cashier/paymentSystems';
import { DepositResponse } from '../../modules/cashier/depositResponse';
import { WithDrawResponse } from '../../modules/cashier/withdrawResponse';
import { Store } from '@ngrx/store';
import { AppState } from '../../appstates/appState';
import { CashierGetOpenWithDrawRequest, CashierGetBalanceStart, CashierGetBankAccount } from '../../appstates/cashierstates/cashierActions';
import { LoginService } from '../login/login.service';
import { BankAccountInfo } from '../../modules/cashier/bankAccountInfo';
import { DepositSystems } from '../../modules/cashier/depositSystems';
import { WithdrawSystems } from '../../modules/cashier/withDraw';
import { TrasactionHistory } from '../../modules/cashier/trasactionhistory';

@Injectable({
  providedIn: 'root'
})
export class CashierService {

  constructor(private httpClient: HttpClient,
              private playerservice: PlayerService,
              private loginservice: LoginService,
              private store: Store<AppState>) { }

  onCashierGetBalance() {
    return this.httpClient.post<Balance>(`${environment.appApi.baseUrl}${environment.appApi.cashier.balance}`, {}, this.playerservice.httpOptions());
  }

  onCashierResetPlayMoney() {
    return this.httpClient.post(`${environment.appApi.baseUrl}${environment.appApi.cashier.resetPlayMoneyBalance}`, {}, this.playerservice.httpOptions());
  }

  onCashierGetPaymentSystems() {
    return this.httpClient.post<PaymentSystems>(`${environment.appApi.baseUrl}${environment.appApi.cashier.getPaymentSystems}`, {}, this.playerservice.httpOptions());
  }

  onCashierGetDepositSystems(pgid) {
    return this.httpClient.post<DepositSystems>(`${environment.appApi.baseUrl}${environment.appApi.cashier.depositSystems}` + '?pgid=' + pgid.id, {}, this.playerservice.httpOptions());
  }
  onCashierGetWithdrawSystems(pgid) {
    // return this.httpClient.get<WithdrawSystems>("/assets/cashoutinfo.json");
   return this.httpClient.post<WithdrawSystems>(`${environment.appApi.baseUrl}${environment.appApi.cashier.withdrawSystems}` + '?pgid=' + pgid.id, {}, this.playerservice.httpOptions());
  }

  onCashierWithdrawCashout(postData) {
    return this.httpClient.post(`${environment.appApi.baseUrl}${environment.appApi.cashier.withDrawCashout}`, postData, this.playerservice.httpOptions());
   }

  onCashierDeposit(postData) {
    return this.httpClient.post<DepositResponse>(`${environment.appApi.baseUrl}${environment.appApi.cashier.deposit}`, postData, this.playerservice.httpOptions());
  }

  onCashierGetOpenWithdrawRequest() {
    return this.httpClient.post<WithDrawResponse>(`${environment.appApi.baseUrl}${environment.appApi.cashier.getOpenWithdrawRequests}`, {}, this.playerservice.httpOptions());
  }

  onCashierCancelWithdrawRequest(postData) {
    return this.httpClient.post(`${environment.appApi.baseUrl}${environment.appApi.cashier.cancelWithdrawRequest}`, postData, this.playerservice.httpOptions());
  }

  onCashierWithdrawRequest(postData) {
    return this.httpClient.post(`${environment.appApi.baseUrl}${environment.appApi.cashier.withdraw}`, postData, this.playerservice.httpOptions());
  }
  onCashierTransactionHistory(postData) {
    return this.httpClient.post<TrasactionHistory>(`${environment.appApi.baseUrl}${environment.appApi.history.transaction}`, postData, this.playerservice.httpOptions());
  }
  onCashierAddBankAccount(postData) {
    return this.httpClient.post(`${environment.appApi.baseUrl}${environment.appApi.cashier.addBankAccount}`, postData, this.playerservice.httpOptions());
  }

  onCashierGetBankAccount() {
    return this.httpClient.post<BankAccountInfo>(`${environment.appApi.baseUrl}${environment.appApi.cashier.getBankAccounts}`, {}, this.playerservice.httpOptions());
  }
  onCashierDeleteBankAccount(postData) {
    return this.httpClient.post<BankAccountInfo>(`${environment.appApi.baseUrl}${environment.appApi.cashier.deleteBankAccounts}`, postData, this.playerservice.httpOptions());
  }

  onCashierCancelWitdrawSuccess() {
    this.store.dispatch(new CashierGetOpenWithDrawRequest());
    this.store.dispatch(new CashierGetBalanceStart());
  }

  getCashierBankAccounts() {
    this.store.dispatch(new CashierGetBankAccount());
  }

}
