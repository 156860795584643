import { Actions, Effect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import * as playerActions from './playerActions';
import { exhaustMap, map, catchError, tap } from 'rxjs/internal/operators';
import { PlayerService } from '../../services/player/player.service';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import { Profile } from '../../modules/player/profile';
import { Router } from '@angular/router';
import { LoginService } from '../../services/login/login.service';
import { GameLaunchURLs } from '../../modules/player/gameLaunchURLs';
import { TournamentsList } from '../../modules/player/tournamentsList';
import { Stats } from '../../modules/player/stats';
import { GameHistoryData } from '../../modules/gamehistory';
import { RemoteGamesHistory } from '../../modules/remotegamehistory/remotegamehistory';

const handleError = (error: any, actions: any) => {
    if (error.error instanceof Error) {
        return of(new actions({ "message": error.error.message }));
    } else {
        try {
            if (error.error.message) {
                return of(new actions({ "message": error.error.message }));
            } else if (error.message) {
                return of(new actions({ "message": error.message }));
            }
            return of(new actions({ "message": "some thing went wrong please contact admin" }));
        } catch (error) {

        }
    }
    return of(new actions({ "message": "some thing went wrong please contact admin" }));
}

@Injectable()
export class PlayerEffects {
    responsePop: boolean = false;
    constructor(private actions$: Actions,
        private playerService: PlayerService,
        private router: Router,
        private loginservice: LoginService, ) { }
    @Effect()
    playerGetProfile = this.actions$.pipe(
        ofType(playerActions.PLAYER_GET_PROFILE),
        exhaustMap((onPlayerGetProfile: playerActions.PlayerGetProfile) =>
            this.playerService.onPlayerGetProfile()
                .pipe(
                    map((response: Profile) => {
                        return new playerActions.PlayerGetProfileSuccess(response);
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return handleError(error, playerActions.PlayerGetProfileFail);
                    })
                )
        )
    )

    @Effect({ dispatch: false })
    playerGetProfileSuccess = this.actions$.pipe(
        ofType(playerActions.PLAYER_GET_PROFILE_SUCCESS),
        tap((onPlayerGetProfileSuccess: playerActions.PlayerGetProfileSuccess) => {
            if (onPlayerGetProfileSuccess.payload.code === "SESSION_EXPIRED") {
                this.loginservice.onPlayerLoggedIn(false);
                this.router.navigate(["/home"]);
            }
            if (onPlayerGetProfileSuccess.payload.success === true) {
                sessionStorage.setItem('profile', onPlayerGetProfileSuccess.payload.login);
                this.loginservice.onPlayerLoggedIn(true);
            }

        })
    )


    @Effect()
    playerUpdateProfile = this.actions$.pipe(
        ofType(playerActions.PLAYER_UPDATE_PROFILE),
        exhaustMap((onPlayerUpdateProfile: playerActions.PlayerUpdateProfile) =>
            this.playerService.onPlayerUpdateProfile(onPlayerUpdateProfile.payload)
                .pipe(
                    map((response: Profile) => {
                        return new playerActions.PlayerUpdateProfileSuccess(response);
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return handleError(error, playerActions.PlayerUpdateProfileFail);
                    })
                )
        )
    )

    @Effect({ dispatch: false })
    playerUpdateProfileSuccess = this.actions$.pipe(
        ofType(playerActions.PLAYER_UPDATE_PROFILE_SUCCESS),
        tap((onProfileUpdateSuccess: playerActions.PlayerUpdateProfileSuccess) => {
            if (onProfileUpdateSuccess.payload["code"] === "SESSION_EXPIRED") {
                this.router.navigate(["/home"]);
                this.loginservice.onPlayerLoggedIn(false);
            } else {
                this.router.navigate(["/profile"]);
            }
        })
    )

    @Effect()
    playerUpdatePassword = this.actions$.pipe(
        ofType(playerActions.PLAYER_UPDATE_PASSWORD),
        exhaustMap((onPlayerUpdatePassword: playerActions.PlayerUpdatePassword) =>
            this.playerService.onPlayerUpdatePassword(onPlayerUpdatePassword.payload)
                .pipe(
                    map((response: Profile) => {
                        return new playerActions.PlayerUpdatePasswordSuccess(response);
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return handleError(error, playerActions.PlayerUpdatePasswordFail);
                    })
                )
        )
    )

    @Effect()
    playerGetLaunchURLs = this.actions$.pipe(
        ofType(playerActions.PLAYER_GET_LAUNCHURLS),
        exhaustMap((onPlayerGetLaunchURLs: playerActions.PlayerGetLaunchURLs) =>
            this.playerService.onPlayerGetLaunchURLs()
                .pipe(
                    map((response: GameLaunchURLs) => {
                        return new playerActions.PlayerGetLaunchURLsSuccess(response);
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return handleError(error, playerActions.PlayerGetLaunchURLsFail);
                    })
                )
        )
    )

    @Effect({ dispatch: false })
    playerGetLaunchURLsSuccess = this.actions$.pipe(
        ofType(playerActions.PLAYER_GET_LAUNCHURLS_SUCCESS),
        tap((onPlayerGetLaunchURLsSuccess: playerActions.PlayerGetLaunchURLsSuccess) => {
            this.playerService.launchFlashGame(onPlayerGetLaunchURLsSuccess.payload);
        })
    )

    @Effect()
    playerGetGameHistory = this.actions$.pipe(
        ofType(playerActions.PLAYER_GET_GAME_HISTORY),
        exhaustMap((onPlayerGetGameHistory: playerActions.PlayerGetGameHistory) =>
            this.playerService.onPlayerGetGameHistory(onPlayerGetGameHistory.payload)
                .pipe(
                    map((response: GameHistoryData) => {
                        return new playerActions.PlayerGetGameHistorySuccess(response);
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return handleError(error, playerActions.PlayerGetGameHistoryFail);
                    })
                )
        )
    )
    @Effect()
    playerGetTournamentsList = this.actions$.pipe(
        ofType(playerActions.PLAYER_GET_TOURNAMENTS_LIST),
        exhaustMap((onPlayerGetTournamentsList: playerActions.PlayerGetTournamentsList) =>
            this.playerService.onPlayerGetTournamentsList(onPlayerGetTournamentsList.payload)
                .pipe(
                    map((response: TournamentsList) => {
                        return new playerActions.PlayerGetTournamentsListSuccess(response);
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return handleError(error, playerActions.PlayerGetTournamentsListFail);
                    })
                )
        )
    )

    @Effect({ dispatch: false })
    playerGetTournamentsListSuccess = this.actions$.pipe(
        ofType(playerActions.PLAYER_GET_TOURNAMENTS_LIST_SUCCESS),
        tap((onPlayerGetTournamentsListSuccess: playerActions.PlayerGetTournamentsListSuccess) => {

        })
    )

    @Effect()
    playerGetStats = this.actions$.pipe(
        ofType(playerActions.PLAYER_GET_STATS),
        exhaustMap((onPlayerGetStats: playerActions.PlayerGetStats) =>
            this.playerService.onPlayerGetStats()
                .pipe(
                    map((response: Stats) => {
                        return new playerActions.PlayerGetStatsSuccess(response);
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return handleError(error, playerActions.PlayerGetStatsFail);
                    })
                )
        )
    )

    @Effect()
    playerGetPlayerLevels = this.actions$.pipe(
        ofType(playerActions.PLAYER_GET_PLAYERLEVELS),
        exhaustMap((onPlayerGetPlayerLevels: playerActions.PlayerGetPlayerLevels) =>
            this.playerService.onPlayerGetPlayerLevels()
                .pipe(
                    map((response) => {
                        return new playerActions.PlayerGetPlayerLevelsSuccess(response);
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return handleError(error, playerActions.PlayerGetPlayerLevelsFail);
                    })
                )
        )
    )

    @Effect()
    playerLeaderBoardList = this.actions$.pipe(
        ofType(playerActions.PLAYER_GET_LEADERBORAD_LIST),
        exhaustMap((onPlayerLeaderBoardList: playerActions.PlayerLeaderBoardList) =>
            this.playerService.onPlayerLeaderBoardList()
                .pipe(
                    map((response) => {
                        return new playerActions.PlayerLeaderBoardListSuccess(response);
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return handleError(error, playerActions.PlayerLeaderBoardListFail);
                    })
                )
        )
    )

    @Effect()
    playerLeaderBoardGetParticipants = this.actions$.pipe(
        ofType(playerActions.PLAYER_GET_LEADERBORAD_GET_PARTICIPANTS),
        exhaustMap((onPlayerLeaderBoardGetParticipants: playerActions.PlayerLeaderBoardGetParticipants) =>
            this.playerService.onPlayerLeaderBoardGetParticipants()
                .pipe(
                    map((response) => {
                        return new playerActions.PlayerLeaderBoardGetParticipantsSuccess(response);
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return handleError(error, playerActions.PlayerLeaderBoardGetParticipantsFail);
                    })
                )
        )
    )
    @Effect()
    PlayerGetRemoteGameHistory = this.actions$.pipe(
        ofType(playerActions.PLAYER_GET_REMOTE_GAME_HISTORY),
        exhaustMap((onPlayerGetRemoteGameHistory: playerActions.PlayerGetRemoteGameHistory) =>
            this.playerService.onPlayerGetRemoteGameHistory(onPlayerGetRemoteGameHistory.payload)
                .pipe(
                    map((response: RemoteGamesHistory) => {
                        return new playerActions.PlayerGetRemoteGameHistorySuccess(response);
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return handleError(error, playerActions.PlayerGetRemoteGameHistoryFail);
                    })
                )
        )
    )
}