import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import * as appState from 'src/app/source/appstates/appState';
import { Subscription } from 'rxjs';
import { Balance, WalletInfo } from 'src/app/source/modules/cashier/balance';
import { CashierState } from 'src/app/source/appstates/cashierstates/cashierState';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as cashierActions from 'src/app/source/appstates/cashierstates/cashierActions';
import { WithDrawResponse, WithDrawsResponses } from 'src/app/source/modules/cashier/withdrawResponse';
import { DepositSystemsClass } from 'src/app/source/modules/cashier/depositSystems';
import { CashOutInfo } from 'src/app/source/modules/cashier/withDraw';
import { ProfileState } from 'src/app/source/appstates/playerstates/playerState';

import { Profile } from 'src/app/source/modules/player/profile';
import * as playerActions from 'src/app/source/appstates/playerstates/playerActions';
@Component({
  selector: 'app-withdraw',
  templateUrl: './withdraw.component.html',
  styleUrls: ['./withdraw.component.scss'],
})
export class WithdrawComponent implements OnInit, OnDestroy {
  private storeSub: Subscription;
  private profilestoreSub:Subscription;
  profile: Profile;
  cashier: Balance;
  walleteInfo: WalletInfo[];
  formResponseMessage: string;
  withDrawResponse: WithDrawsResponses[];
  cashoutInfo: CashOutInfo;
  selectedPayment: string = '';
  phonenumber:string;
  SlideOptions = {
    loop: false,
    navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
    dots: false, nav: true, autoplay: false, margin: 0, autoplayTimeout: 6000, smartSpeed: 1000,
    responsive: {
      0: {
        items: 2
      },
      500: {
        items: 3
      },
      768: {
        items: 5
      },
      992: {
        items: 6
      },
      1200: {
        items: 7
      },
      1920: {
        items: 7
      }
    }
  };

  form: FormGroup;
  constructor(private store: Store<appState.AppState>) { 
    this.store.dispatch(new playerActions.PlayerGetProfile());
  }

  ngOnInit() {

    this.form = new FormGroup({})


    this.profilestoreSub = this.store.select("playerState").subscribe(
      (playerState: ProfileState) => {
        if (playerState.profile) {
          this.profile = playerState.profile;
          this.phonenumber = this.profile.address.phone;
         // alert(this.phonenumber + " "+ "mobilenumber")
        }

      }
    )
    this.storeSub = this.store.select("cashierState").subscribe(
      (cashierState: CashierState) => {
        /* if (cashierState.withDrawResponse) {
          if (cashierState.withDrawResponse.withdrawsResponses) {
            this.withDrawResponse = cashierState.withDrawResponse.withdrawsResponses;
          }
        }
        if (cashierState.withDrawRequestResponse) {
          if (cashierState.withDrawRequestResponse["success"] === false) {
            this.formResponseMessage = cashierState.withDrawRequestResponse["description"];
          }
        }
        if (cashierState.withDrawRequestResponseFail) {
          this.formResponseMessage = cashierState.withDrawRequestResponseFail.message;
        } */
        if (cashierState.withDrawSystem) {
          this.cashoutInfo = cashierState.withDrawSystem.cashOutInfo;

          if (this.cashoutInfo.gateWays) {
            for (let payments of this.cashoutInfo.gateWays) {
              if (payments.selected) {
                this.selectedPayment = payments.type;
                break;
              }
            }

          }


          if (this.cashoutInfo) {
            for (let frm of this.cashoutInfo.params) {
              if (frm.required) {
                this.form.addControl(frm.name, new FormControl('', Validators.required));
              } else {
                this.form.addControl(frm.name, new FormControl(''));
              }
            }
          }

          if (cashierState.withDrawSystem.description) {
            this.formResponseMessage = cashierState.withDrawSystem.description;
          }
        }

      }
    )

    // this.store.dispatch(new cashierActions.CashierGetOpenWithDrawRequest());
    this.store.dispatch(new cashierActions.CashierGetWithdrawSystems({ id: null }));
  }
  onPaymentSelection(paymentName, selected) {
    console.log(paymentName, selected);
    this.form.reset();
    this.selectedPayment = paymentName;
    //this.showtransactionId = false;
    /* if (paymentName == 'CLOUD_AFRICA') {
      this.hideFormOnCouldAfrica = true;
    } else {
      this.hideFormOnCouldAfrica = false;
    } */
    this.store.dispatch(new cashierActions.CashierGetWithdrawSystems({ id: selected }));
  }

  onFormSubmit() {
    this.store.dispatch(new cashierActions.CashierWithDrawRequest(this.form.value));
  }

  onCancelWithdraw(refTxnID) {
    this.store.dispatch(new cashierActions.CashierCancelWithDrawRequest({ "cashoutId": refTxnID }));
  }

  ngOnDestroy() {
    if (this.storeSub) {
      this.storeSub.unsubscribe();
    }
  }

}
