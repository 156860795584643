import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import * as appState from 'src/app/source/appstates/appState';
import * as cashierActions from 'src/app/source/appstates/cashierstates/cashierActions';
import { CashierState } from 'src/app/source/appstates/cashierstates/cashierState';
import { Subscription } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { BankAccount } from 'src/app/source/modules/cashier/bankAccountInfo';
import { CashierService } from 'src/app/source/services/cashier/cashier.service';

@Component({
  selector: 'app-bank-account',
  templateUrl: './bank-account.component.html',
  styleUrls: ['./bank-account.component.scss'],
})
export class BankAccountComponent implements OnInit, OnDestroy {
  private storeSub: Subscription;
  form: FormGroup;
  showImg: boolean = true;
  bankAccountInfos:BankAccount[];
  uploadFileData(){
    console.log('ok')
  }
  uploadFile(){
    console.log('okk')
  }
  
  constructor(private store: Store<appState.AppState>, private cashierSer:CashierService) { }
  

  ngOnInit() {

    if(window.innerWidth <= 1380){
      this.showImg = false;
    }


    this.form = new FormGroup({
      "bankAccountNumber": new FormControl('', [Validators.required, Validators.pattern("[0-9]{9,18}")]),
      "bankName": new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]),
      "accountBranchName": new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]),
      "nameOnAccount": new FormControl('', [Validators.required,Validators.minLength(2),  Validators.pattern('^[a-zA-Z ]*$')]),
      "accountType": new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]),
      "personalNumber": new FormControl('', [Validators.required, Validators.pattern("^[A-Z]{4}0[A-Z0-9]{6}$")]),
    })

    this.storeSub = this.store.select("cashierState").subscribe(
      (cashierState: CashierState) => {
        if(cashierState.bankAccountInfo){
          console.log("test_1")
          console.log(cashierState.bankAccountInfo)

          if(cashierState.bankAccountInfo.success==true){
            console.log("test_2")
            console.log(cashierState.bankAccountInfo)
            if(cashierState.bankAccountInfo.bankAccountInfos){
              console.log("test_3")
            this.bankAccountInfos = cashierState.bankAccountInfo.bankAccountInfos;
            console.log(this.bankAccountInfos)
            this.form.reset();
          }
        }
      }
      })

    
    this.store.dispatch(new cashierActions.CashierGetBankAccount());
   // 
  }
  onFormSubmit() {
    let body = {
    accountBranchName:this.form.value.accountBranchName,
    accountType:this.form.value.accountType,
    bankAccountNumber:this.form.value.bankAccountNumber,
    bankName:this.form.value.bankName,
    nameOnAccount:this.form.value.nameOnAccount,
    personalNumber:this.form.value.personalNumber
    }
    console.log( body )
this.cashierSer.onCashierAddBankAccount(body).subscribe( 
  result => {
    this.respHandler(result)
  }, error => {
    console.log(error)
  }, () =>{

  }
  )
    // console.log(this.form.value);
    // this.store.dispatch(new cashierActions.CashierAddBankAccount(this.form.value));
  }
  respHandler(result){
    console.log( result )
  }
  onCloseAccount(encodedId){
   let obj =  { "bankEncodedIDs" : [encodedId]}
   this.store.dispatch(new cashierActions.CashierDeleteBankAccount(obj));
  }
  ngOnDestroy(){
    if(this.storeSub){
      this.storeSub.unsubscribe();
    }
  }

}
