import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as loginActions from '../../../../../source/appstates/loginstates/loginActions';
import { ActivatedRoute } from '@angular/router';
import * as appState from '../../../../../source/appstates/appState';
import { Subscription } from 'rxjs';
import { LoginState } from 'src/app/source/appstates/loginstates/loginState';
import { Location } from '@angular/common';

@Component({
  selector: 'app-privacypolicy',
  templateUrl: './privacypolicy.component.html',
  styleUrls: ['./privacypolicy.component.scss'],
})
export class PrivacypolicyComponent implements OnInit {
  playerLoggedIn: boolean = false;
  showPleaseLogin: boolean = false;
  private storeSub: Subscription;
  sessionid: any;
  constructor(private store: Store<appState.AppState>, private route: ActivatedRoute, private location:Location) {window.scroll(0,0); 
  
    this.route.queryParams.subscribe(params => {
      this.sessionid = params.sid;
      if (this.sessionid != null) {
        sessionStorage.setItem('wsession', this.sessionid);
        let value = true;
        this.store.dispatch(new loginActions.PlayerLoggedIn({ loggedIn: value }));
        return;
      }
    });}

  ngOnInit() {
    this.storeSub = this.store.select("loginState").subscribe(
      (loginState: LoginState) => {
        if (loginState.playerLoggedIn) {
          if (loginState.playerLoggedIn.loggedIn) {
            this.playerLoggedIn = loginState.playerLoggedIn.loggedIn;
          } else {
            this.playerLoggedIn = false;
          }
        }
      })
  }
  showLogin() {
    if (!this.playerLoggedIn) {
      this.showPleaseLogin = true;
    }
  }
  closeLogPop() {
    this.showPleaseLogin = false;
  }
  backClicked() {
    this.location.back();
  }

}
