import * as playerActions from './playerActions';
import { Profile } from '../../modules/player/profile';
import { TournamentsList } from '../../modules/player/tournamentsList';
import { Stats } from '../../modules/player/stats';
import { HttpClientError } from '../../modules/login/httpClientError';
import { GameHistoryData } from '../../modules/gamehistory';
import { RemoteGamesHistory } from '../../modules/remotegamehistory/remotegamehistory';

export interface ProfileState {
        profile: Profile;
        profileUpdateResponse: object;
        profileUpdateFail: HttpClientError;
        tournamentList: TournamentsList;
        stats: Stats;
        playerLevel: PlayerLevel;
        playerLevelFail: HttpClientError;
        GameHistoryData: GameHistoryData;
        GameHistoryError: HttpClientError;
        remotegameshistory: RemoteGamesHistory;
        remotegameshistoryError: HttpClientError;
}
const initialState: ProfileState = {
        profile: null,
        tournamentList: null,
        stats: null,
        profileUpdateResponse: null,
        profileUpdateFail: null,
        playerLevel: null,
        playerLevelFail: null,
        GameHistoryData: null,
        GameHistoryError: null,
        remotegameshistory: null,
        remotegameshistoryError: null
}
export function playerReducer(state: ProfileState = initialState, action: playerActions.PlayerActions) {
        switch (action.type) {
                case playerActions.RESET_STATE:
                        return {
                                ...state,
                                profile: null,
                                tournamentList: null,
                                stats: null,
                                profileUpdateResponse: null,
                                profileUpdateFail: null,
                                playerLevel: null,
                                playerLevelFail: null,
                                GameHistoryData: null,
                                GameHistoryError: null,
                                remotegameshistory: null,
                                remotegameshistoryError: null
                        }
                        break;
                case playerActions.PLAYER_GET_PROFILE:
                        return {
                                ...state,
                                profile: null
                        }
                        break;
                case playerActions.PLAYER_GET_PROFILE_SUCCESS:
                        return {
                                ...state,
                                profile: { ...state, ...action.payload }
                        }
                        break;
                case playerActions.PLAYER_GET_PROFILE_FAIL:
                        return {
                                ...state,
                                profile: null
                        }
                        break;
                case playerActions.PLAYER_GET_TOURNAMENTS_LIST:
                        return {
                                ...state,
                                tournamentList: null
                        }
                        break;
                case playerActions.PLAYER_GET_TOURNAMENTS_LIST_SUCCESS:
                        return {
                                ...state,
                                tournamentList: { ...state.tournamentList, ...action.payload }
                        }
                        break;
                case playerActions.PLAYER_GET_TOURNAMENTS_LIST_FAIL:
                        return {
                                ...state,
                                tournamentList: null
                        }
                        break;
                case playerActions.PLAYER_GET_STATS:
                        return {
                                ...state,
                                stats: null
                        }
                        break;
                case playerActions.PLAYER_GET_STATS_SUCCESS:
                        return {
                                ...state,
                                stats: { ...state.stats, ...action.payload }
                        }
                        break;
                case playerActions.PLAYER_GET_STATS_FAIL:
                        return {
                                ...state,
                                stats: null
                        }
                        break;
                case playerActions.PLAYER_GET_PLAYERLEVELS:
                        return {
                                ...state,
                                playerLevel: null,
                                playerLevelFail: null
                        }
                        break;
                case playerActions.PLAYER_GET_PLAYERLEVELS_SUCCESS:
                        return {
                                ...state,
                                playerLevel: { ...state.playerLevel, ...action.payload },
                                playerLevelFail: null
                        }
                        break;
                case playerActions.PLAYER_GET_PLAYERLEVELS_FAIL:
                        return {
                                ...state,
                                playerLevel: null,
                                playerLevelFail: { ...state.playerLevelFail, ...action.payload }
                        }
                        break;
                case playerActions.PLAYER_UPDATE_PROFILE:
                        return {
                                ...state,
                                profileUpdateResponse: null,
                                profileUpdateFail: null
                        }
                        break;
                case playerActions.PLAYER_UPDATE_PROFILE_SUCCESS:
                case playerActions.PLAYER_UPDATE_PASSWORD_SUCCESS:
                        return {
                                ...state,
                                profileUpdateResponse: { ...state.profileUpdateResponse, ...action.payload },
                                profileUpdateFail: null
                        }
                        break;
                case playerActions.PLAYER_UPDATE_PROFILE_FAIL:
                case playerActions.PLAYER_UPDATE_PASSWORD_FAIL:
                        return {
                                ...state,
                                profileUpdateResponse: null,
                                profileUpdateFail: { ...state.profileUpdateFail, ...action.payload }
                        }
                        break;
                case playerActions.PLAYER_GET_GAME_HISTORY:
                        return {
                                ...state,
                                GameHistoryData: null,
                                GameHistoryError: null
                        }
                        break;
                case playerActions.PLAYER_GET_GAME_HISTORY_SUCCESS:
                        return {
                                ...state,
                                GameHistoryData: { ...state.tournamentList, ...action.payload },
                                GameHistoryError: null
                        }
                        break;
                case playerActions.PLAYER_GET_GAME_HISTORY_FAIL:
                        return {
                                ...state,
                                GameHistoryData: null,
                                GameHistoryError: { ...state.GameHistoryError, ...action.payload }
                        }
                        break;
                        case playerActions.PLAYER_GET_REMOTE_GAME_HISTORY:
                                return {
                                        ...state,
                                        remotegameshistory: null,
                                        remotegameshistoryError: null
                                }
                                break;
                        case playerActions.PLAYER_GET_REMOTE_GAME_HISTORY_SUCCESS:
                                return {
                                        ...state,
                                        remotegameshistory: { ...state.remotegameshistory, ...action.payload },
                                        remotegameshistoryError: null
                                }
                                break;
                        case playerActions.PLAYER_GET_REMOTE_GAME_HISTORY_FAIL:
                                return {
                                        ...state,
                                        remotegameshistory: null,
                                        remotegameshistoryError: { ...state.remotegameshistoryError, ...action.payload },
                                }
                                break;
                default:
                        return state;
                        break;
        }
}