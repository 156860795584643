import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Countries } from '../../modules/login/countries';
import { Login } from '../../modules/login/login';
import { PlayerService } from '../player/player.service';
import { Store } from '@ngrx/store';
import * as appState from '../../appstates/appState';
import { PlayerLoggedIn, SportsRemoteLogin } from '../../appstates/loginstates/loginActions';
import { PlayerGetProfile } from '../../appstates/playerstates/playerActions';
import { Observable } from 'rxjs';
import { AllGames } from '../../modules/slotsmodel/allgames';
import { AllGameLunch } from '../../modules/slotsmodel/allgamelunch';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private _AllGames = 'assets/MrSlottyGames_trophy.json';
  constructor(private httpClient: HttpClient,
    private playerService: PlayerService,
    private store: Store<appState.AppState>) { }


  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*',
      'siteid': environment.skinId
    })
  };

  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin':'*',
    'siteid': environment.skinId
  });

  onPlayerLoggedIn(value: boolean) {
    this.store.dispatch(new PlayerLoggedIn({ loggedIn: value }));
    //this.store.dispatch(new PlayerGetProfile());
  }
  onGetPlayerProfile(){
    this.store.dispatch(new PlayerGetProfile());
  }
  onGetRemoteLogin(payload){
    this.store.dispatch(new SportsRemoteLogin({operatorId:environment.skinId,token:'No',loginName:payload.login}));
  }
  onGenerateCaptchaStart():Observable<Blob>{
    return this.httpClient.post(`${environment.appApi.baseUrl}${environment.appApi.generateCaptcha}`,
     {},{headers: this.headers, responseType:'blob'});
  }
  onLogin(postdata) {
    return this.httpClient.post<Login>(`${environment.appApi.baseUrl}${environment.appApi.login}`, postdata, this.httpOptions);
  }
  onSportRemoteLogin(postdata) {
    return this.httpClient.post<Login>(`${environment.appApi.baseUrl}${environment.appApi.remotelogin}`, postdata, this.httpOptions);
  }
  onLogOut() {
    console.log('sdjflkjas')
    return this.httpClient.post(`${environment.appApi.baseUrl}${environment.appApi.logout}`, {}, this.playerService.httpOptions());
  }
  onRegister(postdata) {
    return this.httpClient.post<Login>(`${environment.appApi.baseUrl}${environment.appApi.register}`, postdata, this.httpOptions);
  }
  onGetRegisterCountry() {
    return this.httpClient.post<Countries>(`${environment.appApi.baseUrl}${environment.appApi.getCountries}`, {}, this.httpOptions);
  }
  onForgotPassword(postdata) {
    return this.httpClient.post(`${environment.appApi.baseUrl}${environment.appApi.fotgotPassword}`, postdata, this.httpOptions);
  }

  onRegisterGenarateOTPStart(postdata) {
    return this.httpClient.post(`${environment.appApi.baseUrl}${environment.appApi.generateOTP}`, postdata, this.httpOptions);
  }
  onContactUsSendStats(postdata){
    return this.httpClient.post(`${environment.appApi.baseUrl}${environment.appApi.contactus}`, postdata, this.httpOptions);
  }
  AllGamesData(){
    return this.httpClient.get<AllGames>(this._AllGames);
  }
  AllGamesLunch(postdata) {
    if(postdata.gameMode == 'real_play'){
      return this.httpClient.post<AllGameLunch>(`${environment.appApi.baseUrl}${environment.appApi.allGamesUrl}`, postdata, this.playerService.httpOptions());
    }else{
      return this.httpClient.post<AllGameLunch>(`${environment.appApi.baseUrl}${environment.appApi.allGamesfunUrl}`, postdata, this.httpOptions);
    }
  }
}
