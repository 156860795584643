import { Action } from '@ngrx/store';
import { Countries } from '../../modules/login/countries';
import { Login } from '../../modules/login/login';
import { AllGames } from '../../modules/slotsmodel/allgames';
import { AllGameLunch } from '../../modules/slotsmodel/allgamelunch';

export const RESET_STATE = "[login] RESET_STATE";
export const PLAYER_LOGGEDIN = "[login] PLAYER_LOGGEDIN";


export const GENERATE_CAPTCHA_START = "[login] GENERATE_CAPTCHA_START";
export const GENERATE_CAPTCHA_SUCCESS = "[login] GENERATE_CAPTCHA_SUCCESS";
export const GENERATE_CAPTCHA_FAIL = "[login] GENERATE_CAPTCHA_FAIL";

export const LOGIN_START = "[login] LOGIN_START";
export const LOGIN_SUCCESS = "[login] LOGIN_SUCCESS";
export const LOGIN_FAIL = "[login] LOGIN_FAIL";

export const LOGOUT_START = "[login] LOGOUT_START";
export const LOGOUT_SUCCESS = "[login] LOGOUT_SUCCESS";
export const LOGOUT_FAIL = "[login] LOGOUT_FAIL";

export const FORGOTPASSWORD_START = "[login] FORGOTPASSWORD_START";
export const FORGOTPASSWORD_SUCCESS = "[login] FORGOTPASSWORD_SUCCESS";
export const FORGOTPASSWORD_FAIL = "[login] FORGOTPASSWORD_FAIL";

export const REGISTER_START = "[login] REGISTER_START";
export const REGISTER_SUCCESS = "[login] REGISTER_SUCCESS";
export const REGISTER_FAIL = "[login] REGISTER_FAIL";

export const REGISTER_GET_COUNTRY = "[login] REGISTER_GET_COUNTRY";
export const REGISTER_GET_COUNTRY_SUCCESS = "[login] REGISTER_GET_COUNTRY_SUCCESS";
export const REGISTER_GET_COUNTRY_FAIL = "[login] REGISTER_GET_COUNTRY_FAIL";


export const SPORTS_REMOTE_LOGIN = "[login] SPORTS_REMOTE_LOGIN";
export const SPORTS_REMOTE_LOGIN_SUCCESS = "[login] SPORTS_REMOTE_LOGIN_SUCCESS";
export const SPORTS_REMOTE_LOGIN_FAIL = "[login] SPORTS_REMOTE_LOGIN_FAIL";

export const REGISTER_GENERATE_OTP = "[profile] REGISTER_GENERATE_OTP";
export const REGISTER_GENERATE_OTP_SUCCESS = "[profile] REGISTER_GENERATE_OTP_SUCCESS";
export const REGISTER_GENERATE_OTP_FAIL = "[profile] REGISTER_GENERATE_OTP_FAIL";


export const ALL_GAMES_START = "[login] ALL_GAMES_START";
export const ALL_GAMES_SUCCESS = "[login] ALL_GAMES_SUCCESS";
export const ALL_GAMES_FAIL = "[login] ALL_GAMES_FAIL";

export const ALL_GAMES_LUNCH_START = "[login] ALL_GAMES_LUNCH_START";
export const ALL_GAMES_LUNCH_SUCCESS = "[login] ALL_GAMES_LUNCH_SUCCESS";
export const ALL_GAMES_LUNCH_FAIL = "[login] ALL_GAMES_LUNCH_FAIL";

export class ResetState implements Action {
    readonly type = RESET_STATE;
    constructor() { }
}
export class PlayerLoggedIn implements Action {
    readonly type = PLAYER_LOGGEDIN;
    constructor(public payload: { loggedIn: boolean }) { }
}



export class GenerateCaptchaStart implements Action{
    readonly type = GENERATE_CAPTCHA_START;
    constructor(){ }
}
export class GenerateCaptchaSuccess implements Action{
    readonly type = GENERATE_CAPTCHA_SUCCESS;
    constructor(public payload:Blob){}
}
export class GenerateCaptchaFail implements Action{
    readonly type = GENERATE_CAPTCHA_FAIL;
    constructor(public payload:Object){}
}


export class LoginStart implements Action{
    readonly type = LOGIN_START;
    constructor(public payload:Object){ }
}
export class LoginSuccess implements Action{
    readonly type = LOGIN_SUCCESS;
    constructor(public payload:Login){}
}
export class LoginFail implements Action{
    readonly type = LOGIN_FAIL;
    constructor(public payload:Object){}
}

export class LogoutStart implements Action{
    readonly type = LOGOUT_START;
    constructor(){ }
}
export class LogoutSuccess implements Action{
    readonly type = LOGOUT_SUCCESS;
    constructor(public payload:Object){}
}
export class LogoutFail implements Action{
    readonly type = LOGOUT_FAIL;
    constructor(public payload:Object){}
}

export class ForgotPasswordStart implements Action{
    readonly type = FORGOTPASSWORD_START;
    constructor(public payload:Object){ }
}
export class ForgotPasswordSuccess implements Action{
    readonly type = FORGOTPASSWORD_SUCCESS;
    constructor(public payload:Object){}
}
export class ForgotPasswordFail implements Action{
    readonly type = FORGOTPASSWORD_FAIL;
    constructor(public payload:Object){}
}

export class RegisterStart implements Action{
    readonly type = REGISTER_START;
    constructor(public payload:Object){ }
}
export class RegisterSuccess implements Action{
    readonly type = REGISTER_SUCCESS;
    constructor(public payload:Login){}
}
export class RegisterFail implements Action{
    readonly type = REGISTER_FAIL;
    constructor(public payload:Object){}
}

export class RegisterGetCountry implements Action{
    readonly type = REGISTER_GET_COUNTRY;
    constructor(){}
}

export class RegisterGetCountrySuccess implements Action{
    readonly type = REGISTER_GET_COUNTRY_SUCCESS;
    constructor(public payload:Countries){}
}
export class RegisterGetCountryFail implements Action{
    readonly type = REGISTER_GET_COUNTRY_FAIL;
    constructor(public payload:Object){}
}

export class RegisterGenarateOTP implements Action{
    readonly type = REGISTER_GENERATE_OTP;
    constructor(public payload:Object){ }
}
export class RegisterGenarateOTPSuccess implements Action{
    readonly type = REGISTER_GENERATE_OTP_SUCCESS;
    constructor(public payload:Object){ }
}
export class RegisterGenarateOTPFail implements Action{
    readonly type = REGISTER_GENERATE_OTP_FAIL;
    constructor(public payload:Object){ }
}


export class SportsRemoteLogin implements Action {
    readonly type = SPORTS_REMOTE_LOGIN;
    constructor(public payload: { operatorId: String, token: String, loginName: String }) { }
}
export class SportsRemoteLoginSuccess implements Action {
    readonly type = SPORTS_REMOTE_LOGIN_SUCCESS;
    constructor(public payload: Object) { }
}
export class SportsRemoteLoginFail implements Action {
    readonly type = SPORTS_REMOTE_LOGIN_FAIL;
    constructor(public payload: Object) { }
}

export class allGamesStart implements Action{
    readonly type = ALL_GAMES_START;
    constructor(){
     }
}
export class allGamesSuccess implements Action{
    readonly type = ALL_GAMES_SUCCESS;
    constructor(public payload:AllGames){ }
}
export class allGamesFail implements Action{
    readonly type = ALL_GAMES_FAIL;
    constructor(public payload:Object){ }
}
export class allGamesLunchStart implements Action{
    readonly type = ALL_GAMES_LUNCH_START;
    constructor(public payload:Object){
     }
}
export class allGamesLunchSuccess implements Action{
    readonly type = ALL_GAMES_LUNCH_SUCCESS;
    constructor(public payload:AllGameLunch){ }
}
export class allGamesLunchFail implements Action{
    readonly type = ALL_GAMES_LUNCH_FAIL;
    constructor(public payload:Object){ }
}

export type LoginActions = ResetState | PlayerLoggedIn |
    LoginStart | LoginSuccess | LoginFail |
    LogoutStart | LogoutSuccess | LogoutFail |
    RegisterStart | RegisterSuccess | RegisterFail |
    ForgotPasswordStart | ForgotPasswordSuccess | ForgotPasswordFail |
    RegisterGetCountry | RegisterGetCountrySuccess | RegisterGetCountryFail |
    SportsRemoteLogin | SportsRemoteLoginSuccess | SportsRemoteLoginFail|
    GenerateCaptchaStart|GenerateCaptchaSuccess|GenerateCaptchaFail|
RegisterGenarateOTP|RegisterGenarateOTPSuccess|RegisterGenarateOTPFail
|allGamesStart|allGamesSuccess|allGamesFail|allGamesLunchStart|allGamesLunchSuccess|allGamesLunchFail;