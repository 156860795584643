import { Component, OnInit, OnDestroy, ViewChild, ComponentFactoryResolver } from '@angular/core';
import { Store } from '@ngrx/store';
import * as appState from 'src/app/source/appstates/appState';
import { Subscription, Subscriber } from 'rxjs';
import { ProfileState } from 'src/app/source/appstates/playerstates/playerState';
import * as playerActions from 'src/app/source/appstates/playerstates/playerActions';
import { Profile } from 'src/app/source/modules/player/profile';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PlaceholderDirective } from '../../PlaceholderDirective';
import { UpdatePorfileComponent } from '../update-porfile/update-porfile.component';
import { AccountDetailsComponent } from '../account-details/account-details.component';

import { Ticket, WalletInfo } from 'src/app/source/modules/cashier/balance';
import { CashierState } from 'src/app/source/appstates/cashierstates/cashierState';
import { UploadServiceService } from 'src/app/source/services/uploadDocuments/upload-service.service';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { UploadFileResponse } from 'src/app/source/modules/player/uploadfileresponse';
import { GetDocumetsResponse } from 'src/app/source/modules/player/getDocument';
import { stringify } from 'querystring';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit, OnDestroy {
  @ViewChild(PlaceholderDirective, { static: false }) alertHost: PlaceholderDirective;
  tickets = ['1', '2']
  SlideOptions = {
    loop: false,
    navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
    dots: false, nav: true, autoplay: false, margin: 0, autoplayTimeout: 6000, smartSpeed: 1000,
    responsive: {
      0: {
        items: 2
      },
      500: {
        items: 3
      },
      768: {
        items: 5
      },
      992: {
        items: 6
      },
      1200: {
        items: 7
      },
      1920: {
        items: 7
      }
    }
  };
  doctype: any;
  pandoctype: boolean = false;
  idproof: boolean = false;
  adressproof: boolean = false;
  UploadFileResponse: UploadFileResponse;
  GetDocumetsResponse: GetDocumetsResponse;
  fileToUpload: File = null;
  form: FormGroup;
  updatePassword: FormGroup;
  uploadFile: FormGroup;
  imagepath: File[];
  private storeSub: Subscription;
  private storeCashierSub: Subscription;
  private closeSub: Subscription;
  profile: Profile;
  PlayerLevelResponse: PlayerLevelResponse[];
  edit: boolean = false;
  editPassword: boolean = false;
  documentsstatus: any;

  pancardstatus: any;
  idproofstatus: any;
  adressproofstatus: any;
  walleteInfo: WalletInfo[];
  tikets: Ticket[];
  percentDone: number;
  uploadSuccess: boolean;
  errormessage: boolean;

  constructor(private formBuilder: FormBuilder, private store: Store<appState.AppState>,
    private router: Router,
    private componentFactoryResolver: ComponentFactoryResolver,
    private UploadService: UploadServiceService) {
      window.scroll({ 
        top: 0, 
        left: 0, 
        behavior: 'smooth' 
 });

    this.getDocuments();
  }

  ngOnInit() {

    this.form = new FormGroup({
      "nickname": new FormControl('', Validators.required),
      "email": new FormControl(null, [Validators.required, Validators.email]),
      "firstName": new FormControl('', Validators.required),
      "lastName": new FormControl('', Validators.required),
      "address": new FormGroup({
        "city": new FormControl('', Validators.required),
        "phone": new FormControl('', Validators.required),
        "country": new FormControl('', Validators.required),
      }),
    })

    this.uploadFile = new FormGroup({
      file: new FormControl(null),
      docType: new FormControl(1),
    })

    this.updatePassword = new FormGroup({
      "oldPassword": new FormControl('', Validators.required),
      "newPassword": new FormControl('', Validators.required)
    })

    this.storeSub = this.store.select("playerState").subscribe(
      (playerState: ProfileState) => {


        if (playerState.profile) {
          this.profile = playerState.profile;
          if (this.profile.nickname) {
            (<FormControl>this.form.get('nickname')).setValue(this.profile.nickname);
          }
          if (this.profile.email) {
            (<FormControl>this.form.get('email')).setValue(this.profile.email);
          }
          if (this.profile.firstName) {
            (<FormControl>this.form.get('firstName')).setValue(this.profile.firstName);
          }
          if (this.profile.lastName) {
            (<FormControl>this.form.get('lastName')).setValue(this.profile.lastName);
          }
          if (this.profile.address) {
            if (this.profile.address.city) {
              (<FormControl>this.form.get('address')).get("city").setValue(this.profile.address.city);
            }
            if (this.profile.address.phone) {
              (<FormControl>this.form.get('address')).get("phone").setValue(this.profile.address.phone);
            }
            if (this.profile.address.country) {
              (<FormControl>this.form.get('address')).get("country").setValue(this.profile.address.country);
            }
          }
        }
        if (playerState.playerLevel) {
          this.PlayerLevelResponse = playerState.playerLevel.playerLevelResponses;
        }

      }
    )

    this.storeCashierSub = this.store.select("cashierState").subscribe(
      (cashierState: CashierState) => {
        if (cashierState) {
          if (cashierState.balance) {
            if (cashierState.balance.values) {
              this.walleteInfo = cashierState.balance.values;
            }
          }

        }
      }
    )

    this.store.dispatch(new playerActions.PlayerGetProfile());
    this.store.dispatch(new playerActions.PlayerGetPlayerLevels());
  };


  ngOnDestroy() {
    if (this.storeSub) {
      this.storeSub.unsubscribe();
    }
    if (this.storeCashierSub) {
      this.storeCashierSub.unsubscribe();
    }
    if (this.closeSub) {
      this.closeSub.unsubscribe();
    }
    if (this.profile) {
      this.profile = null;
    }
  };
  showUpdateProfile(value: string) {
    const alertCmpFactory = this.componentFactoryResolver.resolveComponentFactory(
      UpdatePorfileComponent
    );

    const hostViewContainerRef = this.alertHost.viewContainerRef;
    hostViewContainerRef.clear();
    const componentRef = hostViewContainerRef.createComponent(alertCmpFactory);

    componentRef.instance.formState = value;
    this.closeSub = componentRef.instance.close.subscribe(() => {
      this.closeSub.unsubscribe();
      hostViewContainerRef.clear();
    });

  }
  bankAccountDetails(value: string) {
    const alertCmpFactory = this.componentFactoryResolver.resolveComponentFactory(
      AccountDetailsComponent
    );

    const hostViewContainerRef = this.alertHost.viewContainerRef;
    hostViewContainerRef.clear();
    const componentRef = hostViewContainerRef.createComponent(alertCmpFactory);

    componentRef.instance.formState = value;
    this.closeSub = componentRef.instance.close.subscribe(() => {
      this.closeSub.unsubscribe();
      hostViewContainerRef.clear();
    });

  }

  editProfile() {
    this.edit = this.edit ? false : true;
  }
  changePassword() {
    this.editPassword = this.editPassword ? false : true;
  }
  onFormSubmit() {
    this.store.dispatch(new playerActions.PlayerUpdateProfile(this.form.value));
  }
  onUpdatePasswordSubmit() {
    this.store.dispatch(new playerActions.PlayerUpdatePassword(this.updatePassword.value));
  }


  handleFilePanCard(files: FileList) {
    this.doctype = 3;
    this.fileToUpload = files.item(0);
    this.UploadService.postFile(this.fileToUpload, this.doctype).subscribe(data => this.uploadFileResponsedata(data));
  }

  handleFileIdCard(files: FileList) {
    this.doctype = 1;
    this.fileToUpload = files.item(0);
    this.UploadService.postFile(this.fileToUpload, this.doctype).subscribe(data => this.uploadFileResponsedata(data));
  }

  handleFileAddress(files: FileList) {
    this.doctype = 2;
    this.fileToUpload = files.item(0);
    this.UploadService.postFile(this.fileToUpload, this.doctype).subscribe(data => this.uploadFileResponsedata(data));
  }
  // uploadFileToActivity() {

  // }
  uploadFileResponsedata(data) {
    this.UploadFileResponse = data;
    if (this.UploadFileResponse.success == true) {
      this.getDocuments();
    } else if (this.UploadFileResponse.success == false) {
      this.errormessage = this.UploadFileResponse.success;
    }
  }
  // .subscribe(data => {
  //   alert(Response);
  //   }, error => {
  //     console.log(error);
  //   });

  getDocuments() {
    this.UploadService.getDocumetsfile().subscribe(data => this.getDocumentsResponse(data));
  }

  getDocumentsResponse(data) {
    this.GetDocumetsResponse = data;
    if (this.GetDocumetsResponse.success == true) {
      this.documentsstatus = this.GetDocumetsResponse.documents;
      for (let docdata of this.documentsstatus) {
        if (docdata.docType == 'Pan Card') {
          this.pandoctype = true;
          this.pancardstatus = this.GetDocumetsResponse.documents;
        } else {
        }
      }
      for (let docdata of this.documentsstatus) {
        if (docdata.docType == 'Address Proof') {
          this.adressproof = true;
          this.adressproofstatus = this.GetDocumetsResponse.documents;
        } else {
        }
      }
      for (let docdata of this.documentsstatus) {
        if (docdata.docType == 'Id Proof') {
          this.idproof = true;
          this.idproofstatus = this.GetDocumetsResponse.documents;
        } else {

        }
      }
    } else if (this.GetDocumetsResponse.success == false) {

    }
  }
}
