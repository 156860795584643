import * as loginActions from './loginActions';
import { Countries } from '../../modules/login/countries';
import { Login } from '../../modules/login/login';
import { ForgotPassword } from '../../modules/login/forgotpassword';
import { HttpClientError } from '../../modules/login/httpClientError';
import { PlayerLoggedIn } from '../../modules/login/playerLoggedIn';
import { AllGames } from '../../modules/slotsmodel/allgames';
import { AllGameLunch } from '../../modules/slotsmodel/allgamelunch';

export interface LoginState {
    countries: Countries;
    loginResponse: Login;
    loginErrorResponse: HttpClientError;
    forgotPasswordResponse: ForgotPassword;
    forgotPasswordErrorResponse: HttpClientError;
    playerLoggedIn: PlayerLoggedIn;
    generatedCaptcha: Blob;
    generatedCaptchaError: HttpClientError;
    allgames:AllGames;
    allgamesError:HttpClientError;
    allGamesLunch:AllGameLunch;
    allGamesLunchError:HttpClientError;
}

const initialState: LoginState = {
    loginResponse: null,
    countries: null,
    loginErrorResponse: null,
    forgotPasswordResponse: null,
    forgotPasswordErrorResponse: null,
    playerLoggedIn: null,
    generatedCaptcha: null,
    generatedCaptchaError: null,
    allgames:null,
    allgamesError:null,
    allGamesLunch:null,
    allGamesLunchError:null
}

export function loginReducer(state: LoginState = initialState, action: loginActions.LoginActions) {
    switch (action.type) {
        case loginActions.RESET_STATE:
            return {
                ...state,
                loginResponse: null,
                countries: null,
                loginErrorResponse: null,
                forgotPasswordResponse: null,
                forgotPasswordErrorResponse: null,
                playerLoggedIn: null,
                generatedCaptcha: null,
                generatedCaptchaError: null,
                allgames:null,
                allgamesError:null,
                allGamesLunch:null,
                allGamesLunchError:null
            }
            break;
        case loginActions.GENERATE_CAPTCHA_START:
            return {
                ...state,
                generatedCaptcha: null,
                generatedCaptchaError: null
            }
            break;
        case loginActions.GENERATE_CAPTCHA_SUCCESS:
            return {
                ...state,
                generatedCaptcha: { ...state.generatedCaptcha, ...action.payload },
                generatedCaptchaError: null
            }
            break;
        case loginActions.GENERATE_CAPTCHA_FAIL:
            return {
                ...state,
                generatedCaptcha: null,
                generatedCaptchaError: { ...state.generatedCaptchaError, ...action.payload }
            }
            break;
        case loginActions.PLAYER_LOGGEDIN:
            return {
                ...state,
                playerLoggedIn: { ...state.playerLoggedIn, ...action.payload }
            }
            break;
        case loginActions.LOGIN_START:
        case loginActions.REGISTER_START:
            return {
                ...state,
                loginResponse: null,
                loginErrorResponse: null,
                forgotPasswordResponse: null
            }
            break;
        case loginActions.LOGIN_SUCCESS:
        case loginActions.REGISTER_SUCCESS:
            return {
                ...state,
                loginResponse: { ...state.loginResponse, ...action.payload },
                loginErrorResponse: null
            }
            break;
        case loginActions.LOGIN_FAIL:
        case loginActions.REGISTER_FAIL:
            return {
                ...state,
                loginResponse: null,
                loginErrorResponse: { ...state.loginErrorResponse, ...action.payload },
            }
            break;
        case loginActions.FORGOTPASSWORD_START:
            return {
                ...state,
                forgotPasswordResponse: null,
                forgotPasswordErrorResponse: null
            }
            break;
        case loginActions.FORGOTPASSWORD_SUCCESS:
            return {
                ...state,
                forgotPasswordResponse: { ...state.forgotPasswordResponse, ...action.payload },
                forgotPasswordErrorResponse: null
            }
            break;
        case loginActions.FORGOTPASSWORD_FAIL:
            return {
                ...state,
                forgotPasswordResponse: null,
                forgotPasswordErrorResponse: { ...state.forgotPasswordErrorResponse, ...action.payload }
            }
            break;
        case loginActions.REGISTER_GET_COUNTRY:
            return {
                ...state,
                countries: null
            }
            break;
        case loginActions.REGISTER_GET_COUNTRY_SUCCESS:
            return {
                ...state,
                countries: { ...state.countries, ...action.payload }
            }
            break;
        case loginActions.REGISTER_GET_COUNTRY_FAIL:
            return {
                ...state,
                countries: null
            }
            break;
            case loginActions.ALL_GAMES_START:
                return {
                    ...state,
                    allgames: null,
                    allgamesError:null
                }
                break;
            case loginActions.ALL_GAMES_SUCCESS:
                return {
                    ...state,
                    allgames: { ...state.allgames, ...action.payload },
                    allgamesError:null
                }
                break;
            case loginActions.ALL_GAMES_FAIL:
                return {
                    ...state,
                    allgames: null,
                    allgamesError:{ ...state.allgamesError, ...action.payload }
                }
                break;
            case loginActions.ALL_GAMES_LUNCH_START:
                return {
                    ...state,
                    allGamesLunch: null,
                    allGamesLunchError:null
                }
                break;
            case loginActions.ALL_GAMES_LUNCH_SUCCESS:
                return {
                    ...state,
                    allGamesLunch: { ...state.allGamesLunch, ...action.payload },
                    allGamesLunchError:null
                }
                break;
            case loginActions.ALL_GAMES_LUNCH_FAIL:
                return {
                    ...state,
                    allGamesLunch: null,
                    allGamesLunchError:{ ...state.allGamesLunchError, ...action.payload }
                }
                break;
        default:
            return state;
            break;
    }
}