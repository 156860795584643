import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-howtoplay',
  templateUrl: './howtoplay.component.html',
  styleUrls: ['./howtoplay.component.scss'],
})
export class HowtoplayComponent implements OnInit {

  constructor(private location:Location) {window.scroll(0,0); }

  ngOnInit() {}
  backClicked() {
    this.location.back();
  }
}
