import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as appState from 'src/app/source/appstates/appState';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import * as cashierActions from 'src/app/source/appstates/cashierstates/cashierActions';
import { PlayerGetGameHistory } from 'src/app/source/appstates/playerstates/playerActions';
import * as moment from 'moment';
import { ProfileState } from 'src/app/source/appstates/playerstates/playerState';
@Component({
  selector: 'app-game-history',
  templateUrl: './game-history.component.html',
  styleUrls: ['./game-history.component.scss'],
})
export class GameHistoryComponent implements OnInit {
  private storeSub: Subscription;
  gamehistory: any;
  gameTypes = ["POKER_HOLDEMNOLIMIT_USD,HOLDEM,NOLIMIT", "POKER_HOLDEMLIMIT_USD,HOLDEM,LIMIT", "POKER_OMAHANOLIMIT_USD,OMAHA,NOLIMIT", "POKER_OMAHALIMIT_USD,OMAHA,LIMIT"]
  form: FormGroup;
  forms: FormGroup;
  startDate: Date;
  endDate: Date;
  showDiv: boolean = false;
  steddate: boolean;
  todaydate: any;

  constructor(private store: Store<appState.AppState>) {
  }

  ngOnInit() { 
    this.todaydate = moment(new Date()).format('YYYY-MM-DD'); 
    this.endDate = new Date();
    let today = new Date(this.endDate.getFullYear(), this.endDate.getMonth(), this.endDate.getDate() - 7);
    this.startDate = today;
    this.form = new FormGroup({
      "currency": new FormControl("USD", Validators.required),
      "startDate": new FormControl(null, Validators.required),
      "endDate": new FormControl(null, Validators.required),
      "types": new FormControl(this.gameTypes, Validators.required),
      "limit": new FormControl("100", Validators.required),
      "index": new FormControl("0", Validators.required),
    })
    this.form = new FormGroup({
      "currency": new FormControl("USD", Validators.required),
      "startDate": new FormControl(null, Validators.required),
      "endDate": new FormControl(null, Validators.required),
      "types": new FormControl(this.gameTypes, Validators.required),
      "limit": new FormControl("100", Validators.required),
      "index": new FormControl("0", Validators.required),
    })
    this.form.valueChanges.subscribe(x => {
      let yearsDiff = 0;
      var ToDate = new Date();
      var pastYear = ToDate.getFullYear() - yearsDiff;
      ToDate.setFullYear(pastYear);
      if (new Date(x.startDate).getTime() > ToDate.getTime() || new Date(x.startDate).getTime() > new Date(x.endDate).getTime() || new Date(x.endDate).getTime() > ToDate.getTime()) {
        this.steddate = true; 
      } else {
        this.steddate = false;
      }
    });
    this.storeSub = this.store.select("playerState").subscribe(
      (playerState: ProfileState) => {
        if (playerState.GameHistoryData) {
          if (playerState.GameHistoryData.success == true) {
            this.gamehistory = playerState.GameHistoryData.values;
            console.log(this.gamehistory)
          }
        }
      })
  }
  onFormSubmit() {
    this.forms.value.startDate = moment(this.forms.value.startDate).format('DD-MM-YYYY');
    this.forms.value.endDate = moment(this.forms.value.endDate).format('DD-MM-YYYY');
    this.store.dispatch(new PlayerGetGameHistory(this.form.value));
    this.showDiv = true;
  }

}

