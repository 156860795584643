import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuickloginComponent } from './quicklogin/quicklogin.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home.component';
import { ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { HomeRoutingModule } from './home-routing.module';
import { QuickregisterComponent } from './quickregister/quickregister.component';
import { ProfileComponent } from './player/profile/profile.component';
import { EffectsModule } from '@ngrx/effects';
import { LoginEffects } from 'src/app/source/appstates/loginstates/loginEffects';
import { PlayerEffects } from 'src/app/source/appstates/playerstates/playerEffects';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { CashierComponent } from './cashier/cashier.component';
import { CashierEffects } from 'src/app/source/appstates/cashierstates/cashierEffects';
import { DepositComponent } from './cashier/deposit/deposit.component';
import { DepositResponseComponent} from './cashier/depositresponse/depositresponse.component';
import { MainComponent } from './main/main.component';
import { PlaceholderDirective } from './PlaceholderDirective';
import { UpdatePorfileComponent } from './player/update-porfile/update-porfile.component';
import { AccountDetailsComponent } from './player/account-details/account-details.component';
import { OwlModule } from 'ngx-owl-carousel';
import { WithdrawComponent } from './cashier/withdraw/withdraw.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { TransactionHistoryComponent } from './transaction-history/transaction-history.component';
import {NgbModule, NgbDateAdapter, NgbDateNativeAdapter} from '@ng-bootstrap/ng-bootstrap';
import { GameHistoryComponent } from './game-history/game-history.component';
import { TournamentsListComponent } from './tournaments-list/tournaments-list.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { BankAccountComponent } from './cashier/bank-account/bank-account.component';
import { PromotionsComponent } from './promotions/promotions.component';
import { HowtoplayComponent } from './howtoplay/howtoplay.component';
import { RakestructureComponent } from './aboutus/rakestructure/rakestructure.component';
import { PrivacypolicyComponent } from './aboutus/privacypolicy/privacypolicy.component';
import { TermsandconditionsComponent } from './aboutus/termsandconditions/termsandconditions.component';
import { TaxationComponent } from './aboutus/taxation/taxation.component';
import { EulaComponent } from './aboutus/eula/eula.component';
import { SoftwarecertificationComponent } from './aboutus/softwarecertification/softwarecertification.component';
import { CurrencyExchangeComponent } from './cashier/currency-exchange/currency-exchange.component';
import { CasinoGamesComponent } from './casino-games/casino-games.component';
import { PokerComponent } from './poker/poker.component';
import { SlotsComponent } from './slots/slots.component';
import {TablgamesComponent} from '../../tablgames/tablgames.component';
import {LotteryComponent} from '../home/lottery/lottery.component';
import {SlotsandtablegamesComponent} from './slotsandtablegames/slotsandtablegames.component';
import { CasinoHistoryComponent } from './casino-history/casino-history.component';
import { MybalanceComponent } from 'src/app/skins/mybalance/mybalance.component';
import { ExchangeComponent } from 'src/app/skins/exchange/exchange.component';
import { VirtualGamesComponent } from 'src/app/skins/virtual-games/virtual-games.component';
import { ReplacePipe } from 'src/app/source/replacePipe';


@NgModule({
  declarations: [
    QuickloginComponent,
    AboutusComponent,
    ReplacePipe,
    QuickregisterComponent,
    ForgotPasswordComponent,
    MainComponent,
    HomeComponent,
    HeaderComponent,
    ProfileComponent,
    CashierComponent,
    PokerComponent,
    DepositComponent,
    DepositResponseComponent,
    UpdatePorfileComponent,
    AccountDetailsComponent,
    PlaceholderDirective,
    WithdrawComponent,
    PagenotfoundComponent,
    TransactionHistoryComponent,
    PromotionsComponent,
    HowtoplayComponent,
    GameHistoryComponent,
    TournamentsListComponent,
    ContactUsComponent,
    CasinoGamesComponent,
    BankAccountComponent,
    RakestructureComponent,
    PrivacypolicyComponent,
    TermsandconditionsComponent,
    TaxationComponent,
    EulaComponent,
    SoftwarecertificationComponent,
    CurrencyExchangeComponent,
    SlotsComponent,
    TablgamesComponent,
    LotteryComponent,
    SlotsandtablegamesComponent,
    CasinoHistoryComponent,
    VirtualGamesComponent,
    MybalanceComponent,
    ExchangeComponent
  ],
  imports: [
    IonicModule,
    CommonModule,
    ReactiveFormsModule,
    HomeRoutingModule,
    EffectsModule.forRoot([LoginEffects, PlayerEffects, CashierEffects]),
    OwlModule,
    NgbModule
  ],
  entryComponents:[
    QuickloginComponent,
    QuickregisterComponent,
    ForgotPasswordComponent,
    UpdatePorfileComponent,
    AccountDetailsComponent
  ], 
  providers: [{provide: NgbDateAdapter, useClass: NgbDateNativeAdapter}]
})
export class HomeModule { }
