import {
  Component,
  OnInit,
  OnDestroy
} from '@angular/core';
import { Store } from '@ngrx/store';
import * as appState from 'src/app/source/appstates/appState';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { PlayerGetProfile } from 'src/app/source/appstates/playerstates/playerActions';
declare var $: any;
function showDepositResponse() {
  $("#openedepositresponse").modal("show");
}
function hideDepositResponse() {
  $("#opendepositresponse").modal("hide");
}
@Component({
    selector: 'app-deposit',
    templateUrl: './depositresponse.component.html',
    styleUrls: ['./depositresponse.component.scss'],
})
export class DepositResponseComponent implements OnInit, OnDestroy {
  result: string;
  depositmsg: string;

  constructor(
    private router: Router, private store: Store<appState.AppState>,
    private route: ActivatedRoute) {

  }
  ngOnInit() {
    showDepositResponse();
    this.route.queryParams.subscribe(params => {
      this.result = params.result;
      if (this.result == 'success') {
        this.depositmsg = "success";
      } else {
        this.depositmsg = "error";
      }
    });
    this.store.dispatch(new PlayerGetProfile());
  }

  navigate() {
    if (this.result == 'success') {
      hideDepositResponse();
      this.router.navigate(["/profile"]);
    } else {
      hideDepositResponse();
      this.router.navigate(["/deposit"]);
    }
  }
  ngOnDestroy() { }
}
