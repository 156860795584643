import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-softwarecertification',
  templateUrl: './softwarecertification.component.html',
  styleUrls: ['./softwarecertification.component.scss'],
})
export class SoftwarecertificationComponent implements OnInit {

  constructor() {window.scroll(0,0); }

  ngOnInit() {}

}
