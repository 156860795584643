import { Component, OnInit } from '@angular/core';

import { FormGroup, FormControl, Validators } from '@angular/forms';
@Component({
  selector: 'app-currency-exchange',
  templateUrl: './currency-exchange.component.html',
  styleUrls: ['./currency-exchange.component.scss'],
})
export class CurrencyExchangeComponent implements OnInit {
  inpValue_1:number;
  inpValue_2:number;
  inpValue_3:number;
  inpValue_4:number;
  form : FormGroup;
  constructor() { }

  ngOnInit() {
  }

  currencyChange_1(inpValue: any): void {  
    this.inpValue_1 = inpValue;
  }
  currencyChange_2(inpValue: any): void {  
    this.inpValue_2 = inpValue;
  }
  currencyChange_3(inpValue: any): void {  
    this.inpValue_3 = inpValue;
  }
  currencyChange_4(inpValue: any): void {  
    this.inpValue_4 = inpValue;
  } 

}