import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { PlayerService } from 'src/app/source/services/player/player.service';
import { SlotsService } from 'src/app/source/services/slotsservice/slots.service';

@Component({
  selector: 'app-virtual-games',
  templateUrl: './virtual-games.component.html',
  styleUrls: ['./virtual-games.component.scss'],
})
export class VirtualGamesComponent implements OnInit {
  virtualGames: any;
  allGames: any;
  gameId: any;
  isMobile: boolean = false;
  platforms: any;
  isMobileRNot: any;
  urlSafe: SafeResourceUrl;

  constructor(private serv: SlotsService, private playSer: PlayerService, public sanitizer: DomSanitizer,) { }

  ngOnInit() {
    this.serv.getVirtula().subscribe(data => this.getGemaesHandler(data))

  }
  getGemaesHandler(data) {
    console.log(data)
    console.log(data.data)
    this.virtualGames = data.data;

    for (let i = 0; i < data.data.length; i++) {
      this.allGames = data.data[i];
      this.gameId = this.allGames.gameId
      // this.platforms = this.allGames.platforms;
      // for( let j =  0; j < this.platforms.length; j++ ){
      //    this.isMobileRNot = ( this.platforms[j])
      // }
      console.log(this.platforms)
      console.log(this.gameId)
    }




  }
  tableFun(gameId: any) {
    // alert("test")
    console.log(gameId)
    this.playSer.getVirtuals(gameId).subscribe(res => this.getResponse(res))
  }
  getResponse(res) {
    console.log(res.launch)
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(res.launch);
    window.scroll({
      top:350,
      left:0,
      behavior:'smooth'
    });
  }
  urlSafeBtn() {
    this.urlSafe = false;
    window.scroll(0,0)
  }

}
