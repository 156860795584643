import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import * as appState from 'src/app/source/appstates/appState';
import * as loginActions from 'src/app/source/appstates/loginstates/loginActions';
import { Subscription, interval, timer } from 'rxjs';
import { LoginState } from 'src/app/source/appstates/loginstates/loginState';
import { Countries, Country } from 'src/app/source/modules/login/countries';
import { map, take } from 'rxjs/internal/operators';
import { LoginService } from 'src/app/source/services/login/login.service';
import { data } from 'jquery';

@Component({
  selector: 'app-quickregister',
  templateUrl: './quickregister.component.html',
  styleUrls: ['./quickregister.component.scss'],
})
export class QuickregisterComponent implements OnInit, OnDestroy {
  showPleaseLogin: boolean = false;
  isdisabled: boolean = true;

  show: boolean = true;
  eye: boolean = true;
  registerErrorMsg: any;
  registerResPop: boolean = false; 
  campaignCode: any;
  type: any;

  showPassword() {
    this.show = !this.show;
    this.eye = !this.eye;
  }

  changetype: boolean = true;
  visable: boolean = true;

  form: FormGroup;
  private storeSub: Subscription;
  countries: Countries;
  country: Country[];
  errorMessage: string;
  //name = new FormControl('');

  viewpass() {
    this.visable = !this.visable;
    this.changetype = !this.changetype;
  }

  constructor(private store: Store<appState.AppState>, private loginSer:LoginService) { }

  ngOnInit() {
    this.form = new FormGroup({
 
      "register": new FormGroup({
        "login": new FormControl(null, [Validators.required, Validators.minLength(4), Validators.pattern('[a-zA-Z0-9]*')]),
        // "email": new FormControl(null, [Validators.required, Validators.email, Validators.pattern("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$")]),
        "email": new FormControl(null, [Validators.required, Validators.email, Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
      ]),
        // "nickname": new FormControl(null, [Validators.required, Validators.minLength(4), Validators.pattern('[a-zA-Z0-9]*')]),
        "nickname": new FormControl(''),
        "firstName": new FormControl(''),
        // "lastName": new FormControl('elite1'),
        "campaignCode": new FormControl(this.campaignCode),
        "referralType": new FormControl(this.type),
        // "password": new FormControl(null, [Validators.required, Validators.minLength(6), Validators.maxLength(15), Validators.pattern('[a-zA-Z0-9@#$%^&*()-_=+]*')]),
        "password": new FormControl(null,[ Validators.required,Validators.minLength(6),Validators.maxLength(15),Validators.pattern(/^\S*$/)]),
        "address": new FormGroup({
          "phone": new FormControl(null, [Validators.required, Validators.pattern('[4-9]\\d{9}')]),
          // "country": new FormControl(364),
        })
      }),
      "acceptTerms": new FormControl(null, [Validators.required, Validators.requiredTrue])
    });



    
    this.storeSub = this.store.select("loginState").subscribe(
      (loginState: LoginState) => {
        console.log(loginState)
        if (loginState.countries) {
          this.countries = loginState.countries;
          this.country = this.countries.values;
          if (loginState.loginResponse) {
            if (loginState.loginResponse.success === false) {
              this.errorMessage = loginState.loginResponse.description;
              console.log("success_false",this.errorMessage)
            } else if ( loginState.loginResponse.success === true ){
              this.errorMessage = "Registered Successfully"
              console.log("success_True", this.errorMessage)
            }
          }
          if (loginState.loginErrorResponse) {
            this.errorMessage = loginState.loginErrorResponse.message;
            console.log(this.errorMessage)
          }
        }
      }
    )

    this.store.dispatch(new loginActions.RegisterGetCountry());

  }

  generateOTP($event) {
    $event.target.disabled = true;
    let numbers = interval(1000);
    let takeFourNumbers = numbers.pipe(take(60));
    takeFourNumbers.subscribe(x => {
      $event.target.innerHTML = x;
      if (x == 59) {
        $event.target.disabled = false;
        $event.target.innerHTML = "Resend OTP";
      }
    });

    console.log(this.form.get('address.phone').value);
    this.store.dispatch(new loginActions.RegisterGenarateOTP({ language: 'en', mobile: this.form.get('address.phone').value }));
  }
  onFormSubmit() {
    // debugger
    console.log(this.form.value);
    this.form.value.register.nickname = this.form.value.register.login;
    this.form.value.register.firstName = this.form.value.register.login;
    this.form.value.register.lastName = this.form.value.register.login;
    // this.loginSer.onRegister(this.form.value.register).subscribe(data => this.registerDataRes(data) )
    this.store.dispatch(new loginActions.RegisterStart(this.form.value.register));
    // alert("Register Successfully")
  }
  registerDataRes(data){
    // alert("kaalk")
    console.log(data)
    if(data.success == false){
      console.log("false")
      console.log(data.description)
       this.registerErrorMsg = data.description;
       console.log(this.registerErrorMsg)
      this.registerResPop = true;
     
    }
    if(data.success == true){
      this.registerResPop = true;
      this.registerErrorMsg = "Registered Successfully";
    }
  }

  closeLogPop(){
    this.registerResPop  = false;
  }
  ngOnDestroy() {
    if (this.storeSub) {
      this.storeSub.unsubscribe();
    }
  }
  closePop(){
    this.errorMessage = null;
  }

  username(event){
    var R
    R = event.charCode;
    console.log(R)
    
    return((R>64 && R<91) || (R>96 && R<123) || (R>48 && R<57) )   
  }
  userchar(event){
    var R
    R = event.charCode;
    console.log(R)
    
    return((R > 64 && R < 91) || (R > 96 && R< 123) || R== 8 || R >= 33 && R<= 47 || R >= 60 && R <= 64 || (R >= 48 && R <= 58) || R>= 91 && R<= 96 || R >= 123 && R<= 126);   
  }
  emailValids(event) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k >= 33 && k <= 47 || k >= 60 && k <= 64 || (k >= 48 && k <= 57) || k >= 91 && k <= 96 || k >= 123 && k <= 126);
  }
}