import { Component, OnInit, OnDestroy, OnChanges, AfterViewChecked, ComponentFactoryResolver, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import * as appState from 'src/app/source/appstates/appState';
import * as cashierActions from 'src/app/source/appstates/cashierstates/cashierActions';
import { CashierState } from 'src/app/source/appstates/cashierstates/cashierState';
import { Subscription } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { PlaceholderDirective } from '../../PlaceholderDirective';
import { DepositSystems, DepositSystemsClass, TelecomProvider } from 'src/app/source/modules/cashier/depositSystems';
import { ProfileState } from 'src/app/source/appstates/playerstates/playerState';
import { Profile } from 'src/app/source/modules/player/profile';
import * as playerActions from 'src/app/source/appstates/playerstates/playerActions';
declare var $: any;

import { Location } from '@angular/common';

@Component({
  selector: 'app-deposit',
  templateUrl: './deposit.component.html',
  styleUrls: ['./deposit.component.scss'],
})
export class DepositComponent implements OnInit, OnDestroy {
  @ViewChild(PlaceholderDirective, { static: false }) alertHost: PlaceholderDirective;
  private profilestoreSub:Subscription;
  private storeSub: Subscription;
  profile:Profile;
  form: FormGroup;
  formResponseMessage: string;
  depositSystems: DepositSystemsClass;
  telecomProvider: TelecomProvider[];
  hideFormOnCouldAfrica: boolean = false;
  showtransactionId: boolean = false;
  chanelname: string = "";
  transactionId: string = "";
  phonenumber:string="";

  SlideOptions = {
    loop: false,
    navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
    dots: false, nav: true, autoplay: false, margin: 0, autoplayTimeout: 6000, smartSpeed: 1000,
    responsive: {
      0: {
        items: 2
      },
      500: {
        items: 3
      },
      768: {
        items: 5
      },
      992: {
        items: 6
      },
      1200: {
        items: 7
      },
      1920: {
        items: 7
      }
    }
  };

  constructor(private store: Store<appState.AppState>, private location:Location) { }

  ngOnInit() {
    
    this.store.dispatch(new cashierActions.ResetState());
    this.form = new FormGroup({});
    this.store.dispatch(new playerActions.PlayerGetProfile());
    this.profilestoreSub = this.store.select("playerState").subscribe(
      (playerState: ProfileState) => {
        if (playerState.profile) {
          this.profile = playerState.profile;
          this.phonenumber = this.profile.address.phone;
         // alert(this.phonenumber + " "+ "mobilenumber")
        }

      }
    )
    this.storeSub = this.store.select("cashierState").subscribe(
      (cashierState: CashierState) => {

        if (cashierState.depositeSystem) {

          this.depositSystems = cashierState.depositeSystem.depositSystems;

          if (cashierState.depositeSystem.depositSystems) {
            if (cashierState.depositeSystem.depositSystems.additional) {
              this.telecomProvider = cashierState.depositeSystem.depositSystems.additional;
            }
          }

          if (this.depositSystems) {
            for (let frm of this.depositSystems.params) {
              if (frm.required) {
                this.form.addControl(frm.name, new FormControl('', Validators.required));
              } else {
                this.form.addControl(frm.name, new FormControl(''));
              }
            }
          }

          if (cashierState.depositeSystem.description) {
            this.formResponseMessage = cashierState.depositeSystem.description;
          }

        } else {
          this.depositSystems = null;
          this.telecomProvider = null;
          this.showtransactionId = false;
        }

        if (cashierState.depositResponse) {
          if (cashierState.depositResponse.success === true) {
            if (cashierState.depositResponse.result) {
              if (cashierState.depositResponse.result.form) {
                this.createFormToSubmitDeposit(cashierState.depositResponse.result.form);
                this.showtransactionId = false;
              }
              if (cashierState.depositResponse.result.redirectUrl) {
                let depositPaymentGateWay = window;
                depositPaymentGateWay.location.href = cashierState.depositResponse.result.redirectUrl;
              }
              if (cashierState.depositResponse.result.transactionId) {
                //TODO show transaction ID
                this.transactionId = cashierState.depositResponse.result.transactionId;
                this.showtransactionId = true;
              }
            }
          }
          if (cashierState.depositResponse.description) {
            this.formResponseMessage = cashierState.depositResponse.description;
          }
        }

        if (cashierState.depositFail) {
          this.formResponseMessage = cashierState.depositFail.message;
        }

        if (cashierState.depositeSystemResponseFail) {
          this.formResponseMessage = cashierState.depositeSystemResponseFail.message;
        }

      }
    )
    this.storeSub = this.store.select("cashierState").subscribe(
      (cashierState: CashierState) => {
        if (cashierState.depositResponse) {
         // this.cashier = cashierState.balance;
          if(cashierState.depositResponse.success==true){

           // alert(cashierState.depositResponse.description);
      }else if(cashierState.depositResponse.success==false){
//alert(cashierState.depositResponse.description);
        } 
        }
      }
    )
    this.store.dispatch(new cashierActions.CashierGetDepositSystems({ id: null }));
  }

  createFormToSubmitDeposit(form) {
    let frm = document.createElement("form");

    for (let params in form.params) {
      let element = document.createElement("input");
      element.value = form.params[params];
      element.name = params;
      frm.appendChild(element);
    }

    frm.method = form.method;
    frm.action = form.url;
    document.body.appendChild(frm);
    frm.submit();
  }

  onPaymentSelection(paymentName, selected) {
    this.form.reset();
    this.showtransactionId = false;
    if (paymentName == 'CLOUD_AFRICA') {
      this.hideFormOnCouldAfrica = true;
    } else {
      this.hideFormOnCouldAfrica = false;
    }
    this.store.dispatch(new cashierActions.CashierGetDepositSystems({ id: selected }));
  }
  onClickProviderDeposit(provider) {
    this.chanelname = provider;
    this.hideFormOnCouldAfrica = false;
  }
  onFormSubmit() {
    console.log(this.form.value);
    this.form.value.currency = "CHP"
    this.store.dispatch(new cashierActions.CashierDepositStart(this.form.value));
  }

  ngOnDestroy() {
    if (this.storeSub) {
      this.storeSub.unsubscribe();
    }
  }
  backClicked() {
    this.location.back();
  }

}
