import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import * as appState from 'src/app/source/appstates/appState';
import * as cashierActions from 'src/app/source/appstates/cashierstates/cashierActions';
import { Subscription } from 'rxjs';
import { CashierState } from 'src/app/source/appstates/cashierstates/cashierState';
import { Balance, WalletInfo } from 'src/app/source/modules/cashier/balance';
@Component({
  selector: 'app-cashier',
  templateUrl: './cashier.component.html',
  styleUrls: ['./cashier.component.scss'],
})
export class CashierComponent implements OnInit, OnDestroy {
  @Input() showPlayMoney:Boolean = true;
  private storeSub: Subscription;
  //cashier:Balance;
  walleteInfo:WalletInfo[];
  preferredBalance:WalletInfo;
  constructor(private store: Store<appState.AppState>) { }

  ngOnInit() {
    this.storeSub = this.store.select("cashierState").subscribe(
      (cashierState: CashierState) => {
        if (cashierState.balance) {
         // this.cashier = cashierState.balance;
          this.walleteInfo = cashierState.balance.values;
          for (let wallete of this.walleteInfo){
            if(wallete.preferred === true){
              this.preferredBalance = wallete;
              break;
            }
          }
        }
      }
    )
    this.store.dispatch(new cashierActions.CashierGetBalanceStart());
  }
  resetPlayMoney(){
    this.store.dispatch(new cashierActions.CashierResetPlayMoneyStart());
  }
  ngOnDestroy() {
    if (this.storeSub) {
      this.storeSub.unsubscribe();
    }
  }

}
