import { Action } from '@ngrx/store';
import { SportBookGetSportsResponse } from '../../modules/sportsbook/sportsbookSports';
import { HttpClientError } from '../../modules/login/httpClientError';
import { LeaguesListResponse } from '../../modules/sportsbook/leagueList';
import { LeaguesAndMatchesList } from '../../modules/sportsbook/leadguesandmatchesList';
import { LiveMatch } from '../../modules/sportsbook/livematch/livematch';
import { IGenerateBetSlip, BetSlip } from '../../modules/sportsbook/generateBetSlip';
import { SendBetsSlipResponse } from '../../modules/sportsbook/sendBetsSlipResponse';

export const SPORTSBOOK_GET_SPORTS = "[sportsbook] SPORTSBOOK_GET_SPORTS";
export const SPORTSBOOK_GET_SPORTS_SUCCESS = "[sportsbook] SPORTSBOOK_GET_SPORTS_SUCCESS";
export const SPORTSBOOK_GET_SPORTS_FAIL = "[sportsbook] SPORTSBOOK_GET_SPORTS_FAIL";

export const SPORTSBOOK_GET_LEAGUES = "[sportsbook] SPORTSBOOK_GET_LEAGUES";
export const SPORTSBOOK_GET_LEAGUES_SUCCESS = "[sportsbook] SPORTSBOOK_GET_LEAGUES_SUCCESS";
export const SPORTSBOOK_GET_LEAGUES_FAIL = "[sportsbook] SPORTSBOOK_GET_LEAGUES_FAIL";

export const SPORTSBOOK_GET_LEAGUESANDMATCHES = "[sportsbook] SPORTSBOOK_GET_LEAGUESANDMATCHES";
export const SPORTSBOOK_GET_LEAGUESANDMATCHES_SUCCESS = "[sportsbook] SPORTSBOOK_GET_LEAGUESANDMATCHES_SUCCESS";
export const SPORTSBOOK_GET_LEAGUESANDMATCHES_FAIL = "[sportsbook] SPORTSBOOK_GET_LEAGUESANDMATCHES_FAIL";

export const INITNETWORK = "[sportsbook] INITNETWORK";
export const NETWORK_CONNECTED = "[sportsbook] NETWORK_CONNECTED";
export const NETWORK_CONNECT_ERROR = "[sportsbook] NETWORK_CONNECT_ERROR";
export const NETWORK_RECEIVE_DATA = "[sportsbook] NETWORK_RECEIVE_DATA";
export const NETWORK_SEND = "[sportsbook] NETWORK_SEND";
export const NETWORK_CLOSE = "[sportsbook] NETWORK_CLOSE";



export const LIVE_GETSPORTS = "[sportsbook] LIVE_GETSPORTS";

export const GENERATEBETSLIP = "[sportsbook] GENERATEBETSLIP";
export const BETSLIP = "[sportsbook] BETSLIP";

export const SPORTSBOOK_SEND_BETS = "[sportsbook] SPORTSBOOK_SEND_BETS";
export const SPORTSBOOK_SEND_BETS_SUCCESS = "[sportsbook] SPORTSBOOK_SEND_BETS_SUCCESS";
export const SPORTSBOOK_SEND_BETS_FAIL = "[sportsbook] SPORTSBOOK_SEND_BETS_FAIL";


export const SPORTSBOOK_GET_BONUS_CONFIG = "[sportsbook] SPORTSBOOK_GET_BONUS_CONFIG";
export const SPORTSBOOK_GET_BONUS_CONFIG_SUCCESS = "[sportsbook] SPORTSBOOK_GET_BONUS_CONFIG_SUCCESS";
export const SPORTSBOOK_GET_BONUS_CONFIG_FAIL = "[sportsbook] SPORTSBOOK_GET_BONUS_CONFIG_FAIL";


export const SPORTSBOOK_GET_BETS_BY_COUPON = "[sportsbook] SPORTSBOOK_GET_BETS_BY_COUPON";
export const SPORTSBOOK_GET_BETS_BY_COUPON_SUCCESS = "[sportsbook] SPORTSBOOK_GET_BETS_BY_COUPON_SUCCESS";
export const SPORTSBOOK_GET_BETS_BY_COUPON_FAIL = "[sportsbook] SPORTSBOOK_GET_BETS_BY_COUPON_FAIL";
export const SPORTSBOOK_CLOSE_BETS_BY_COUPON = "[sportsbook] SPORTSBOOK_CLOSE_BETS_BY_COUPON";


export const SPORTSBOOK_COUPON_EMAIL_TICKET = "[sportsbook] SPORTSBOOK_COUPON_EMAIL_TICKET";
export const SPORTSBOOK_COUPON_EMAIL_TICKET_SUCCESS = "[sportsbook] SPORTSBOOK_COUPON_EMAIL_TICKET_SUCCESS";
export const SPORTSBOOK_COUPON_EMAIL_TICKET_FAIL = "[sportsbook] SPORTSBOOK_COUPON_EMAIL_TICKET_FAIL";


export const SPORTSBOOK_GET_BET_HISTORY = "[sportsbook] SPORTSBOOK_GET_BET_HISTORY";
export const SPORTSBOOK_GET_BET_HISTORY_SUCCESS = "[sportsbook] SPORTSBOOK_GET_BET_HISTORY_SUCCESS";
export const SPORTSBOOK_GET_BET_HISTORY_FAIL = "[sportsbook] SPORTSBOOK_GET_BET_HISTORY_FAIL";

/* start network */
export class InitNetWork implements Action {
    readonly type = INITNETWORK;
    constructor(public payload: Object) { }
}

export class NetworkConnected implements Action {
    readonly type = NETWORK_CONNECTED;
    constructor() { }
}

export class NetworkConnectError implements Action {
    readonly type = NETWORK_CONNECT_ERROR;
    constructor(public payload: Object) { }
}

export class NetworkReceiveData implements Action {
    readonly type = NETWORK_RECEIVE_DATA;
    constructor(public payload: LiveMatch) { }
}

export class NetworkSend implements Action {
    readonly type = NETWORK_SEND;
    constructor(public payload: Object) { }
}

export class NetworkClose implements Action {
    readonly type = NETWORK_CLOSE;
    constructor() { }
}
/* end network */

export class SportsbookGetSports implements Action {
    readonly type = SPORTSBOOK_GET_SPORTS;
    constructor() { }
}
export class SportsbookGetSportsSuccess implements Action {
    readonly type = SPORTSBOOK_GET_SPORTS_SUCCESS;
    constructor(public payload: SportBookGetSportsResponse) { }
}
export class SportsbookGetSportsFail implements Action {
    readonly type = SPORTSBOOK_GET_SPORTS_FAIL;
    constructor(public payload: HttpClientError) { }
}

export class SportsbookGetLeagues implements Action {
    readonly type = SPORTSBOOK_GET_LEAGUES;
    constructor(public sportname: string, public filtervalue: string) { }
}
export class SportsbookGetLeaguesSuccess implements Action {
    readonly type = SPORTSBOOK_GET_LEAGUES_SUCCESS;
    constructor(public payload: LeaguesListResponse) { }
}
export class SportsbookGetLeaguesFail implements Action {
    readonly type = SPORTSBOOK_GET_LEAGUES_FAIL;
    constructor(public payload: HttpClientError) { }
}


export class SportsbookGetLeaguesAndMatches implements Action {
    readonly type = SPORTSBOOK_GET_LEAGUESANDMATCHES;
    constructor(public sportname: string, public filtervalue: string) { }
}
export class SportsbookGetLeaguesAndMatchesSuccess implements Action {
    readonly type = SPORTSBOOK_GET_LEAGUESANDMATCHES_SUCCESS;
    constructor(public payload: LeaguesAndMatchesList) { }
}
export class SportsbookGetLeaguesAndMatchessFail implements Action {
    readonly type = SPORTSBOOK_GET_LEAGUESANDMATCHES_FAIL;
    constructor(public payload: HttpClientError) { }
}


export class SetGenerateBetSlip implements Action {
    readonly type = GENERATEBETSLIP;
    constructor(public payload: IGenerateBetSlip) { }
}

export class SetBetSlip implements Action {
    readonly type = BETSLIP;
    constructor(public payload: BetSlip[]) { }
}
export class LiveGetSports implements Action {
    readonly type = LIVE_GETSPORTS;
    constructor(public payload: { name: string }[]) { }
}

export class SportsbookSendBets implements Action {
    readonly type = SPORTSBOOK_SEND_BETS;
    constructor(public payload: object) { }
}
export class SportsbookSendBetsSuccess implements Action {
    readonly type = SPORTSBOOK_SEND_BETS_SUCCESS;
    constructor(public payload: SendBetsSlipResponse) { }
}
export class SportsbookSendBetsFail implements Action {
    readonly type = SPORTSBOOK_SEND_BETS_FAIL;
    constructor(public payload: HttpClientError) { }
}


export class SportsbookGetBonusConfig implements Action {
    readonly type = SPORTSBOOK_GET_BONUS_CONFIG;
    constructor() { }
}
export class SportsbookGetBonusConfigSuccess implements Action {
    readonly type = SPORTSBOOK_GET_BONUS_CONFIG_SUCCESS;
    constructor(public payload: object) { }
}
export class SportsbookGetBonusConfigFail implements Action {
    readonly type = SPORTSBOOK_GET_BONUS_CONFIG_FAIL;
    constructor(public payload: HttpClientError) { }
}


export class SportsbookGetBetsByCoup implements Action {
    readonly type = SPORTSBOOK_GET_BETS_BY_COUPON;
    constructor(public payload: { transactionId: string }) { }
}
export class SportsbookGetBetsByCoupSuccess implements Action {
    readonly type = SPORTSBOOK_GET_BETS_BY_COUPON_SUCCESS;
    constructor(public payload: object, public transactionId: string) { }
}
export class SportsbookGetBetsByCoupFail implements Action {
    readonly type = SPORTSBOOK_GET_BETS_BY_COUPON_FAIL;
    constructor(public payload: HttpClientError) { }
}

export class SportsBookCloseBetsByCoupon implements Action {
    readonly type = SPORTSBOOK_CLOSE_BETS_BY_COUPON;
    constructor() { }
}



export class SportsbookCouponEmailTicket implements Action {
    readonly type = SPORTSBOOK_COUPON_EMAIL_TICKET;
    constructor(public payload: object) { }
}
export class SportsbookCouponEmailTicketSuccess implements Action {
    readonly type = SPORTSBOOK_COUPON_EMAIL_TICKET_SUCCESS;
    constructor(public payload: object) { }
}
export class SportsbookCouponEmailTicketFail implements Action {
    readonly type = SPORTSBOOK_COUPON_EMAIL_TICKET_FAIL;
    constructor(public payload: HttpClientError) { }
}



export class SportsbookGetBetHistory implements Action {
    readonly type = SPORTSBOOK_GET_BET_HISTORY;
    constructor(public payload: object) { }
}
export class SportsbookGetBetHistorySuccess implements Action {
    readonly type = SPORTSBOOK_GET_BET_HISTORY_SUCCESS;
    constructor(public payload: object) { }
}
export class SportsbookGetBetHistorytFail implements Action {
    readonly type = SPORTSBOOK_GET_BET_HISTORY_FAIL;
    constructor(public payload: HttpClientError) { }
}

export type SportsBookActions = SportsbookGetSports | SportsbookGetSportsSuccess | SportsbookGetSportsFail |
    SportsbookGetLeagues | SportsbookGetLeaguesSuccess | SportsbookGetLeaguesFail |
    SportsbookGetLeaguesAndMatches | SportsbookGetLeaguesAndMatchesSuccess | SportsbookGetLeaguesAndMatchessFail |
    InitNetWork | NetworkConnected | NetworkConnectError | NetworkReceiveData | NetworkSend | NetworkClose |
    SetGenerateBetSlip | SetBetSlip | LiveGetSports | SportsbookGetBonusConfig |
    SportsbookGetBonusConfigSuccess | SportsbookGetBonusConfigFail |
    SportsbookGetBetsByCoup | SportsbookGetBetsByCoupSuccess | SportsbookGetBetsByCoupFail |
    SportsbookSendBets | SportsbookSendBetsSuccess | SportsbookSendBetsFail | SportsBookCloseBetsByCoupon |
    SportsbookCouponEmailTicket | SportsbookCouponEmailTicketSuccess | SportsbookCouponEmailTicketFail |
    SportsbookGetBetHistory | SportsbookGetBetHistorySuccess | SportsbookGetBetHistorytFail;