import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {environment} from 'src/environments/environment';
import { Profile } from '../../modules/player/profile';
import { GameLaunchURLs } from '../../modules/player/gameLaunchURLs';
import { LoginService } from '../login/login.service';
import { TournamentsList } from '../../modules/player/tournamentsList';
import { Stats } from '../../modules/player/stats';
import {  GameHistoryData } from '../../modules/gamehistory';
import { RemoteGamesHistory } from '../../modules/remotegamehistory/remotegamehistory';

function launchFlashLobby(params:object) {
  let authWindow = window.open('about:blank', '', 'left=20,top=20,width=800,height=600,toolbar=0,resizable=1');
  authWindow.location.replace("/lobby/lobbyflash.html");
  authWindow.sessionStorage.setItem("flashgamelaunch",JSON.stringify(params));
}

@Injectable({
  providedIn: 'root'
})
export class PlayerService {
  allGames: any;
  gameId: any;
  isMobile: boolean;

  constructor(private httpClient:HttpClient) { }
  
  onPlayerGetProfile(){
    return this.httpClient.post<Profile>(`${environment.appApi.baseUrl}${environment.appApi.player.getProfile}`,{},this.httpOptions());
  }
  onPlayerUpdateProfile(postData){
    return this.httpClient.post(`${environment.appApi.baseUrl}${environment.appApi.player.updateProfile}`,postData,this.httpOptions());
  }
  onPlayerUpdatePassword(postData){
    return this.httpClient.post(`${environment.appApi.baseUrl}${environment.appApi.player.updatePassword}`,postData,this.httpOptions());
  }
  onPlayerGetLaunchURLs(){
    return this.httpClient.post<GameLaunchURLs>(`${environment.appApi.baseUrl}${environment.appApi.player.getLaunchURLS}`,{},this.httpOptions());
  }

  onPlayerGetGameHistory(postdata){
    return this.httpClient.post<GameHistoryData>(`${environment.appApi.baseUrl}${environment.appApi.history.game}`,postdata,this.httpOptions());
  }
  onPlayerGetTournamentsList(postdata){
    return this.httpClient.post<TournamentsList>(`${environment.appApi.baseUrl}${environment.appApi.player.tournamentsList}`,postdata,this.httpBeforLoginOptions);
  }

  onPlayerGetStats(){
    return this.httpClient.post<Stats>(`${environment.appApi.baseUrl}${environment.appApi.player.playerStats}`,{},this.httpBeforLoginOptions);
  }

  onPlayerGetPlayerLevels(){
    return this.httpClient.post(`${environment.appApi.baseUrl}${environment.appApi.player.playerLevels}`,{},this.httpOptions());
  }

  onPlayerLeaderBoardList(){
    return this.httpClient.post(`${environment.appApi.baseUrl}${environment.appApi.leaderboardList}`,{},this.httpOptions());
  }
  getExchangeRates(){
    return this.httpClient.post<any>(`${environment.appApi.baseUrl}${environment.appApi.player.getExchangeRates}`,{},this.httpOptions());
  }
  getVirtuals(data){
    // console.log(data)

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.isMobile = true;
      return this.httpClient.get<any>(`${environment.appApi.baseUrl}${environment.appApi.virtuals}${localStorage.getItem ( 'trophy777User')}/${data}/mobile`);
    } else {
      this.isMobile = false;
      return this.httpClient.get<any>(`${environment.appApi.baseUrl}${environment.appApi.virtuals}${localStorage.getItem ( 'trophy777User')}/${data}/desktop`);
    }
  }
  makeExchange(exchangeInfo)
  {
    return this.httpClient.post<any>(`${environment.appApi.baseUrl}${environment.appApi.player.exchangeVipPointsUrl}`,exchangeInfo,this.httpOptions());
  
  }
  onPlayerLeaderBoardGetParticipants(){
    return this.httpClient.post(`${environment.appApi.baseUrl}${environment.appApi.getParticipants}`,{},this.httpOptions());
  }
  onPlayerGetRemoteGameHistory(postdata){
    return this.httpClient.post<RemoteGamesHistory>(`${environment.appApi.baseUrl}${environment.appApi.history.remotegame}`,postdata,this.httpOptions());
  }
  launchFlashGame(value:GameLaunchURLs){
    let params ={serverUrls:[],sessionID:"",clientType:"",LANG:"",swf2load:""};
    value.connections.forEach(value => {
      params.serverUrls.push(value.uri);
    });
    
    params.sessionID = sessionStorage.getItem('wsession').split(".")[1];
    console.log(sessionStorage.getItem('wsession').split(".")[1]);
    params.clientType="POKER_LOBBY";
    params.LANG="en";
    params.swf2load="flashloader";
    launchFlashLobby(params);
  }

  httpOptions() {
    let options = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Access-Control-Allow-Origin':'*',
        'siteid': environment.skinId,
        'wsession':sessionStorage.getItem('wsession')
      })
    };
    return options;
  }

  httpBeforLoginOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*',
      'siteid': environment.skinId
    })
  };
}
