import { Component, OnInit, OnDestroy } from "@angular/core";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import { WalletInfo } from "src/app/source/modules/cashier/balance";
import { CashierState } from "src/app/source/appstates/cashierstates/cashierState";
import { CashierGetBalanceStart } from "src/app/source/appstates/cashierstates/cashierActions";
import * as playerActions from "src/app/source/appstates/playerstates/playerActions";
import { Router } from "@angular/router";
import { LoginState } from "src/app/source/appstates/loginstates/loginState";
import { PlayerService } from "src/app/source/services/player/player.service";
import * as cashierActions from "src/app/source/appstates/cashierstates/cashierActions";
import * as appState from "src/app/source/appstates/appState";
import { CommonUtilService } from "src/app/commonutil.service";
import { ProfileState } from "src/app/source/appstates/playerstates/playerState";
import { appConf } from "src/app/app.config";

@Component({
  selector: 'app-mybalance',
  templateUrl: './mybalance.component.html',
  styleUrls: ['./mybalance.component.scss'],
})


export class MybalanceComponent implements OnInit, OnDestroy {
  private storeSub: Subscription;
  private loginSub: Subscription;
  private profilestoreSub: Subscription;
  walleteInfo: WalletInfo[];
  preferredBalance: WalletInfo;
  playerLoggedIn: boolean = false;
  /* NEWLY ADDED */
  availableAmount: any = 0;
  selectedlanguege = "FA";
  bonus: any = 0;
  inPlay: any = 0;
  total: any = 0;
  vipPoints: any = 0;
  playerLevel: any;
  profile: any;
  vipPointsFilter: any = [];
  vipNameFilter: any = [];
  endDate: any;
  vipName: any;
  vipObj: any = [];
  vipLevels: any = [];
  vipLevelPoints: any = [];
  profileName: any = sessionStorage.getItem("profile");
  isError: boolean = false;
  playerLevelloader: boolean = true;
  errMsg: any = "";
  monthCollectedPints: any;
  goldPoints: any;
  yearCollectedPints: any;
  weeklyCollectedPints: any;
  endDateMonthly: any;
  endDateWeekly: any;
  endDateYearly: any;
  endDateHourly: any;
  constructor(
    private playerService: PlayerService,
    private store: Store<appState.AppState>,
    private commonUtilSer: CommonUtilService
  ) {
    this.selectedlanguege = localStorage.getItem("locale");
  }
  ngOnInit() {
    this.loginSub = this.store
      .select("loginState")
      .subscribe((loginState: LoginState) => {
        if (loginState.playerLoggedIn) {
          this.playerLoggedIn = loginState.playerLoggedIn.loggedIn;
          if (this.playerLoggedIn) {
            this.store.dispatch(new cashierActions.CashierGetBalanceStart());
          }
        }
      });
    this.resetError();
    this.store.dispatch(new playerActions.ResetState());
    this.storeSub = this.store
      .select("cashierState")
      .subscribe((cashierState: CashierState) => {
        if (cashierState.balance) {
          // this.cashier = cashierState.balance;
          if (cashierState.balance.success == true) {
            this.walleteInfo = cashierState.balance.values;
            this.loadWalletsData(this.walleteInfo);
            for (let wallete of this.walleteInfo) {
              if (wallete.preferred === true) {
                this.preferredBalance = wallete;
                break;
              }
            }
          } else if (cashierState.balance.success == false) {
            this.setError(cashierState.balance.description);
          }
        }
      });
    this.profilestoreSub = this.store
      .select("playerState")
      .subscribe((playerState: ProfileState) => {
        if (playerState.profile) {
          this.profile = playerState.profile;
          this.profileName = this.profile.login;
        }
      });
    this.getPlayerLevel();
  }

  ngOnDestroy() {
    if (this.storeSub) {
      this.storeSub.unsubscribe();
    }
    if (this.profilestoreSub) {
      this.profilestoreSub.unsubscribe();
    }
    if (this.loginSub) {
      this.loginSub.unsubscribe();
    }
  }

  loadWalletsData(apiRes) {
    let prefCurrency = this.commonUtilSer.loadAppPreferredCurrency(apiRes);
    let availableAmountblc = this.commonUtilSer.loadCashbalanceBasedOnCurrency(
      apiRes,
      prefCurrency,
      "cash"
    );
    this.availableAmount = availableAmountblc.toString().split(".")[0];
    let bonusblc = this.commonUtilSer.loadCashbalanceBasedOnCurrency(
      apiRes,
      prefCurrency,
      "bonus"
    );
    this.bonus = bonusblc.toString().split(".")[0];
    this.inPlay = this.commonUtilSer.loadCashbalanceBasedOnCurrency(
      apiRes,
      prefCurrency,
      "cashInPlay"
    );
    let totalblc = this.commonUtilSer.addNumbers([
      this.availableAmount,
      this.bonus,
      this.inPlay,
    ]);
    this.total = totalblc.toString().split(".")[0];
    let vipPointsblc = this.commonUtilSer.loadCashbalanceBasedOnCurrency(
      apiRes,
      appConf.vipPointsKey,
      "cash"
    );
    this.vipPoints = vipPointsblc.toString().split(".")[0];
  }

  getPlayerLevel() {
    //   this.authSer.getDataOnPostCall(this.levelApi, {}, this.levelHandler.bind(this));
    this.playerService.onPlayerGetPlayerLevels().subscribe((data) => {
      this.levelHandler(data);
    });
  }
  levelHandler(apiRes) {
    this.vipLevels = apiRes.playerLevelResponses;
    // for(let i=0; i<this.vipLevels.length; i++ ){
    //   this.vipLevels[i].expPeriodsNumber;
    //   console.log( this.vipLevels[i].expPeriodsNumber )
    //   if(this.vipLevels[i].expPeriodsNumber == 6 ){
    //     this.vipPointsFilter.push(this.vipLevels[i].compPoints)
    //     this.vipNameFilter.push(this.vipLevels[i].name)
    //     this.vipObj.push({name: this.vipLevels[i].name, compPoints : this.vipLevels[i].compPoints})
    //   } else if(this.vipLevels[i].expPeriodsNumber == 1) {
    //     this.vipName = this.vipLevels[i].name;
    //   } else {
    //     this.vipName = this.vipLevels[i].name;
    //   }
    // }
    // console.log( this.vipPoints + "   :   " + this.vipLevels[3].compPoints )
    if (apiRes.yearkyCounter.endDate) {
      this.endDateYearly = apiRes.yearkyCounter.endDate;
      this.endDateYearly = this.endDateYearly.replace("999", "");
    } else {
      this.endDateYearly = "";
    }
    if (apiRes.monthlyCounter.endDate) {
      this.endDateMonthly = apiRes.monthlyCounter.endDate;
      this.endDateMonthly = this.endDateMonthly.replace("999", "");
    } else {
      this.endDateMonthly = this.endDateYearly;
    }
    if (apiRes.weeklyCounter.endDate) {
      this.endDateWeekly = apiRes.weeklyCounter.endDate;
      this.endDateWeekly = this.endDateWeekly.replace("999", "");
    } else {
      this.endDateWeekly = this.endDateYearly;
    }
    if (apiRes.hourlyCounter.endDate) {
      this.endDateHourly = apiRes.hourlyCounter.endDate;
      this.endDateHourly = this.endDateHourly.replace("999", "");
    } else {
      this.endDateHourly = "";
    }
    for (let i = 0; i < this.vipLevels.length; i++) {
      console.log(this.vipPoints + "    " + this.vipLevels[i].compPoints);
      console.log(this.vipPoints < this.vipLevels[i].compPoints);

      if (this.vipPoints < this.vipLevels[i].compPoints) {
        this.vipPointsFilter.push(this.vipLevels[i].compPoints);
        this.vipNameFilter.push(this.vipLevels[i].name);
        if (this.vipLevels[i].period == "Week") {
          this.vipObj.push({
            name: this.vipLevels[i].name,
            endDate: this.endDateWeekly,
            compPoints: this.vipLevels[i].compPoints,
          });
        } else if (this.vipLevels[i].period == "Month") {
          this.vipObj.push({
            name: this.vipLevels[i].name,
            endDate: this.endDateMonthly,
            compPoints: this.vipLevels[i].compPoints,
          });
        } else if (this.vipLevels[i].period == "Year") {
          this.vipObj.push({
            name: this.vipLevels[i].name,
            endDate: this.endDateYearly,
            compPoints: this.vipLevels[i].compPoints,
          });
        } else {
          this.vipObj.push({
            name: this.vipLevels[i].name,
            endDate: this.endDateHourly,
            compPoints: this.vipLevels[i].compPoints,
          });
        }
      } else {
        this.vipName = this.vipLevels[i].name;
      }
    }
    console.log(this.vipNameFilter);
    console.log(this.vipObj);
    this.monthCollectedPints = apiRes.monthlyCounter.compPoints;
    this.yearCollectedPints = apiRes.yearkyCounter.compPoints;
    this.weeklyCollectedPints = apiRes.weeklyCounter.compPoints;
    if(apiRes.goldCounter){
      this.goldPoints = apiRes.goldCounter.compPoints;
    }
    this.playerLevelloader = false;
    if (apiRes.success) {
      if (
        "playerLevelResponses" in apiRes &&
        apiRes["playerLevelResponses"] &&
        apiRes["playerLevelResponses"].length > 0
      ) {
        this.playerLevel = apiRes["playerLevelResponses"][0]["name"];
      } else {
        this.playerLevel = "NA";
      }
    } else {
      // ERROR POPUP
      this.setError("code" in apiRes ? apiRes["code"] : "Unknown error");
    }
  }

  resetError() {
    this.isError = false;
    this.errMsg = "";
  }
  setError(errMsg) {
    this.errMsg = this.errMsg + " and " + errMsg;
    this.isError = true;
  }
}
