import { Component, OnInit } from '@angular/core';
import * as cashierActions from 'src/app/source/appstates/cashierstates/cashierActions';
import * as appState from 'src/app/source/appstates/appState';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { WalletInfo } from 'src/app/source/modules/cashier/balance';
import { CashierState } from 'src/app/source/appstates/cashierstates/cashierState';
import { appConf } from 'src/app/app.config';
import { PlayerService } from 'src/app/source/services/player/player.service';
import { CommonUtilService } from 'src/app/commonutil.service';
import { appConstants } from 'src/app/appconstants';
import { LoginState } from 'src/app/source/appstates/loginstates/loginState';
import { Location } from '@angular/common';

@Component({
  selector: 'app-exchange',
  templateUrl: './exchange.component.html',
  styleUrls: ['./exchange.component.scss'],
})
export class ExchangeComponent implements OnInit {

  private storeSub: Subscription;
  private loginSub: Subscription;
  walleteInfo: WalletInfo[];
  preferredBalance: WalletInfo;
  playerLoggedIn: boolean = false;
  availableAmount: any = 0;
  bonus: any = 0;
  inPlay: any = 0;
  total: any = 0;
  playerLevel: any = "NA";
  uiSelection: any = "Cash";
  selectedExchange: any = {};
  selectedDropDown: any = [];
  selectedDropDownValue: any = "";
  mapping: any = {};
  cashToVipMapping: any = null;
  exchangeData: any = null;
  exchangeDataLength: any = 0;
  vipPoints: any = "NA";
  tableData: any = [];
  isError: boolean = false;
  successMsg: any = null;
  errMsg: string = "";

  lang: any = appConstants.language;
  historyloader: boolean = false;
  constructor(private store: Store<appState.AppState>, private playerService: PlayerService
    , private commonUtilSer: CommonUtilService, private location: Location) {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    // this.store.dispatch(new cashierActions.CashierGetBalanceStart());
  }

  ngOnInit() {

    this.loginSub = this.store
      .select("loginState")
      .subscribe((loginState: LoginState) => {
        if (loginState.playerLoggedIn) {
          this.playerLoggedIn = loginState.playerLoggedIn.loggedIn;
          if (this.playerLoggedIn) {
            this.store.dispatch(new cashierActions.CashierGetBalanceStart());
          }
        }
      });
    this.storeSub = this.store.select("cashierState").subscribe(
      (cashierState: CashierState) => {
        if (cashierState.balance) {
          // this.cashier = cashierState.balance;
          this.walleteInfo = cashierState.balance.values;
          this.loadWalletsData(this.walleteInfo);
          for (let wallete of this.walleteInfo) {
            if (wallete.preferred === true) {
              this.preferredBalance = wallete;
              break;
            }
          }
        }
      }
    );
  }
  loadWalletsData(apiRes) {
    this.vipPoints = this.commonUtilSer.loadCashbalanceBasedOnCurrency(apiRes, appConf.vipPointsKey, "cash");
    this.loadExchangeRates();
  }
  loadExchangeRates() {
    // this.toastSer.showOverLay();
    // this.authSer.getDataOnPostCall(environment["api_url"] + environment["exchangerates_api"], {}, this.datahandler.bind(this))
    // this.resetError();
    this.playerService.getExchangeRates().subscribe((data) => {
      this.datahandler(data);
    })
  }
  datahandler(apiRes) {
    if (apiRes.success) {
      this.exchangeData = apiRes.values;
      this.loadDataToView();
    }
    else {
      // ERROR POPUP
      this.setError("code" in apiRes ? apiRes["code"] : "Unknown error");
    }
  }
  loadDataToView(key = null) {
    if (!key)
      key = this.uiSelection;
    this.uiSelection = key;
    if (this.exchangeData) {
      this.exchangeDataLength = this.exchangeData.length;
      this.exchangeData.forEach((k) => {
        if (k.exchangeType.toLowerCase() == key.toLowerCase())
          this.selectedExchange = k;
      });
      this.mapConversionToVip("Cash");
      this.mapConversionToVip("Bonus");
      this.limitSelectedDropDown();
    }
    else {
      this.loadExchangeRates();
    }

  }
  mapConversionToVip(key) {

    if (!this.cashToVipMapping)
      this.cashToVipMapping = {};
    {
      if (this.exchangeData) {
        for (var i = 0; i < this.exchangeDataLength; i++) {
          if (this.exchangeData[i]["exchangeType"] == key) {
            this.cashToVipMapping[key] = {};
            for (var k in this.exchangeData[i]["rates"]) {
              this.cashToVipMapping[key][String(this.exchangeData[i]["rates"][k])] = k;
            }

          }
        }
      }
    }
  }
  limitSelectedDropDown() {
    if (!(this.uiSelection in this.mapping) || !("selectedDropDown" in this.mapping[this.uiSelection])) {
      const tempSelection = [];
      this.mapping[this.uiSelection] = {};
      this.mapping[this.uiSelection]["tableData"] = [];
      let isAtleastOne = false;
      this.tableData = [];
      if (this.vipPoints != null) {
        const vipPoint = Number(this.vipPoints);
        for (var key in this.selectedExchange["rates"]) {
          this.tableData.push([key, this.selectedExchange["rates"][key]])
          if (vipPoint >= Number(key)) {
            tempSelection.push(this.selectedExchange["rates"][key]);
            isAtleastOne = true;
          }
        }
      }
      tempSelection.sort(function (a, b) { return (Number(a) - Number(b)) });
      this.tableData.sort(function (a, b) { return (Number(a[0]) - Number(b[0])) });
      this.selectedDropDown = tempSelection;
      console.log(this.selectedDropDown)
      if (isAtleastOne) {
        this.selectedDropDownValue = this.selectedDropDown[0];
      }
      this.mapping[this.uiSelection]["selectedDropDownValue"] = this.selectedDropDownValue;
      this.mapping[this.uiSelection]["selectedDropDown"] = this.selectedDropDown;
      this.mapping[this.uiSelection]["tableData"] = this.tableData;
    }
    else {
      this.selectedDropDownValue = this.mapping[this.uiSelection]["selectedDropDownValue"];
      this.selectedDropDown = this.mapping[this.uiSelection]["selectedDropDown"];
      this.tableData = this.mapping[this.uiSelection]["tableData"];
    }

  }

  submitExchange() {
    this.historyloader = true;
    this.successMsg = '';
    this.isError = false;
    this.errMsg = "";
    let amount = Number(this.cashToVipMapping[this.uiSelection][this.selectedDropDownValue])
    if (this.vipPoints == 0 || !this.vipPoints) {
      this.setError("No points");
      return;
    }
    if (amount >= this.vipPoints) {
      this.setError("You need Min 500 VIP points to exchange to CASH/BONUS");
      return;
    }
    const body = {
      "amount": Number(this.cashToVipMapping[this.uiSelection][this.selectedDropDownValue]),
      "targetAmount": this.selectedDropDownValue,
      "cpExchangeType": this.uiSelection == "Cash" ? "0" : "1",
      "selectedWalletType": "INR"
    }
    console.log(body)
    this.playerService.makeExchange(body)
      .subscribe((data) => {
        this.exchangeconvert(data);
      }, (err) => {
        this.setError(err);
      })
  }
  exchangeconvert(data) {
    if (data && data.success) {

      this.store.dispatch(new cashierActions.CashierGetBalanceStart());
      if (this.lang == "EN")
        this.setError("VIP Points Converted Successfully");
      else
        this.setError("VIP Points Converted Successfully");
      // this.successMsg="Exchange Converted successfull";
      // setTimeout(() => {
      // this.successMsg = '';
      // }, 3000);
    }
    else {
      this.setError("code" in data ? data["code"] : "Unknown error");
    }
  }

  errorHandle(err) {
  }

  resetError() {
    this.isError = false;
    this.errMsg = "";
  }
  setError(errMsg) {
    this.historyloader = false;
    if (errMsg == 'INVALID_SESSION_TOKEN') {
      this.errMsg = 'Invalid Session';
      this.isError = true;
    } else {
      this.errMsg = errMsg;
      this.isError = true;
    }
    // setTimeout(() => {
    // this.resetError();
    // }, 5000);
  }
  backClicked() {
    this.location.back();
    window.scroll(0, 0);
  }
}
