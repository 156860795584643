import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import * as appState from 'src/app/source/appstates/appState';
import * as loginActions from 'src/app/source/appstates/loginstates/loginActions';
import { Subscription } from 'rxjs';
import { LoginState } from 'src/app/source/appstates/loginstates/loginState';
import { FormGroup, FormControl, Validators } from '@angular/forms';
declare var $: any;
function showforgotpasswordModal() {
  $("#forgotpassword").modal("show");
}
function hideforgotpasswordModal() {
  $("#forgotpassword").modal("hide");
}
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  private storeSub: Subscription;
  form: FormGroup;
  errorMessage:string;
  successMessage:string;
  sMessage:boolean = false;

  constructor(private store: Store<appState.AppState>) { }

  ngOnInit() {
    this.form = new FormGroup({
      "email": new FormControl(null, [Validators.required, Validators.email, Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]),
      "login": new FormControl(null, [Validators.required, Validators.minLength(4),Validators.maxLength(15),Validators.pattern('[a-zA-Z0-9]*')])
    })

    this.storeSub = this.store.select("loginState").subscribe(
      (loginState: LoginState) => {
        
         if(loginState.forgotPasswordResponse){
          if(loginState.forgotPasswordResponse.success === true){
            // this.errorMessage = null;
            this.sMessage = true
            this.successMessage = "Password sent successfully to the registered email ID.";
            // alert("wait")
            // hideforgotpasswordModal();
          }
          if(loginState.forgotPasswordResponse.description){
            this.successMessage=null;
            this.errorMessage = loginState.forgotPasswordResponse.description;
          }
        }
        // if(loginState.forgotPasswordErrorResponse){
        //   this.successMessage=null;
        //   this.errorMessage = loginState.forgotPasswordErrorResponse.message;
        // }
      } 
    )
  }
  onInputFocus(){
    this.errorMessage = "";
  }
  onFormSubmit(){
    this.store.dispatch(new loginActions.ForgotPasswordStart(this.form.value));
  }
  ngOnDestroy() {
    if (this.storeSub) {
      this.storeSub.unsubscribe();
    }
  }

  emailValids(event) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k >= 33 && k <= 47 || k >= 60 && k <= 64 || (k >= 48 && k <= 57) || k >= 91 && k <= 96 || k >= 123 && k <= 126);
  }
  username(event){
    var R
    R = event.charCode;
    console.log(R)
    
    return((R>64 && R<91) || (R>96 && R<123) || (R>48 && R<57) )   
  }
}
