import { Component, OnInit } from '@angular/core';
import { SlotsService } from "../../../../source/services/slotsservice/slots.service";
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import * as appState from 'src/app/source/appstates/appState';
import {slotsData} from '../../../../source/modules/slotsmodel/slotsdata';
import {RootObject} from '../../../../source/modules/slotsmodel/slotsvalue';
import {buildRootObject, buildGame} from '../../../../source/modules/slotsmodel/builsslots';
import { environment } from '@app/env';
import * as cashierActions from 'src/app/source/appstates/cashierstates/cashierActions';
import { LoginState } from 'src/app/source/appstates/loginstates/loginState';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ProfileState } from 'src/app/source/appstates/playerstates/playerState';
import * as loginActions from 'src/app/source/appstates/loginstates/loginActions';

@Component({
  selector: 'app-slots',
  templateUrl: './slots.component.html',
  styleUrls: ['./slots.component.css'],
})
export class SlotsComponent implements OnInit {
  private storeProfileSub :Subscription;
  private storeSub: Subscription;
  slotsdata:slotsData;
  slotsfundata:slotsData;
  rootObject:RootObject;
  buildRootObject:buildRootObject;
  buildGame:buildGame;
  imagespath=environment.slotsiamgepath;
  keyname:any;
  gamelunchurl:any;
  urlSafe:SafeResourceUrl;
  brandid:any;
  playerName:string;
  tokendata:any=null;
  casinogamessuccessPop:boolean=false;
  casinomessage:string;
  playerLoggedIn: boolean = false;
  loader_div: boolean = false;
  registerRespPopup: boolean = false;
  regSuccessPoptext: string = "";
  constructor(public sanitizer: DomSanitizer, private SlotsService: SlotsService, private store: Store<appState.AppState>) {
    setTimeout(() => {
      this.regSuccessPoptext = '';
      this.registerRespPopup = false;
    }, 100);

    this.storeSub = this.store.select("loginState").subscribe(
      // (loginState: LoginState) => {
      //   if (loginState.playerLoggedIn) {
      //       this.playerLoggedIn = loginState.playerLoggedIn.loggedIn;
      //   }
      // })
      (loginState: LoginState) => {
        if (loginState.loginResponse) {
          // console.log(loginState.loginResponse);
          if (loginState.loginResponse.success === true) {
            // console.log(loginState.loginResponse.loginResponse.gameClientLaunchResponse);
            if(loginState.loginResponse.loginResponse.gameClientLaunchResponse.success === true){
              setTimeout(() => {
               this.registerRespPopup = true;
               this.regSuccessPoptext = 'Congratulations, your account has been successfully created.';
             }, 1000);
            }
          }
        }
          });
    this.store.dispatch(new cashierActions.CashierGetBalanceStart());
    this.SlotsService.getJSON().subscribe(slotdata => { this.slotsvaluelunch(slotdata);
    });
    this.hitfunService();
    this.playerName= sessionStorage.getItem('profile');
    if(this.playerName != null){
        this.hitService(this.playerName);
      }
  }
  slotsvaluelunch(slotdata){
    console.log(slotdata)
    let slotGames = []
      this.rootObject= slotdata;
      console.log(this.rootObject.Games)

      if(slotdata.Games.GameTypeName == "Video Slots" || "Slots" ){
          slotGames.push(slotdata.Games)
          console.log(slotGames)
      }
  }
  ngOnInit() {
   // this.store.dispatch(new loginActions.ResetState());
    this.storeSub = this.store.select("loginState").subscribe(
      (loginState: LoginState) => {
        if (loginState.playerLoggedIn) {
            this.playerLoggedIn = loginState.playerLoggedIn.loggedIn;
        }
      })

     
  
   
      // this.storeProfileSub = this.store.select("playerState").subscribe(
      //   (profileState: ProfileState) => {
      //     if (profileState.profile) {
      //       if (profileState.profile.success === true) {
      //         if (profileState.profile.login) {
      //           this.playerName = profileState.profile.login;
      //           this.hitService(this.playerName);
      //         }
      //       }else if (profileState.profile.success === false) {
      //       }
      //     }
      //   })
  }
  responseofsession(){
    this.store.dispatch(new cashierActions.CashierGetBalanceStart());
  }
  hitService(profile) {
    //let profile = sessionStorage.getItem('profile');
    this.SlotsService.httpClient(profile).subscribe(data => { this.slotslunch(data) });
  };
  hitfunService() {
    //let profile = sessionStorage.getItem('profile');
    this.SlotsService.httpClientfun().subscribe(data => { this.slotslunchfun(data) });
  };
  // slotsinlogout(data) {
  //   if (this.isLogin == true) {
  //     this.store.dispatch(new cashierActions.CashierGetBalanceStart());
  //     this.keyname = data;
  //     if (this.tokendata == null) {
  //       this.hitService();
  //     } else if(this.tokendata != null){
  //       this.gamelunchurl= this.slotsdata.HABANERO_GAMING_URL+'brandid='+this.slotsdata.BRAND_ID+'&keyname='+data+'&token='+this.slotsdata.TOKEN+'&mode=real&locale=en';
  //       this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.gamelunchurl);
  //       window.scroll(0,450);
  //       return;
  //     }
  //   } else if (this.isLogin == false) {
  //     this.casinomessage="Please login";
  //     this.casinogamessuccessPop=true;
  //   }
  // }
  slotsreal(data) {
    if (this.playerLoggedIn == true) {
      this.loader_div = true;
      this.store.dispatch(new cashierActions.CashierGetBalanceStart());
      this.keyname = data;
      setTimeout(() => {
        this.loader_div = false;
      }, 10000);
      if (this.tokendata == null) {
        let profile = sessionStorage.getItem('profile');
        this.hitService(profile);
      } else if(this.tokendata != null){
        this.gamelunchurl= this.slotsdata.HABANERO_GAMING_URL+'brandid='+this.slotsdata.BRAND_ID+'&keyname='+this.keyname+'&token='+this.slotsdata.TOKEN+'&mode=real&locale=en';
        this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.gamelunchurl);
        window.scroll(0,350);
        return;
      }
    }else if (this.playerLoggedIn == false) {
      this.casinomessage = 'Please Login';
     this.casinogamessuccessPop= true;
    }
  }
  slotsfun(data) {
    this.loader_div = true;
    this.keyname = data;
      if (this.brandid == null) {
        this.hitfunService();
      } else if(this.brandid != null){
        this.gamelunchurl= this.slotsfundata.HABANERO_GAMING_URL+'brandid='+this.slotsfundata.BRAND_ID+'&keyname='+this.keyname+'&mode=fun&locale=en';
        this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.gamelunchurl);
        // window.scroll(0,350);
        window.scroll({
          top: 350,
          left: 0,
          behavior: 'smooth'
        });
        return;
      }
      setTimeout(() => {
        this.loader_div = false;
      }, 10000);
    
  }
  slotslunch(data) {
    if (data) {
      this.slotsdata = data;
      this.tokendata = this.slotsdata.TOKEN;
    //  this.gamelunchurl= this.slotsdata.HABANERO_GAMING_URL+'brandid='+this.slotsdata.BRAND_ID+'&keyname='+this.keyname+'&token='+this.slotsdata.TOKEN+'&mode=real&locale=en';
    // this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.gamelunchurl);
    //  window.scroll(0,450);
    //  return;
    }
  }
  slotslunchfun(data) {
    if (data) {
      this.slotsfundata = data;
      this.brandid = this.slotsfundata.BRAND_ID;
    //  this.gamelunchurl= this.slotsdata.HABANERO_GAMING_URL+'brandid='+this.slotsdata.BRAND_ID+'&keyname='+this.keyname+'&token='+this.slotsdata.TOKEN+'&mode=fun&locale=en';
    // this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.gamelunchurl);
    //  window.scroll(0,450);
    //  return;
    }
  }
  casinogamessuccessPopClose(){
    this.casinogamessuccessPop= false;
    this.casinomessage = '';
  }
  urlSafeBtn(){
    this.urlSafe = false;
    window.scroll(0,0)
   }
   closePop(){

    this.registerRespPopup = false;
   }
}

