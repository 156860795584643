import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './media/euro/home/home.component';
import { MainComponent } from './media/euro/home/main/main.component';
import { PagenotfoundComponent } from './media/euro/home/pagenotfound/pagenotfound.component';

const routes: Routes = [
  // { path: '', redirectTo: '', pathMatch: 'full'},
  { path:'', redirectTo:'main', pathMatch:'full'},
  // { path:'home', component:HomeComponent},
  {path:"home",component:MainComponent},
  { path: 'main', component:MainComponent},
  { path:'**', component:PagenotfoundComponent}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {onSameUrlNavigation:"reload"})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
