import { appConf } from './app.config';
import { DomainConfigModel } from './DomainConfigModel';

export module DomainConf {
    const listOfDomains = {
        "winnabets": getWinnabetsConfig,
        "ikkaa": getIkkaConfig,
        "moguls": getPokerMogulsConfig,
        "redsonbet": getRedsonBetConfig,
        "mogulsTest": getPokerMogulsTestingConfig,
        "appMoguls":getAppMogulsTestingConfig,
        "rp1":getRp1Config
    };
    const currentDomain = "rp1";
    let domainConfig = new DomainConfigModel();
    export function getDomainConfigData(): DomainConfigModel {
        return listOfDomains[currentDomain]();
    }
    export function getRp1Config() {
        domainConfig.api_url = "https://rp1.czargaming.com";
        domainConfig.currency = "INR";
        domainConfig.siteId = "rp1";
        return domainConfig;
    }
    export function getWinnabetsConfig() {
        domainConfig.api_url = "http://winnabets.czargaming.com/api/";
        domainConfig.currency = "GHS";
        domainConfig.siteId = "winnabets";
        return domainConfig;
    }
    export function getRedsonBetConfig() {
        domainConfig.api_url = "http://redsonbet.czargaming.com/api/";
        domainConfig.currency = "NGN";
        domainConfig.siteId = "redsonbet";
        return domainConfig;
    }
    export function getPokerMogulsConfig() {
        domainConfig.api_url = "https://www.pokermoguls.com/api/";
        domainConfig.currency = "INR";
        domainConfig.siteId = "moguls";
        return domainConfig;
    }
    export function getPokerMogulsTestingConfig() {
        domainConfig.api_url = "http://moguls.undertesting.com/api/";
        domainConfig.currency = "INR";
        domainConfig.siteId = "moguls";
        return domainConfig;
    }
    export function getIkkaConfig() {
        domainConfig.api_url = "https://ikkaapoker.czargaming.com/api/";
        domainConfig.currency = "INR";
        domainConfig.siteId = "ikkaa";
        return domainConfig;
    }
    export function getAppMogulsTestingConfig() {
        domainConfig.api_url = "http://app.pokermoguls.com/api/";
        domainConfig.currency = "INR";
        domainConfig.siteId = "moguls";
        return domainConfig;
    }
}