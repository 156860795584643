import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import * as appState from 'src/app/source/appstates/appState';
import { PlayerGetTournamentsList, PlayerLeaderBoardList, PlayerLeaderBoardGetParticipants } from 'src/app/source/appstates/playerstates/playerActions';
import { Subscription } from 'rxjs';
import { ProfileState } from 'src/app/source/appstates/playerstates/playerState';
import { Values } from 'src/app/source/modules/player/tournamentsList';

@Component({
  selector: 'app-tournaments-list',
  templateUrl: './tournaments-list.component.html',
  styleUrls: ['./tournaments-list.component.scss'],
})
export class TournamentsListComponent implements OnInit, OnDestroy {
  private storeSub: Subscription;
  tournamentList: Values[];

  constructor(private store: Store<appState.AppState>) { window.scroll(0,0);}

  ngOnInit() {

    this.storeSub = this.store.select("playerState").subscribe(
      (playerState: ProfileState) => {
        if (playerState.tournamentList) {
          if (playerState.tournamentList.values) {
            this.tournamentList = playerState.tournamentList.values;
            console.log("**************************************************************", this.tournamentList)
          }
        }
      }
    )

    let obj = { "filter": { "regular": true, "guaranteed": true, "freeroll": true }, "paging": { "offset": 0, "limit": 100 } };
    this.store.dispatch(new PlayerGetTournamentsList(obj));
    this.store.dispatch(new PlayerLeaderBoardList());
    this.store.dispatch(new PlayerLeaderBoardGetParticipants());
  }
  ngOnDestroy() {
    if (this.storeSub) {
      this.storeSub.unsubscribe();
    }
  }

}
