import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs'; 

@Injectable({
    providedIn: 'root'
  })
  export class CommonService {
    private hitMethod = new BehaviorSubject('');
    currentMethod = this.hitMethod.asObservable();
  
    constructor() { }

    updateHitMethod(message: any) {
      this.hitMethod.next(message)
      }
 

  }