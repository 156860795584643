import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import * as appState from 'src/app/source/appstates/appState';
import * as cashierActions from 'src/app/source/appstates/cashierstates/cashierActions';
import { CashierState } from 'src/app/source/appstates/cashierstates/cashierState';
import { Subscription } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { BankAccount } from 'src/app/source/modules/cashier/bankAccountInfo';
declare var $: any;
function showLoginModal() {
  $("#accountDetails").modal("show");
}
function hideLoginModal() {
  $("#accountDetails").modal("hide");
}
@Component({
  selector: 'app-account-details',
  templateUrl: './account-details.component.html',
  styleUrls: ['./account-details.component.scss'],
})
export class AccountDetailsComponent implements OnInit, OnDestroy {
  @Input() formState: string;
  @Output() close = new EventEmitter<void>();

  private storeSub: Subscription;
  form: FormGroup;
  errormessage:string;
  addbankerrormessage:string;
  bankAccountInfos:BankAccount[];
  
  constructor(private store: Store<appState.AppState>) { }
  ngOnInit() { 
    this.store.dispatch(new cashierActions.ResetState());
    this.form = new FormGroup({
      "bankAccountNumber": new FormControl(null, [Validators.required,  Validators.pattern('[0-9]{9,18}')]),
      "bankName": new FormControl(null, [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]),
      "accountBranchName": new FormControl(null, [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]),
      "nameOnAccount": new FormControl(null, [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]),
      "accountType": new FormControl(null, [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]),
      "personalNumber": new FormControl(null, [Validators.required, Validators.pattern('^[A-Za-z]{4}[a-zA-Z0-9]{7}$')]),
    })
    this.storeSub = this.store.select("cashierState").subscribe(
      (cashierState: CashierState) => {
        if(cashierState.addBankAccountResponse){
          if(cashierState.addBankAccountResponse.success===true){
       
        }else if(cashierState.addBankAccountResponse.success===false){
          this.addbankerrormessage = cashierState.addBankAccountResponse.description;
        }
      }
      if(cashierState.addBankAccountError){
        this.addbankerrormessage = cashierState.addBankAccountError.message;
      }
      })
    this.storeSub = this.store.select("cashierState").subscribe(
      (cashierState: CashierState) => {
        if(cashierState.bankAccountInfo){
          if(cashierState.bankAccountInfo.success==true){
          if(cashierState.bankAccountInfo.bankAccountInfos){
            this.bankAccountInfos = cashierState.bankAccountInfo.bankAccountInfos;
            this.form.reset();
          }
          
        }else if(cashierState.bankAccountInfo.success==false){
          this.errormessage = 'no bank account details';
        }
      }
      if(cashierState.bankAccountInfoError){

        this.errormessage = cashierState.bankAccountInfoError.message;
      }
      })
  
    
    this.store.dispatch(new cashierActions.CashierGetBankAccount());
 

    showLoginModal();
    $("#accountDetails").on('hidden.bs.modal', function () {
      this.onClose();
    }.bind(this));
  } 
  onFormSubmit() {
    console.log(this.form.value);
    this.store.dispatch(new cashierActions.CashierAddBankAccount(this.form.value));
  }
  onCloseAccount(encodedId){
   let obj =  { "bankEncodedIDs" : [encodedId]}
   this.store.dispatch(new cashierActions.CashierDeleteBankAccount(obj));
  }
  ngOnDestroy(){
    if(this.storeSub){
      this.storeSub.unsubscribe();
    }
  }
  onClose() {
    console.log("update profile close popup");
    this.close.emit();
  } 

}
