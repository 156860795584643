import * as sportsbookActions from './sportsbookActions';
import { SportBookGetSportsResponse } from '../../modules/sportsbook/sportsbookSports';
import { HttpClientError } from '../../modules/login/httpClientError';
import { LeaguesListResponse } from '../../modules/sportsbook/leagueList';
import { LeaguesAndMatchesList } from '../../modules/sportsbook/leadguesandmatchesList';
import { LiveMatch } from '../../modules/sportsbook/livematch/livematch';
import { IGenerateBetSlip, BetSlip } from '../../modules/sportsbook/generateBetSlip';
import { BonusConfigReference } from '../../modules/sportsbook/bonusConfigReference';
import { BetsByCouponResponse } from '../../modules/sportsbook/betsByCouponResponse';
import { BetHistory } from '../../modules/sportsbook/bethistory';

export interface SportsBookState {
    getSports: SportBookGetSportsResponse;
    getSportsError: HttpClientError;
    leaguesListResponse: LeaguesListResponse;
    leaguesListResponseError: HttpClientError;
    leaguesAndMatchesList: LeaguesAndMatchesList;
    leaguesAndMatchesListResponseError: HttpClientError;
    liveMatchResponse: LiveMatch;
    getGenerateBetSlip: IGenerateBetSlip[];
    getBetSlip: BetSlip[];
    liveSports: { name: string }[];
    BonusConfigResponse: BonusConfigReference;
    betsByCouponResponse: BetsByCouponResponse;
    transactionId: string;
    couponEmailTiketResponse: string;
    betHistory: BetHistory[];
}
const initialState: SportsBookState = {
    getSports: null,
    getSportsError: null,
    leaguesListResponse: null,
    leaguesListResponseError: null,
    leaguesAndMatchesList: null,
    leaguesAndMatchesListResponseError: null,
    liveMatchResponse: null,
    getGenerateBetSlip: [],
    getBetSlip: [],
    liveSports: null,
    BonusConfigResponse: null,
    betsByCouponResponse: null,
    transactionId: '',
    couponEmailTiketResponse: '',
    betHistory: null
}

export function cashierReducer(state: SportsBookState = initialState, action: sportsbookActions.SportsBookActions) {
    switch (action.type) {
        case sportsbookActions.SPORTSBOOK_GET_SPORTS:
            return {
                ...state,
                getSports: null,
                getSportsError: null
            }
            break;
        case sportsbookActions.SPORTSBOOK_GET_SPORTS_SUCCESS:
            return {
                ...state,
                getSports: { ...state.getSports, ...action.payload },
                getSportsError: null
            }
            break;
        case sportsbookActions.SPORTSBOOK_GET_SPORTS_FAIL:
            return {
                ...state,
                getSports: null,
                getSportsError: { ...state.getSportsError, ...action.payload }
            }
            break;
        case sportsbookActions.SPORTSBOOK_GET_LEAGUES:
            return {
                ...state,
                leaguesListResponse: null,
                leaguesListResponseError: null
            }
            break;
        case sportsbookActions.SPORTSBOOK_GET_LEAGUES_SUCCESS:
            return {
                ...state,
                leaguesListResponse: { ...state.leaguesListResponse, ...action.payload },
                leaguesListResponseError: null
            }
            break;
        case sportsbookActions.SPORTSBOOK_GET_LEAGUES_FAIL:
            return {
                ...state,
                leaguesListResponse: null,
                leaguesListResponseError: { ...state.leaguesListResponseError, ...action.payload }
            }
            break;
        case sportsbookActions.SPORTSBOOK_GET_LEAGUESANDMATCHES:
            return {
                ...state,
                leaguesAndMatchesList: null,
                leaguesAndMatchesListResponseError: null
            }
            break;
        case sportsbookActions.SPORTSBOOK_GET_LEAGUESANDMATCHES_SUCCESS:
            return {
                ...state,
                leaguesAndMatchesList: { ...state.leaguesAndMatchesList, ...action.payload },
                leaguesAndMatchesListResponseError: null
            }
            break;
        case sportsbookActions.SPORTSBOOK_GET_LEAGUESANDMATCHES_FAIL:
            return {
                ...state,
                leaguesAndMatchesList: null,
                leaguesAndMatchesListResponseError: { ...state.leaguesAndMatchesListResponseError, ...action.payload }
            }
            break;
        case sportsbookActions.LIVE_GETSPORTS:
            return {
                ...state,
                liveSports: { ...state.liveSports, ...action.payload },
                betsByCouponResponse: null,
                transactionId: ''
            }
            break;
        case sportsbookActions.SPORTSBOOK_GET_BONUS_CONFIG:
            return {
                ...state,
                BonusConfigResponse: null
            }
            break;
        case sportsbookActions.SPORTSBOOK_GET_BONUS_CONFIG_SUCCESS:
            return {
                ...state,
                BonusConfigResponse: { ...state.BonusConfigResponse, ...action.payload }
            }
            break;
        case sportsbookActions.BETSLIP:
            let repeted: boolean = false;
            let index;
            const checkBetSlip = state.getBetSlip.filter((ig, igIndex) => {

                if (ig.generateBetSlip[0].oddId == action.payload[0].generateBetSlip[0].oddId &&
                    ig.generateBetSlip[0].oddTitle == action.payload[0].generateBetSlip[0].oddTitle &&
                    ig.generateBetSlip[0].matchId == action.payload[0].generateBetSlip[0].matchId) {
                    repeted = true;
                    index = igIndex;
                    return igIndex;
                }
            });

            if (repeted) {
                const updatedbetslips = state.getBetSlip.filter((ig, igIndex) => {
                    return igIndex !== index;
                });
                return {
                    ...state,
                    getBetSlip: [...updatedbetslips],
                    betsByCouponResponse: null,
                    transactionId: ''
                }
            }

            return {
                ...state,
                getBetSlip: [...state.getBetSlip, ...action.payload],
                betsByCouponResponse: null,
                transactionId: ''
            }
            break;
        case sportsbookActions.SPORTSBOOK_GET_BETS_BY_COUPON:
        case sportsbookActions.SPORTSBOOK_SEND_BETS:
        case sportsbookActions.SPORTSBOOK_CLOSE_BETS_BY_COUPON:
            return {
                ...state,
                betsByCouponResponse: null,
                couponEmailTiketResponse: '',
                transactionId: ''
            }
            break;
        case sportsbookActions.SPORTSBOOK_GET_BETS_BY_COUPON_SUCCESS:
            return {
                ...state,
                betsByCouponResponse: { ...state.betsByCouponResponse, ...action.payload },
                transactionId: action.transactionId,
                couponEmailTiketResponse: '',
                getBetSlip: []
            }
            break;
        case sportsbookActions.SPORTSBOOK_COUPON_EMAIL_TICKET:
            return {
                ...state,
                betsByCouponResponse: null,
                transactionId: '',
                couponEmailTiketResponse: ''
            }
            break;
        case sportsbookActions.SPORTSBOOK_COUPON_EMAIL_TICKET_SUCCESS:
            return {
                ...state,
                betsByCouponResponse: null,
                transactionId: '',
                couponEmailTiketResponse: "email sent successfully"
            }
            break;
        case sportsbookActions.SPORTSBOOK_GET_BET_HISTORY_SUCCESS:
            return {
                ...state,
                betHistory:{...state.betHistory,...action.payload}
            }
            break;
        default:
            return state;
            break;
    }
}